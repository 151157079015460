export function transformDate(inputDate: any) {
    
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function transformDateMini(inputDate: any) {
    const date = new Date(inputDate);
    const day = date.getDate() < 10? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() < 10? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function transformTimeZone(dateInUTC: any){

    // Получите разницу часового пояса с UTC в минутах
    const timezoneOffsetInMinutes = dateInUTC?.getTimezoneOffset();

    // Корректируем время, добавляя разницу часового пояса, чтобы получить локальное время
    const dateInLocalTime = new Date(dateInUTC?.getTime() - timezoneOffsetInMinutes * 60000);

    return dateInLocalTime?.toISOString() // Вывод: "2023-08-07T15:34:56.000Z" (предполагая разницу в часовом поясе +03:00)

}

export function addEndTime(date: any){
    const new_date = new Date(date.getTime())
    new_date.setHours(date.getHours() + 23)
    new_date.setMinutes(date.getMinutes() + 59)
    new_date.setSeconds(date.getSeconds() + 59)
    return new_date
}

export function getCurrentDate(date: any, inDay?: any){
    let currentDate = new Date();
    if(date) currentDate = date

    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    if(inDay) return `${hours}:${minutes}`;
    return `${day}.${month}.${year}`;
}

export function getTimestamp24HoursAgo() {
    let currentDate = new Date().getTime();
    return currentDate - 86400000
}

export function getTimestampWeekAgo() {
    let currentDate = new Date().getTime();
    return currentDate - 604800000
}

export function getTimestampOneMonthAgo() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    return currentDate.getTime();
}

// Функция для преобразования даты в стандартный формат (день.месяц.год)
export function parseDate(dateString: string) {
  var parts = dateString.split(".");
  if (parts.length === 3) {
    const returnedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    returnedDate.setHours(23)
    returnedDate.setMinutes(59)
    returnedDate.setSeconds(59)
    return returnedDate
  }
  return null;
}

// Сортируем массив объектов по дате
export function sortStatisticDates(arrayOfObjects: any){
    arrayOfObjects.sort(function(a: any, b: any) {
        var dateA: any = parseDate(a.date);
        var dateB: any = parseDate(b.date);
        return dateA - dateB;
    });
    return arrayOfObjects
}

export function getDatesBetween(timestamp1: any, timestamp2: any) {
    const startDate = new Date(timestamp1);
    const endDate = new Date(timestamp2);
    const dates = [];
    const currentDate = new Date(startDate);
    currentDate.setHours(Number("00"))
    currentDate.setMinutes(Number("00"))
    currentDate.setSeconds(Number("00"))
  
    while (currentDate <= endDate) {
      dates.push({date: getCurrentDate(new Date(currentDate))});
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

export function getHourlyDatesForLast24Hours() {
    const now = new Date(); // Текущая дата и время
    now.setMinutes(0, 0, 0); // Обнуляем минуты, секунды и миллисекунды
    const dates = [];
  
    for (let i = 0; i < 24; i++) {
      const hourAgo = new Date(now);
      hourAgo.setHours(now.getHours() - i);
      dates.push({date: getCurrentDate(hourAgo, true)});
    }
  
    return dates.reverse();
}
