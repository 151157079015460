import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const referralApi = createApi({
    reducerPath: 'referralApi',
    tagTypes: ['Referral'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        
        getReferralsLinks: builder.query({
            query: (params) => ({
                url: `/api/referral/get/all`,
                method: "GET",
                params
            }),
            providesTags: ["Referral"]
        }),

        getReferralsLink: builder.query({
            query: (params) => ({
                url: `/api/referral/get`,
                method: "GET",
                params
            }),
            providesTags: ["Referral"]
        }),

        CreateReferralLink: builder.mutation({
            query: (data) => ({
                url: '/api/referral/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["Referral"]
        }),

        DeleteReferralLink: builder.mutation({
            query: (data) => ({
                url: '/api/referral/delete',
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: ["Referral"]
        })

    }),
  })

export const {
    useGetReferralsLinksQuery,
    useGetReferralsLinkQuery,
    useCreateReferralLinkMutation,
    useDeleteReferralLinkMutation
} = referralApi;