export const modules = {
    toolbar: {
        container: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["link", "image"],
            [{ script: "sub" }, { script: "super" }]
        ]
    },
};

export const imageExtensions = [
    ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".tif", ".webp", ".heic", ".heif", ".svg"
];

export const videoExtensions = [
    ".mp4", ".mkv", ".avi", ".mov", ".wmv", ".flv", ".webm", ".mpeg", ".mpg", ".3gp", ".ogv"
];

export const STATISTICS_IGNORE_DATERANGE = ['users_published_adverts', 'users_not_published_adverts', 'average_publication_users', 'users_published_deals', 'average_deals_users', 'users_not_published_deals']

