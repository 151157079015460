type Lang = { key: string };

export const findById = (
    values: Record<string, any>,
    ids: number[],
    newValues: Record<string, any>,
    langs: Lang[],
    defaultImage: any,
    isManual: number[]
): Record<string, any> => {
    console.log(newValues);

    // Створюємо поверхневу копію об'єкта values для оновлення
    let updatedValues: any = { ...values };

    // Рекурсивна функція для оновлення вкладених значень для заданого id
    const updateNestedValues = (value: any, id: number): any => {
        if (!value) return value;

        // Якщо знайдено елемент з потрібним id
        if (value.id === id) {

            // Обробка спеціальних типів: "number", "slug", "date"
            if (value.type === "number") {
                Object.entries(newValues).forEach(([newKey, newValue]) => {
                    if (newKey.includes("number") && newKey.includes(String(id))) {
                        // Оновлюємо значення, приводячи його до числа
                        value.values = Number(newValue);
                    }
                });
            } else if (value.type === "slug") {
                Object.entries(newValues).forEach(([newKey, newValue]) => {
                    if (newKey.includes("slug") && newKey.includes(String(id))) {
                        value.values = newValue;
                    }
                });
            } else if (value.type === "date") {
                Object.entries(newValues).forEach(([newKey, newValue]) => {
                    if (newKey.includes("date") && newKey.includes(String(id))) {
                        value.values = new Date(newValue).getTime();
                    }
                });
            } else {
                // Для інших типів – оновлення значень для кожної мови
                langs.forEach((lang) => {
                    let valueUpdated = false;
                    Object.entries(newValues).forEach(([newKey, newValue]) => {
                        if (newKey.includes(String(id)) && newKey.includes(lang.key)) {
                            valueUpdated = true;
                            const isMedia = value.type === "image" || value.type === "video";
                            const isLink = value.type === "link";
                            const existingValues = value.values || [];
                            // Перевіряємо, чи вже є оновлення для цієї мови
                            const exists = existingValues.some(
                                (item: any) =>
                                    item.lang_key === lang.key &&
                                    (item.file === newValue || item.value === newValue || item.file === "")
                            );
                            if (!exists) {
                                const updatedItem = isMedia
                                    ? {
                                        file: newValue,
                                        lang_key: lang.key,
                                        default_image: defaultImage.some(
                                            (el: any) => el.key === lang.key && el.id === value.id
                                        )
                                    }
                                    : { value: newValue, lang_key: lang.key };
                                value.values = [...existingValues, updatedItem];
                            }
                            if (isLink) {
                                // Якщо елемент є посиланням, оновлюємо властивість link
                                Object.entries(newValues).forEach(([linkKey, linkValue]) => {
                                    if (linkKey.includes("link") && linkKey.includes(String(value.id))) {
                                        value.link = linkValue;
                                    }
                                });
                            }
                        }
                    });
                    // Якщо для даної мови оновлення не знайдено – додаємо пусте значення
                    if (!valueUpdated) {
                        const isMedia = value.type === "image" || value.type === "video";
                        const updatedItem = isMedia
                            ? {
                                file: "",
                                lang_key: lang.key,
                                default_image: defaultImage.some(
                                    (el: any) => el.key === lang.key && el.id === value.id
                                )
                            }
                            : { value: "", lang_key: lang.key };
                        value.values = [...(value.values || []), updatedItem];
                    }
                });
            }
            return value;
        }

        // Якщо є властивість "values" і вона є масивом, оновлюємо кожен елемент
        if (Array.isArray(value.values)) {
            value.values = value.values.map((nestedValue: any) =>
                updateNestedValues(nestedValue, id)
            );
        }

        // Якщо value – об'єкт, рекурсивно проходимо по його властивостях
        if (typeof value === "object") {
            Object.entries(value).forEach(([key, nestedValue]) => {
                value[key] = updateNestedValues(nestedValue, id);
            });
        }

        // Для медіа-елементів встановлюємо прапорець isManual
        if (value && (value.type === "image" || value.type === "video")) {
            value.isManual = isManual.includes(value.id);
        }

        return value;
    };

    // Для кожного id з масиву оновлюємо значення у всіх властивостях updatedValues
    ids.forEach((id) => {
        Object.entries(updatedValues).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                updatedValues[key] = value.map((item: any) => updateNestedValues(item, id));
            } else if (typeof value === "object" && value !== null) {
                updatedValues[key] = updateNestedValues(value, id);
            }
        });
    });

    return { result: updatedValues };
};

export const extractIds = (input: Record<string, any>): number[] => {
    const ids = new Set<number>();

    Object.keys(input).forEach((key) => {
        // Спочатку шукаємо ключі, які відповідають формату "цифри-два символи" (наприклад, "123-en")
        const match = key.match(/(\d+)-\w{2}$/);
        // Якщо не знайдено такого формату, пробуємо витягти будь-які цифри з ключа
        const digits = key.match(/\d+/)?.[0];

        if (match) {
            const id = parseInt(match[1], 10);
            ids.add(id);
        } else if (digits) {
            ids.add(Number(digits));
        }
    });

    return Array.from(ids);
};
