import React, { useEffect, useState } from 'react'
import { Checkbox, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import s from "./CreateMailing.module.scss"
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton'
import { TimePeriod } from 'utils/enums/common.enum'
import { checkTextFields } from 'utils/checkTextFields'
import { useSelector } from 'react-redux'
import { useCreateMailingMutation, useGetEmailHTMLTemplateQuery, useGetGroupsQuery, useGetMailingSendersQuery, useGetMailingsHandlerMutation, useUpdateMailingMutation } from '../../../../../../redux/apis/mailingApi'
import { StyledNumberInput } from 'components/StyledNumberInput/StyledNumberInput'
import DatePicker from 'react-datepicker'
import { TextFieldsBlock } from 'components/TextFieldsBlock/TextFieldsBlock'
import { StyledHTMLFieldsBlock } from 'components/StyledHTMLFiledsBlock/StyledHTMLFieldsBlock'
import { ModalGeneral } from 'components/Modals/ModalGeneral'
import { useStatusContext } from 'components/StatusProvider'
import { Skeleton } from 'components/Skeleton/Skeleton'


export type MailingFormType = {
    id?: string
    body: { [key: string]: string }
    mailing_type: number
    group: string | undefined
    sender: string | undefined
    interval: TimePeriod | undefined
    send_count: number | undefined
    all_send_count: number | undefined
    send_type: number
    start_date: string
    title: { [key: string]: string }
    createdAt?: string
    inProcess: boolean
    completed: boolean
    statistics?: {
        users: number
        errors: number
        sended: number
    }
}

export const CreateMailing = () => {

    // ### State
    const [form, setForm] = useState<MailingFormType>({
        body: {}, 
        title: {},
        mailing_type: 0,
        group: undefined,
        sender: undefined,
        interval: undefined,
        send_count: undefined,
        all_send_count: undefined,
        send_type: 0,
        inProcess: false,
        completed: false,
        start_date: new Date().toISOString()
    })
    const langs = useSelector((store: any) => store.main.langs);
    const [currentLang, setCurrentLang] = useState<string>(langs?.[0]?.key)
    const [template, setTemplate] = useState<string | undefined>(undefined)
    const [confirmModal, setConfirmModal] = useState(false)
    const [dateTimeState, setDateTimeState] = useState<boolean>(false)
    const { openStatus } = useStatusContext();

    const {data: templateData, isLoading: isGetTemplateLoading} = useGetEmailHTMLTemplateQuery({})
    const {data: groups, isLoading: isGetLoading} = useGetGroupsQuery({})
    const {data: senders, isLoading} = useGetMailingSendersQuery({})
    
    const [CreateMailingHandler, {isLoading: isCreateLoading}] = useCreateMailingMutation()
    const [UpdateMailingHandler, {isLoading: isUpdateLoading}] = useUpdateMailingMutation()
    const [GetMailing, {isLoading: isGetmailingLoading}] = useGetMailingsHandlerMutation({})

    const {t} = useTranslation()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    
    // ### Effects
    useEffect(()=>{
        if(langs) setCurrentLang(langs?.[0]?.key)
    },[langs]);

    useEffect(()=>{
        if(params.get('id')){
            GetMailingHandler(params.get('id') as string)
        }
    },[params.get('id')]);

    useEffect(()=>{
        if(templateData && currentLang){
            let newTemplate = templateData.template.replace('$body', form.body[currentLang] || '')
            newTemplate = newTemplate.replace('$title', form.title[currentLang] || '')
            setTemplate(newTemplate)
        }
    },[form.body, form.title, currentLang, templateData]);


    // ### Functions
    async function handleSubmit(){
        // ### Validation
        let error: string | boolean = false
        if(!checkTextFields(form.title, langs)) error = "title_error"
        if(!checkTextFields(form.body, langs)) error = "body_error"
        if(!form.group) error = "book_error"
        if(!form.sender) error = "sender_error"
        if(form.mailing_type){
            if(!form.interval) error = "interval_error"
            if(!form.all_send_count) error = "send_count_error"
        }

        if(error){
            openStatus('error', t(`mailings.${error}`));
            return
        }
        
        const action = params.get('id') ? UpdateMailingHandler : CreateMailingHandler
        const now = new Date()
        const response = await action({
            ...form, 
            start_date: new Date(form.start_date) < now ? now.toISOString() : form.start_date,
            send_count: form.all_send_count
        }).unwrap()
        if(response.message === "mailing_created" || response.message === "mailing_updated") navigate(`/mailings/list/about?id=${response.id}`) 
    }

    async function GetMailingHandler(id: string){
        const response = await GetMailing({id}).unwrap()
        if(response?.length) setForm({...response?.[0], completed: false})
    }

    function checkConfirmModal(){
        if(new Date(form.start_date) < new Date()) return true
        else return false
    }

    // ### Views
    
    if((params.get('id') && !form.id) || isGetTemplateLoading || isGetLoading || isLoading || isGetmailingLoading) return <div className={s.loading_wrapper}><Skeleton isLoading={true}/></div>
    return(
        <div className={s.wrapper}>
            <div className={s.content_wrapper}>
                <div className={s.left}>
                    <div className={s.row}>
                        <span className={s.title}>{t('mailings.group')}</span>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.group} 
                            onChange={(ev)=>setForm(prev => {return{...prev, group: ev.target.value as string}})}
                        >   
                            {
                                groups?.map((group: any, index: number) => {
                                    return(
                                        <MenuItem key={index} value={group.id}>{group.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className={s.row}>
                    <span className={s.title}>{t('mailings.sender')}</span>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.sender}
                            onChange={(ev)=>setForm(prev => {return{...prev, sender: ev.target.value as string}})}
                        >
                            {
                                senders?.map((sender: any, index: number) => {
                                    return(
                                        <MenuItem key={index} value={sender.id}>{sender.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className={s.row}>
                        <span className={s.title}>{t('mailings.mailing_type')}</span>
                        <div className={s.checkbox_row}>
                            <div className={s.checkbox_wrapper}>
                                <Checkbox 
                                    checked={!form.mailing_type} 
                                    color="default" 
                                    onClick={()=>setForm(prev => {return{...prev, mailing_type:0}})}
                                />
                                {t('mailings.one_time')}
                            </div>
                            <div className={s.checkbox_wrapper}>
                                <Checkbox 
                                    checked={!!form.mailing_type} 
                                    color="default" 
                                    onClick={()=>setForm(prev => {return{...prev, mailing_type:1}})}
                                />
                                {t('mailings.cyclic')}
                            </div>
                        </div>
                        {
                            form.mailing_type > 0 ? 
                            <div className={s.checkbox_row}>
                                <div className={s.checkbox_row}>
                                    <div className={s.checkbox_wrapper}>
                                        <span className={s.title}>{t('mailings.interval')}</span>
                                        <Select
                                            sx={{width: 150}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={(ev)=>setForm(prev => {return{...prev, interval: ev.target.value as TimePeriod}})}
                                            value={form.interval}
                                        >
                                            {
                                            Object.entries(TimePeriod).map((el, index) => {
                                                return <MenuItem key={index} value={el[1] as string}>{t(`mailings.${el[0]}`)}</MenuItem>
                                            })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className={s.checkbox_row}>
                                    <div className={s.checkbox_wrapper}>
                                        <span className={s.title}>{t(`mailings.send_count`)}</span>
                                        <StyledNumberInput
                                            value={form.all_send_count}
                                            onChange={(value: any) => setForm(prev => {return{...prev, send_count: value, all_send_count: value}})}
                                        />
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div className={s.row}>
                        <span className={s.title}>{t(`mailings.send_date`)}</span>
                        <div className={s.checkbox_row}>
                            <div className={s.checkbox_wrapper}>
                                <Checkbox 
                                    checked={form.send_type === 0}
                                    onChange={()=>{
                                        setForm(prev => {return{...prev, send_type: 0, start_date: new Date().toISOString()}})
                                        setDateTimeState(false)
                                    }}
                                    color="default" 
                                />
                                {t(`mailings.send_now`)}
                            </div>
                            <div className={s.checkbox_wrapper}>
                                <Checkbox 
                                    color="default" 
                                    checked={form.send_type === 1}
                                    onChange={() => {
                                        setForm(prev => {return{...prev, send_type: 1}})
                                        setDateTimeState(true)
                                    }}
                                />
                                <p 
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setForm(prev => {return{...prev, send_type: 1}})
                                        setDateTimeState(true)
                                    }}
                                >
                                    {t(`mailings.change_date`)}
                                </p>
                            </div>
                            {
                                form.send_type && form.start_date ? 
                                    <DatePicker 
                                        showTimeSelect
                                        dateFormat="dd.MM.yyyy, HH:mm:ss"
                                        selected={new Date(form.start_date)} 
                                        onChange={(date: any) => setForm(prev => ({...prev, start_date: date.toISOString()}))} 
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    <div className={s.row}>
                        <TextFieldsBlock 
                            title={t(`mailings.title`)}
                            values={form.title}
                            changeValue={(value: any)=>{setForm(prev => {return{...prev, title: {...prev.title, ...value}}})}}
                            setLang={(lang: any) => setCurrentLang(lang)}
                            activeLang={currentLang}
                            setActiveLang={setCurrentLang}
                        />
                    </div>
                    <div className={s.row}>
                        <StyledHTMLFieldsBlock
                            title={t(`mailings.body`)}
                            values={form.body}
                            changeValue={(value: any)=>{setForm(prev => {return{...prev, body: {...prev.body, ...value}}})}}
                            setLang={(lang: any) => setCurrentLang(lang)}
                            activeLang={currentLang}
                            setActiveLang={setCurrentLang}
                        />
                    </div>
                </div>
                <div className={s.right}>
                    {
                        template ? 
                        <div className={s.frame} dangerouslySetInnerHTML={{__html: template}}></div>
                        : null
                    }
                </div>
            </div>
            <div className={s.footer}>
                <PrimaryButton 
                    style={{width: "fit-content"}}
                    text={t('general.cancel')} 
                    type={'button'} 
                    onClick={() => navigate('/mailings/list')}
                />
                <PrimaryButton 
                    disabled={(isCreateLoading || isUpdateLoading)}
                    style={{width: "fit-content"}}
                    onClick={()=>{
                        checkConfirmModal() ? setConfirmModal(true) : handleSubmit()
                    }}
                    text={t('general.save')}
                    type={"button"}
                />
        
            </div>

            <ModalGeneral
                showModal={confirmModal}
                setShowModal={setConfirmModal}
                description={`${t('mailings.confirm_save')}`}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={handleSubmit}
            />

        </div>
    )
}