import React, { FC, useEffect, useRef, useState } from 'react'
import s from "./ProgressLine.module.scss"

type ProgressType = {
    count: number
    progress: number
}

export const ProgressLine: FC<ProgressType> = ({count, progress}) => {
    console.log("count", count);
    console.log("progress", progress);
    // ### State
    const [lineWidth, setLineWidth] = useState(0)
    const wrapper = useRef<HTMLDivElement>(null)

    // ### Effects
    useEffect(()=>{
        if(wrapper && wrapper.current && progress && count){
            const wrapperWidth = wrapper.current.offsetWidth
            const currentProcent = progress / count * 100
            const currentWidth = (wrapperWidth / 100) * currentProcent
            setLineWidth(currentWidth)
        }
    },[count, progress, wrapper.current?.offsetWidth]);

    // ### Functions

    // ### Views
    if(typeof count !== "number" || typeof progress !== "number") return <></>
    return(
        <div className={s.wrapper} ref={wrapper}>
            <div className={s.line} style={{width: `${lineWidth}px`}}>
                <div className={s.shadow}></div>
                <div className={s.gradient}></div>
            </div>
        </div>
    )
}