import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import {clearHTML} from "./clearHTML";

export function Term({children}:{children: any}): any {
    const {i18n} = useTranslation()
    if(children && typeof children[i18n.language] === "string") return parse(clearHTML(children[i18n.language]))
    else return ""
}

export function TermHTML({children}:{children: any}){
    const {i18n} = useTranslation()
    if(children) return parse(children[i18n.language])
    else return ""
}