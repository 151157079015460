import React from 'react';
import * as yup from 'yup';
import { useAddSectionMutation, useUpdateSectionMutation} from "../../../../redux/apis/sectionApi";
import { Form, Formik} from "formik";
import s from './SectionModal.module.scss';
import {Back} from "components/Back/Back";
import {Input} from "components/Input/Input";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useStatusContext} from "components/StatusProvider";
import {useTranslation} from "react-i18next";
import {Preloader} from "components/preloader/Preloader";

type SectionModal = {
    close: React.Dispatch<React.SetStateAction<any>>;
    el: any;
    pageState: any
    refetchGetSection: any;
}

export const SectionModal = ({ close, el, pageState, refetchGetSection }: SectionModal) => {
    const { t } = useTranslation();
    const initialValues = {id: el? el.id : '', name: el? el.name : '', content: el? el.content : '', marker: el.marker? el.marker : ''};
    const validationSchema = yup.object().shape({
        name: yup.string().required(t("validation.required")).min(2, t("validation.min2s")),
        marker: yup
            .string()
            .required(t("validation.required"))
            .test(
                "no-spaces",
                t("validation.noSpacesAllowed"),
                (value) => !/\s/.test(value)
            )
            .matches(
                /^[a-z0-9_]+$/,
                t("validation.invalidCharacters")
            )
            .min(2, t("validation.min2s"))
    });

    const [updateSection, {isLoading: isLoadingUpdateSection}] = useUpdateSectionMutation();
    const [addSection, {isLoading: isLoadingAddSection}] = useAddSectionMutation();
    const { openStatus } = useStatusContext();

    if (isLoadingUpdateSection || isLoadingAddSection) return <Preloader />

    return (
        <div className={s.pages_modal}>
            <Back close={close} text={t("sectionModal.backToSections")} />

            <div className={s.form}>
                <h2>{el ? t("sectionModal.editSection") : t("sectionModal.addASection")}</h2>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(false)
                        if (el) {
                            const res: any = await updateSection(values);
                            if (res.error?.data.message === 1000) {
                                openStatus('warning', "status.unfortunately");
                                console.log(res);
                                close(false);
                            } else {
                                openStatus('success', "status.operationSuccessful");
                                refetchGetSection();
                                close(false);
                            }

                        } else {
                            const res: any = await addSection({
                                values,
                                pageState: {...pageState, marker: values.marker}
                            });
                            if (res.error?.data.message === 1000) {
                                openStatus('warning', "status.unfortunately");
                                close(false);
                            } else if (res.error?.data.message === 34) {
                                openStatus('error', `${t("pagesModal.name")} ${res.error.data.data.name} ${t("pagesModal.alreadyTaken")}`);
                            } else {
                                openStatus('success', "status.operationSuccessful");
                                refetchGetSection();
                                close(false);
                            }
                        }
                    }}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.pages_form}>
                            <Input
                                value={values.name}
                                label={t("sectionModal.sectionName")}
                                errors={errors.name}
                                touched={touched.name}
                                name={"name"}
                                onClick={() => setFieldValue('name', '')}
                            />

                            <Input
                                value={values.marker}
                                label={t("pages.marker")}
                                errors={errors.marker}
                                touched={touched.marker}
                                name={"marker"}
                                onClick={() => setFieldValue('marker', '')}
                            />

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => close(false)}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}