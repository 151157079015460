import s from './Folder.module.scss';
import { ReactComponent as Arrow } from "../../.././../assets/icons/arrow_tools.svg"
import { ReactComponent as FolderIcon } from "../../.././../assets/icons/folder.svg"
import { useEffect, useRef, useState } from 'react';
import { formatSize } from 'utils/formatSize';

type FolderType = {
    folder: any,
    leftIndex?: number,
    activeFolder: string,
    setActiveFolder: any
}

export const Folder = ({folder, leftIndex = 1, activeFolder, setActiveFolder}: FolderType) => {

    // ### State
    const [manualClose, setManualClose] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const arrowRef = useRef<HTMLDivElement>(null)

    // ### Effects

    useEffect(()=>{
        // Открываем нужные папки основываясь на activeFolder
        // Открываем родителей
        const isset = searchNestedFolder(folder)
        if(isset && dropdownRef.current && arrowRef.current) {
            const dropdown = dropdownRef.current
            if(dropdown && !dropdown?.offsetHeight) handleOpen()
        }
        // Открываем выбранную activeFolder
        else if(activeFolder === folder.path && dropdownRef.current && arrowRef.current && !manualClose){
            handleOpen()
        }
    },[activeFolder, folder]);

    // ### Functions

    function searchNestedFolder(el: any){
        let isset = false
        if(el.path === activeFolder && el.path !== folder.path){
            return true
        }
        
        const res = el.children?.map((el: any) => {
            return searchNestedFolder(el)
        })
       
        if(res.includes(true)) return true
            
        return isset
    }

    // Ручное открытие/закрытие
    function toogleDropdowm(el: HTMLDivElement){
       const dropdown = el.parentNode?.childNodes?.[1] as HTMLDivElement

       if(dropdown){
            if(dropdown.offsetHeight){
                setManualClose(true)
                handleClose()
            }
            else if(activeFolder === folder.path){
                handleOpen()
            }
       }
    }

    function handleOpen(){
        const dropdown = dropdownRef.current
        const arrow = arrowRef.current

        if(dropdown && arrow){
            dropdown.style.cssText = `height: ${dropdown.scrollHeight}px;`;
            arrow.style.cssText = "transform: rotate(0deg);"
            setTimeout(()=>{
                dropdown.style.cssText = `height: auto;`;
            }, 300)
        }
    }

    function handleClose(){
        const dropdown = dropdownRef.current
        const arrow = arrowRef.current

        if(dropdown && arrow){
            dropdown.style.cssText = `height: ${dropdown.scrollHeight}px;`;
            setTimeout(()=>{
                dropdown.style.cssText = "height: 0px;"
                arrow.style.cssText = "transform: rotate(-90deg);"
            }, 30)
        }
    }

    // ### Views
    
    const childrensView = folder?.children?.map((child: any, index: number) => {
        return(
            <Folder 
                key={index}
                folder={child}
                leftIndex={leftIndex + 1}
                activeFolder={activeFolder}
                setActiveFolder={setActiveFolder}
            />
        )
    })

    return(
        <div className={s.wrapper}>
            <div 
                className={`${s.row} ${activeFolder === folder.path ? s.active : ""}`} 
                style={{paddingLeft: `${15*leftIndex + (folder.children.length ? 0 : 20)}px`}} 
                onClick={(ev) => {
                    toogleDropdowm(ev.target as HTMLDivElement)
                    setActiveFolder(folder.path)
                }}
            >
                { folder?.children?.length ? <div className={s.arrow_wrapper} ref={arrowRef}><Arrow/></div> : null }
                <FolderIcon/>
                <span className={`${s.name}`}>{folder.name}</span>
                <span className={`${s.size}`}>{formatSize(folder.size)}</span>
            </div>
            <div className={s.dropdown} ref={dropdownRef}>
                {childrensView}
            </div> 
        </div>
    )
}