import { useEffect, useState } from 'react';
import s from './UpdateGroup.module.scss';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import {ReactComponent as Success} from "../../../../../../assets/icons/success.svg"
import {ReactComponent as Back} from "../../../../../../assets/icons/arrow-back.svg"
import { GroupModal } from '../GroupModal/GroupModal';
import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { useTranslation } from 'react-i18next';
import { useGetFullGroupQuery, useUpdateGroupMutation } from '../../../../../../redux/apis/mailingApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'components/Table/Table';
import { Users } from 'pages/Dashboard/components/Users/Users';
import { Skeleton } from 'components/Skeleton/Skeleton';
import { Tabs } from 'components/Tabs/Tabs';
import { Checkbox } from '@mui/material';

export const UpdateGroup = () => {

    // ### State
    const params = useParams()
    const [changeDelete, setChangeDelete] = useState<any>([])
    const [changeDeleteContacts, setChangeDeleteContacts] = useState<any>([])
    const [changedUsers, setChangedUsers] = useState({users: [], contacts: []})
    const [changeAll, setChangeAll] = useState<boolean>(false)
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const {data: group, isLoading} = useGetFullGroupQuery({id: params?.["*"]})
    
    const items = [
        {
            id: 0,
            title: `${t("mailings.registered")} (${changeAll ? `${t('mailings.all')} ${group.usersCount}`: `${group?.changedList?.users?.length}`})`
        },
        {
            id: 1,
            title: `${t("mailings.not_registered")} (${group?.changedList?.contacts?.length})`
        }
    ]
   
    const [tab, setTab] = useState<any>(items?.[0]?.id)

    const [Update, {isLoading: isUpdateLoading}] = useUpdateGroupMutation()

    // ### Effects
    useEffect(()=>{
        if(group){
            setChangeAll(group.changeAll)
            setChangedUsers({
                users: group.changedList.users,
                contacts: group.changedList.contacts
            })
        }
    },[group]);


    // ### Functions
    async function handleUpdate(){
        const contacts = changedUsers.contacts?.filter((contact: any) => {
            const isset = changeDeleteContacts.some((el: any) => el.email === contact.email)
            if(isset)return false
            else return true
        })
        
        await Update({
            id: Number(params?.["*"]),
            users: changedUsers.users?.filter(el => !changeDelete.includes(el)), 
            contacts: contacts, 
            changeAll
        })
        setChangeDelete([])
        setChangeDeleteContacts([])
    }

    // ### Views
    const columns: TableColumnType[] = [
        { title: "" },
        { title: "Email" }
    ];

    const rows: TableRowType[] = group?.changedList?.contacts?.map((contact: any) => {
        return(
            {
                values: [
                    { title: (<Checkbox 
                                checked={ changeDeleteContacts.find((el: any) => el.email === contact.email) ? true : false}
                                onChange={(e) => {
                                    e.stopPropagation()
                                    setChangeDeleteContacts((prev: any) => {
                                        const isChecked = changeDeleteContacts.find((el: any) => el.email === contact.email)
                                        if(isChecked) return prev.filter((el: any) => el.email !== contact.email)
                                        else return [...prev, contact]
                                    })
                                }}
                            />) },
                    { title: contact.email },
                ]
            }
        )
    })
    

    return(
        <div className={s.wrapper}>
            <div className={s.header}>
                <div className={s.left}>
                    <div className={s.back_wrapper} onClick={()=>navigate('/mailings/groups')}><Back/></div>
                    <p className={s.title}>{group?.name}</p>
                </div>
                <div className={s.right}>
                    {
                        changeAll ?
                        <div className={s.changed_all}>{t('mailings.change_all')}<Success/></div> : null
                    }
                    <div>
                        <PrimaryButton
                            text={t('general.delete')}
                            type={"button"}
                            onClick={handleUpdate}
                            style={{width: "fit-content"}}
                            disabled={((!changeDelete?.length && !changeDeleteContacts.length) || isUpdateLoading)}
                        />
                    </div>
                    <div>
                        <PrimaryButton
                            text={t('general.edit')}
                            type={"button"}
                            onClick={() => setModal(true)}
                            style={{width: "fit-content"}}
                        />
                    </div>
                </div>
            </div>
            <div className={s.content}>
                {
                    group ? 
                    <Tabs
                        items={items}
                        tab={tab}
                        setTab={setTab}
                    /> : null
                }
                
                {
                    tab === 0 ?
                    group?.users?.usersList ? 
                    <Users 
                        ids={changeAll ? undefined : group?.changedList?.users} 
                        isModal={changeAll ? false : true}
                        setChangedUsers={({userId}: any) => {
                            setChangeDelete((prev: any) => {
                                const isChecked = prev.includes(userId)
                                if(isChecked) return [...prev?.filter((id: any) => id !== userId)]
                                else return [...prev, userId]
                            })
                        }}
                        changedUsers={changeDelete}
                    /> : <Skeleton isLoading={isLoading} /> : null
                }
                {
                    tab === 1 ?
                    <div className={s.contacts}>
                        <Table 
                            rows={rows} 
                            columns={columns} 
                            isLoading={isLoading}
                            isModal={true}
                        />
                    </div> : null
                }
            </div>

            { modal ? 
                <GroupModal 
                    setMainTab={setTab}
                    id={group?.id}
                    changedUsers={changedUsers}
                    setChangedUsers={setChangedUsers}
                    setChangeAll={setChangeAll}
                    changeAll={changeAll}
                    close={()=>setModal(false)}
                /> : null }
        </div>
    )
}