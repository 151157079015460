import { useEffect, useState } from 'react';
import s from './MailingSenders.module.scss';
import { SenderModal } from './components/SenderModal/SenderModal';
import { useDeleteMailingSenderMutation, useGetMailingSendersQuery } from '../../../../redux/apis/mailingApi';
import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DateFormat } from 'utils/enums/common.enum';
import { Table } from 'components/Table/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import {ReactComponent as DeleteIcon} from "../../../../assets/icons/delete_icon.svg"
import { ModalGeneral } from 'components/Modals/ModalGeneral';

export const MailingSenders = () => {

    // ### State
    const params = useParams()
    const [senderModal, setSenderModal] = useState<string | undefined>(undefined)
    const [deleteModal, setDeleteModal] = useState<any>(false)

    const navigate = useNavigate()
    const {t} = useTranslation()

    const {data: senders, isLoading} = useGetMailingSendersQuery({})
    const [DeleteSender] = useDeleteMailingSenderMutation()
    
    // ### Effects
    useEffect(()=>{
        setSenderModal(params["*"] )
    },[params]);

    // ### Functions
    async function handleDelete(id: number){
        await DeleteSender({id})
    }

    // ### Views
    const columns: TableColumnType[] = [
        {
            title: t('Sender name')
        },
        {
            title: t('Email')
        },
        {
            title: t('Host')
        },
        {
            title: t('Port')
        },
        {
            title: t('Date')
        },
        {
            title: ""
        }
    ];

    const rows: TableRowType[] = senders?.map((sender: any) => {
        return(
            {
                link: `/mailings/senders/${sender.id}`,
                values: [
                    {
                        title: sender.name
                    },
                    {
                        title: sender.email,
                    },
                    {
                        title: sender.host
                    },
                    {
                        title: sender.port
                    },
                    {
                        title: format(new Date(sender.date), DateFormat.LONG)
                    },
                    {
                        title: (
                            <div 
                                className={s.delete_wrapper}
                                onClick={(e) => {
                                    setDeleteModal(sender.id)
                                    e.stopPropagation()
                                }}
                            >
                                <DeleteIcon />
                            </div>
                        )
                    }
                ]
            }
        )
    })

    return(
        <div className={s.wrapper}>
            <Table 
                rows={rows}
                columns={columns}
                isLoading={isLoading}
            />
            <PrimaryButton 
                type={"button"} 
                onClick={() => setSenderModal("1")} 
                text={t('general.add')} 
                style={{width: "fit-content"}}
            />
            { senderModal ? <SenderModal close={()=>navigate("/mailings/senders")} /> : null }

            <ModalGeneral
                showModal={deleteModal}
                setShowModal={setDeleteModal}
                description={`${t('general.delete')}?`}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={()=> {
                    handleDelete(Number(deleteModal)) 
                } }
            />
        </div>
    )
}