import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const usersApi = createApi({
    reducerPath: 'usersApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getUsersStatistics: builder.query({
            query: (params) => ({
                url: `/api/user/statistics`,
                method: "GET",
                params
            }),
            providesTags: ["Users"]
        }),

        getUserInfo: builder.query({
            query: (params) => ({
                url: `/api/user/get/crm`,
                method: "GET",
                params
            }),
            providesTags: ["Users"]
        }),

    //   auth: builder.mutation({
    //     query: (data) => ({
    //       url: '/api/customer/auth',
    //       method: 'POST',
    //       body: { email: data.email, password: data.password},
    //     }),
    //     invalidatesTags: ["Users"]
    //   })

    }),
  })

export const {
    useGetUsersStatisticsQuery,
    useGetUserInfoQuery
} = usersApi;