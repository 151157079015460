import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { format } from 'date-fns';
import { DateFormat } from 'utils/enums/common.enum';
import { Table } from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
const currency = process.env.REACT_APP_CURRENCY

export const Payments = ({payments}: {payments: any}) => {

    // ### State
    const {t} = useTranslation()

    // ### Effects

    // ### Functions

    // ### Views

    const columns: TableColumnType[] = [
        {
            title: t('general.date_transaction')
        },
        {
            title: t('general.amount')
        },
        {
            title: t('general.type')
        },
        {
            title: t('general.value')
        }
    ];

    const rows: TableRowType[] = payments?.map((payment: any) => {
        const amuount = (<><ColorNumber>{payment.amount}</ColorNumber> {currency}</>)
        return(
            {
                values: [
                    {
                        title: format(new Date(Number(payment.date)), DateFormat.LONG)
                    },
                    {
                        title: amuount,
                    },
                    {
                        title: payment.type === "course" ? t('dashboard.users.course_payment') : t('dashboard.users.guide_payment')
                    },
                    {
                        title: payment.value
                    }
                ]
            }
        )
    })

    return(
        <Table 
            rows={rows}
            columns={columns}
            isLoading={false}
        />
    )
}