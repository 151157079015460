import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark", 
    primary: {
      main: "#90caf9", // Основной цвет (голубой)
    },
    secondary: {
      main: "#f48fb1", // Второстепенный цвет (розовый)
    },
    background: {
      default: "#1E1E21", // Фон страницы
      paper: "#232428", // Фон карточек и элементов
    },
    text: {
      primary: "#FFF", // Основной цвет текста
      secondary: "#FFF", // Второстепенный цвет текста
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#A9A9A9', // Цвет бордера по умолчанию
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#A9A9A9', // Цвет при наведении
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EC7C40', // Цвет при фокусе
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: '#EC7C40',
          '&.Mui-checked': {
            color: '#EC7C40',
          }
        },
      }
    }
  },
});

export function MuiThemeProvider({children}: any) {
  return (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
  )
}
