import s from './ViewModule.module.scss';
import {ModuleValuesItem} from "./components/ModuleValuesItem/ModuleValuesItem";
import React from "react";

type ViewModuleProps = {
    object: any;
    sectionId: number;
    refetchGetSection: any;
    setEditModule: React.Dispatch<any>;
    setIsAddModule: React.Dispatch<boolean>;
    setModuleId: React.Dispatch<number>;
    setIdSection: React.Dispatch<number>;
    setElementOfArray: React.Dispatch<any>;
    setScrollId:React.Dispatch<number>;
    hiddenModules: number[];
    setHiddenModules: React.Dispatch<number[]>;
    currentModule: number;
    setCurrentModule: React.Dispatch<number>;
}

export const ViewModule = ({
                               object,
                               sectionId,
                               refetchGetSection,
                               setEditModule,
                               setIsAddModule,
                               setModuleId,
                               setIdSection,
                               setElementOfArray,
                               setScrollId,
                               hiddenModules,
                               setHiddenModules,
                               currentModule,
                               setCurrentModule
}: ViewModuleProps) => {

    return (
        <div className={s.container}>
            <ModuleValuesItem
                object={object}
                sectionId={sectionId}
                refetchGetSection={refetchGetSection}
                setEditModule={setEditModule}
                setIsAddModule={setIsAddModule}
                setModuleId={setModuleId}
                setIdSection={setIdSection}
                setElementOfArray={setElementOfArray}
                setScrollId={setScrollId}
                hidden={hiddenModules}
                setHidden={setHiddenModules}
                currentModule={currentModule}
                setCurrentModule={setCurrentModule}
            />
        </div>
    )
}