import {Back} from "components/Back/Back";
import React, {Dispatch} from "react";
import {useTranslation} from "react-i18next";
import s from "./AddBlog.module.scss";
import {Form, Formik} from "formik";
import {DropDown} from "components/DropDown/DropDown";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import * as yup from "yup";
import {useGetLangsQuery} from "../../../redux/apis/langApi";
import {Preloader} from "components/preloader/Preloader";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {Input} from "components/Input/Input";
import {useCreateBlogMutation, useUpdateBlogMutation} from "../../../redux/apis/blogApi";
import {useStatusContext} from "components/StatusProvider";
import TinyEditor from "components/TinyEditor/TinyEditor";

type AddBlogProps = {
    setIsAddBlog: Dispatch<any>;
    blog?: any
}

export const AddBlog = ({ setIsAddBlog, blog }: AddBlogProps) => {
    const { t } = useTranslation();
    const { data: langs, isFetching } = useGetLangsQuery({});
    const [createBLog] = useCreateBlogMutation();
    const [updateBlog] = useUpdateBlogMutation();
    const { openStatus } = useStatusContext();

    if (isFetching || !langs) return <Preloader />

    const validation_data: any = {
        author: yup.string().required(t("validation.required"))
    }

    const validationSchema = yup.object().shape(validation_data);

    const initialValues: any = {
        author: blog?.author ?? "",
        slug: blog?.slug ?? ""
    };

    langs?.data?.forEach((lang: any) => {
        initialValues[`image-${lang.key}`] = blog ? blog.image?.values?.[lang.key] : ''
        initialValues[`title-${lang.key}`] = blog ? blog.title?.[lang.key] : ''
        initialValues[`description-${lang.key}`] = blog ? blog.body?.[lang.key] :''
        initialValues[`text-${lang.key}`] = blog ? blog.card_body?.[lang.key] :  ''
    })

    const handleSubmit = async (values: any, {resetForm}: any) => {
        let titleValues: any[] = [];
        let bodyValues: any[] = [];
        let cardBodyValues: any[] = [];
        let imagesValues: any[] = [];

        langs?.data?.forEach((lang: any) => {
            Object.keys(values).forEach((key) => {
                if (key.includes(lang.key) && key.includes("title")) {
                    titleValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                } else if (key.includes(lang.key) && key.includes("description")) {
                    bodyValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                } else if (key.includes(lang.key) && key.includes("text")) {
                    cardBodyValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                } else if (key.includes(lang.key) && key.includes("image") && !values[key]?.startsWith('http')) {
                    imagesValues.push({
                        file: values[key],
                        lang_key: lang.key
                    });
                }
            });
        })

        const result: any = await (blog ? updateBlog({
            id: blog?.id,
            title: titleValues,
            body: bodyValues,
            card_body: cardBodyValues,
            author: values.author,
            images: imagesValues,
            slug: values.slug
        }) : createBLog({
            title: titleValues,
            body: bodyValues,
            card_body: cardBodyValues,
            author: values.author,
            images: imagesValues,
            slug: values.slug
        }));

        if (result?.data?.message === "Blog created successful" || result?.data?.message === "Blog updated successful") {
            openStatus('success', "status.operationSuccessful");
            setIsAddBlog({ show: false });
            resetForm();
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.container}>
            <Back close={setIsAddBlog} text={t("blog.backToBlog")} />

            <div className={s.content}>
                <h1 className={s.content_title}>{!blog ? t("general.add") : t("general.edit") }</h1>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue, values, isValid, dirty }) => (
                        <Form className={s.form}>
                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"image"}
                                label={"image"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"title"}
                                label={"Title"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"description"}
                                label={"Description"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"text"}
                                label={"Card description"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <Input
                                value={values.author}
                                errors={errors.author}
                                touched={touched.author}
                                name={"author"}
                                label={"Author"}
                                placeholder={"Enter author"}
                                onClick={() => setFieldValue("author", "")}
                            />

                            <Input
                                value={values.author}
                                errors={errors.slug}
                                touched={touched.slug}
                                name={"slug"}
                                label={"Slug"}
                                placeholder={"Enter slug"}
                                onClick={() => setFieldValue("slug", "")}
                            />

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                    disabled={!(isValid && dirty)}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => setIsAddBlog({show: false})}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}