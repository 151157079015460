import s from "./HeaderSection.module.scss";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { ReactComponent as LangArrow } from "assets/icons/lang_arrow.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";

import {Locale} from "utils/enums";

type HeaderSection = {
    title: string;
    buttonText?: string;
    icon?: any;
    onClick?: any;
    description?: string;
    disabled?: boolean;
}

export const HeaderSection = ({ title, buttonText, icon, onClick, description, disabled }: HeaderSection) => {
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setIsOpen(false);
    };

    return (
        <div className={s.header_section}>
            <section className={s.header}>
                <h1 className={s.title}>{title}</h1>

                <div ref={dropdownRef} className={`${s.wrapper_lang} ${isOpen && s.wrapper_lang_open}`} >

                    <div className={s.lang} onClick={() => setIsOpen(!isOpen)}>
                        {t("lang")}
                        <LangArrow />
                    </div>

                    {isOpen && (
                        <div className={s.dropdownMenu}>
                            {Object.values(Locale).map((locale: string) => (
                                <>
                                    <p className={s.menu_item} onClick={() => handleLanguageChange(locale)}>
                                        {locale}
                                        {i18n.language === locale && <Check/>}
                                    </p>

                                </>
                            ))}
                        </div>
                    )}
                </div>
                
                {buttonText && <PrimaryButton
                    text={buttonText}
                    type={"button"}
                    className={s.button}
                    icon={icon}
                    onClick={onClick && onClick}
                    disabled={disabled}
                />}
            </section>

            {description && <section className={s.description}>{description}</section>}
        </div>
    )
}