import axios from 'axios';

const API_KEY = process.env.REACT_APP_GOOGLE_API;

export async function translateText(text: string, variables: string[]): Promise<Record<string, string>> {
    try {
        const translations: Record<string, string> = {};

        await Promise.all(
            variables.map(async (lang) => {
                let currentLang = lang;
                if (lang === 'ua') currentLang = 'uk';
                if (lang === 'cn') currentLang = 'zh';

                const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

                const response = await axios.post<GoogleTranslateResponse>(url, {
                    q: text,
                    target: currentLang
                });

                const translatedText: string = decodeHtmlEntities(
                    response?.data?.data?.translations?.[0]?.translatedText || ''
                );

                translations[lang] = translatedText;
            })
        );

        console.log('translations', translations);
        return translations;
    } catch (e) {
        console.error('Translate text fetch error', e);
        throw e;
    }
}

function decodeHtmlEntities(text: string): string {
    const entityMap: Record<string, string> = {
        '&quot;': '"',
        '&#39;': "'",
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
    };

    return text.replace(/&quot;|&#39;|&amp;|&lt;|&gt;/g, (match) => entityMap[match]);
}

interface GoogleTranslateResponse {
    data: {
        translations: {
            translatedText: string;
        }[];
    };
}
