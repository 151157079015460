import React, { FC, useEffect, useRef, useState } from 'react'
import s from './Progress.module.scss'
import {useTranslation} from "react-i18next";

type ProgressType = {
    count: number
    progress: number;
    langProgress?: string;
    fileNameProgress: string;
    storage?: boolean
}

export const Progress: FC<ProgressType> = ({count, progress, langProgress, fileNameProgress, storage}) => {

    // ### State
    const [lineWidth, setLineWidth] = useState(0)
    const wrapper = useRef<HTMLDivElement>(null)
    const { t } = useTranslation();

    // ### Effects
    useEffect(()=> {
        if(wrapper && wrapper.current){
            const wrapperWidth = wrapper.current.offsetWidth
            const currentProcent = progress / count * 100
            const currentWidth = (wrapperWidth / 100) * currentProcent
            setLineWidth(currentWidth)
        }
    },[count, progress]);

    // ### Functions

    // ### Views

    return(
        <div className={s.wrap}>
            <div className={s.text}>{`${t("general.upload")} ${fileNameProgress} ${!storage ? `(${langProgress})`: ""}`}</div>
            <div className={s.hint}>{t("general.hint")}</div>
            <div ref={wrapper} className={s.progress_wrapper}>
                <div style={{width: `${lineWidth}px`}} className={s.progress_line}>
                    <div className={s.progress_shadow}></div>
                    <div className={s.progress_gradient}></div>
                </div>
            </div>
        </div>
    )
}