export enum AlignTypes {
    Left = "left",
    Center = "center",
    Right = "right"
}

export enum SortingTypes {
    Date = "date",
    Email = "email",
    Name = "name",
    Courses = "courses",
    Guides = "guides",
    Consultations = "consultations"
}

export enum DateFormat {
    SHORT = "dd.MM.yy",
    LONG = "dd.MM.yy HH:mm:ss",
    TIME = "HH:mm"
}

export enum DateRangeTypes {
    AllTime = "all_time",
    Day = "24h",
    Week = "week",
    Month = "month",
}

export enum TimePeriod {
    SIX_HOURS = "21600000",
    TWELVE_HOURS = "43200000",
    ONE_DAY = "86400000",
    TWO_DAYS = "172800000",
    THREE_DAYS = "259200000",
    FIVE_DAYS = "432000000",
    SIX_DAYS = "518400000",
    SEVEN_DAYS = "604800000",
    TWO_WEEKS = "1209600000",
    ONE_MONTH = "2592000000",
    THREE_MONTHS = "7776000000",
    HALF_YEAR = "15552000000",
    ONE_YEAR = "31536000000"
}