import s from './FolderItem.module.scss';
import { ReactComponent as FolderIcon } from "../../.././../assets/icons/folder.svg"
import { ReactComponent as Delete } from "../../.././../assets/icons/delete_icon2.svg"

export const FolderItem = ({folder, setActiveFolder, setDeleteModal}: any) => {

    // ### State

    // ### Effects

    // ### Functions

    // ### Views

    return(
        <div className={s.wrapper} onClick={() => setActiveFolder(folder.path)}>
            <span className={s.name}>{folder.name}</span>
            <div className={s.image_wrapper}>
                <FolderIcon/>
            </div>
            <div className={s.delete_button} onClick={(e)=>{
                e.stopPropagation();
                setDeleteModal(folder.path)
            }
            }><Delete /></div>
        </div>
    )
}