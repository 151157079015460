import s from './CreateReferralLinkModal.module.scss';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg'
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { useState } from 'react';
import { useCreateReferralLinkMutation } from '../../../../redux/apis/referralApi';
import { useTranslation } from 'react-i18next';

type FormType = {
    name: string | null
    link: string | null
}

export const CreateReferralLinkModal = ({close}:{close: any}) => {

    // ### State
    const [form, setForm] = useState<FormType>({
        name: null,
        link: null
    })
    const [errors, setErrors] = useState({name: false, link: false})
    const {t} = useTranslation()

    const [CreateLink, {isLoading}] = useCreateReferralLinkMutation()

    // ### Effects

    // ### Functions
    async function handleCreateLink(){
        let error = false
        if(!form.name || form.name?.length < 2){
            setErrors(prev => ({...prev, name: true}))
            error = true
        } else setErrors(prev => ({...prev, name: false}))
        if(!form.link || form.link?.length < 2){
            setErrors(prev => ({...prev, link: true}))
            error = true
        } else setErrors(prev => ({...prev, link: false}))
        if(error) return

        const response = await CreateLink(form)
        if(response.data?.message === "link_created_successful"){
            close()
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.back}></div>
            <div className={s.modal}>
                <div className={s.header}>
                    <span className={s.title}>{t('dashboard.referal.create_link')}</span>
                    <div className={s.close_wrapper} onClick={()=>close()}><Close/></div>
                </div>
                <div className={s.content}>
                    <div className={s.item}>
                        <span className={`${s.label} ${errors.name ? s.error : ""}`}>{t('general.name')}</span>
                        <input 
                            type="text"
                            value={form.name || ""}
                            onChange={(ev) => setForm(prev => ({...prev, name: ev.target.value}))}
                        />
                    </div>
                    <div className={s.item}>
                        <span className={`${s.label} ${errors.link ? s.error : ""}`}>{t('general.link')}</span>
                        <input 
                            type="text"
                            value={form.link || ""}
                            onChange={(ev) => setForm(prev => ({...prev, link: ev.target.value}))}
                        />
                    </div>
                    <PrimaryButton 
                        text={t('general.confirm')}
                        type='button'
                        onClick={handleCreateLink}
                    />
                </div>
            </div>
        </div>
    )
}