import {Back} from "components/Back/Back";
import React, {Dispatch} from "react";
import {useTranslation} from "react-i18next";
import s from "./AddDocument.module.scss";
import {Form, Formik} from "formik";
import {DropDown} from "components/DropDown/DropDown";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import * as yup from "yup";
import {useGetLangsQuery} from "../../../redux/apis/langApi";
import {Preloader} from "components/preloader/Preloader";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {Input} from "components/Input/Input";
import {useStatusContext} from "components/StatusProvider";
import {useCreateDocumentMutation, useUpdateDocumentMutation} from "../../../redux/apis/documentsApi";

type AddDocumentProps = {
    setIsAddDocument: Dispatch<any>;
    document?: any
}

export const AddDocument = ({ setIsAddDocument, document }: AddDocumentProps) => {
    const { t } = useTranslation();
    const { data: langs, isFetching } = useGetLangsQuery({});
    const [createDocument] = useCreateDocumentMutation();
    const [updateDocument] = useUpdateDocumentMutation();
    const { openStatus } = useStatusContext();

    if (isFetching || !langs) return <Preloader />

    const validation_data: any = {}

    const validationSchema = yup.object().shape(validation_data);

    const initialValues: any = {
        slug: document?.slug ?? ""
    };

    langs?.data?.forEach((lang: any) => {
        initialValues[`title-${lang.key}`] = document ? document.title?.document_title?.[lang.key] : ''
        initialValues[`description-${lang.key}`] = document ? document.body?.document_body?.[lang.key] :''
    })

    const handleSubmit = async (values: any, {resetForm}: any) => {
        let titleValues: any[] = [];
        let bodyValues: any[] = [];

        langs?.data?.forEach((lang: any) => {
            Object.keys(values).forEach((key) => {
                if (key.includes(lang.key) && key.includes("title")) {
                    titleValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                } else if (key.includes(lang.key) && key.includes("description")) {
                    bodyValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                }
            });
        })

        const result: any = await (document ? updateDocument({
            id: document?.id,
            title: titleValues,
            body: bodyValues,
            slug: values.slug
        }) : createDocument({
            title: titleValues,
            body: bodyValues,
            slug: values.slug
        }));

        if (result?.data?.message === "Document created successful" || result?.data?.message === "Document updated successful") {
            openStatus('success', "status.operationSuccessful");
            setIsAddDocument({ show: false });
            resetForm();
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.container}>
            <Back close={setIsAddDocument} text={"Back to documents"} />

            <div className={s.content}>
                <h1 className={s.content_title}>{!document ? t("general.add") : t("general.edit") }</h1>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue, values, isValid, dirty }) => (
                        <Form className={s.form}>
                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"title"}
                                label={"Title"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"description"}
                                label={"Description"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <Input
                                value={values.author}
                                errors={errors.slug}
                                touched={touched.slug}
                                name={"slug"}
                                label={"Slug"}
                                placeholder={"Enter slug"}
                                onClick={() => setFieldValue("slug", "")}
                            />

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                    disabled={!(isValid && dirty)}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => setIsAddDocument({show: false})}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}