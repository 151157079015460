import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { useDeleteReferralLinkMutation, useGetReferralsLinksQuery } from '../../../../redux/apis/referralApi';
import {ReactComponent as Delete} from "../../../../assets/icons/delete_icon.svg"
import {ReactComponent as Copy} from "../../../../assets/icons/copy.svg"
import {ReactComponent as Success} from "../../../../assets/icons/success.svg"
import s from './ReferalLinks.module.scss';
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { format } from 'date-fns';
import { DateFormat } from 'utils/enums/common.enum';
import { Table } from 'components/Table/Table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CreateReferralLinkModal } from '../CreateReferralLinkModal/CreateReferralLinkModal';
import { ModalGeneral } from 'components/Modals/ModalGeneral';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { ReferralLinkModal } from '../ReferralLinkModal/ReferralLinkModal';

export const ReferalLinks = () => {

    // ### State
    const [params] = useSearchParams()
    const [id, setId] = useState<string | null>(null)
    const [copy, setCopy] = useState<number>(0)
    const [deleteLinkModal, setDeleteLinkModal] = useState(false)
    const [createModal, setCreateModal] = useState(false)

    const navigate = useNavigate()
    const {t} = useTranslation()


    const {data: referralLinks, isLoading: isGetReferralLoading} = useGetReferralsLinksQuery({})
    const [DeleteLink, {isLoading}] = useDeleteReferralLinkMutation()

    console.log("referralLinks", referralLinks);
    // ### Effects
    useEffect(()=>{
        console.log("params.get('id')", params.get('id'));
        if(params.get('id')) setId(params.get('id'))
            else setId(null)
    },[params.get('id')]);

    // ### Functions
    async function handleDelete(id: number){
        await DeleteLink({id})
        
    }

    // ### Views
    const columns: TableColumnType[] = [
            {
                title: t('general.id')
            },
            {
                title: t('general.name')
            },
            {
                title: t('dashboard.referal.clicks')
            },
            {
                title: t('dashboard.referal.registrations')
            },
            {
                title: t('general.link')
            },
            {
                title: t('general.date_created')
            },
            {
                title: ""
            }
        ];
    
    const rows: TableRowType[] = referralLinks?.map((link: any) => {
        const licks = <ColorNumber>{link?.clicks}</ColorNumber>
        const registrations = <ColorNumber>{link?.registrations}</ColorNumber>
        return(
            {
                link: `/dashboard/referral-links?id=${link.id}`,
                values: [
                    {
                        title: link?.id,
                    },
                    {
                        title: link?.name,
                    },
                    {
                        title: licks,
                    },
                    {
                        title: registrations,
                    },
                    {
                        title: link?.link,
                    },
                    {
                        title: format(new Date(Number(link.date)), DateFormat.LONG)
                    },
                    {
                        title: (
                            <div className={s.buttons_wrapper} style={{display: "flex", gap: 10, alignItems: "center"}}>
                                <div 
                                    className={s.copy_wrapper}
                                    style={{width: 25, height: 25}}
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        navigator.clipboard.writeText(link?.referralLink)
                                        .then(() => {
                                            setCopy(Number(link.id))
                                            setTimeout(()=>{
                                                setCopy(0)
                                            }, 2000)
                                        })
                                    }}
                                >
                                    {link.id === copy ? <Success/> : <Copy/>}
                                </div>
                                <div 
                                    className={s.delete_wrapper}
                                    onClick={(ev) => {setDeleteLinkModal(link?.id); ev.stopPropagation()}}
                                    style={{width: 25, height: 25}}
                                ><Delete style={{width: 20, height: 20}}/></div>
                            </div>
                        )
                    }
                ]
            }
        )
    })

    return(
        <div className={s.wrapper}>
            <PrimaryButton 
                text={t('general.add')}
                type="button"
                onClick={()=>setCreateModal(true)}
                style={{width: "fit-content"}}
            />
            <Table 
                rows={rows}
                columns={columns}
                isLoading={isGetReferralLoading}
            />
            <ModalGeneral
                showModal={deleteLinkModal}
                setShowModal={setDeleteLinkModal}
                title={`${t('general.delete')}?`}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={()=> {
                    handleDelete(Number(deleteLinkModal)) 
                    setDeleteLinkModal(false)
                } }
            />
            { createModal ? <CreateReferralLinkModal close={()=>setCreateModal(false)}/> : null }
            { id ? <ReferralLinkModal id={Number(id)} close={()=> {
                setId(null)
                navigate('/dashboard/referral-links')
            }}/> : null }
        </div>
    )
}