import {Select} from "../../Select/Select";
import React from "react";
import {useGetCategoriesQuery} from "../../../redux/apis/shopApi";
import {useTranslation} from "react-i18next";

type AddModuleProps = {
    setProduct: React.Dispatch<any>;
}

export const AddProduct = ({ setProduct }: AddModuleProps) => {
    const {data: categories} = useGetCategoriesQuery({})
    const { t, i18n } = useTranslation();

    const chooseProduct = (value: any) => {
        setProduct(value);
    }

    return (
        <Select
            values={categories}
            defaultValue={t("shop.chooseCategory")}
            change={chooseProduct}
            wantId
        />
    )
}