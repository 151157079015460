import s from "./Table.module.scss"
import { AlignTypes } from "utils/enums/common.enum"
import { TableType } from "utils/types/table.type"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "components/Skeleton/Skeleton"


export const Table = ({rows, columns, isLoading, routeState, totalCount, isModal}: TableType) => {

    // ### State
    const navigation = useNavigate()

    // ### Effects

    // ### Functions

    // ### Views

    const tableColumnsView = columns?.map((column, index) => (
        <td 
            key={index}
            style={{width: (index === 0 && isModal) ? "20px" : ""}}
        >
            {column.title}
        </td>
    ))

    const tableRowsView = rows?.map((row, index) => {
        return(
            <tr
                className={row?.link ? s.link : ""}
                key={index}
                onClick={() => row?.link ? navigation(row.link, {state: routeState}) : null}
            >
                {row.values.map((value, index) => (
                    <td
                        key={index} 
                        align={value.align as AlignTypes}
                    >
                        <span>{value.title}</span>
                    </td>
                ))}
            </tr>
        )
    })                                                                                                                                                                                      

    return(
        <div className={s.wrapper}>
            {
                totalCount?
                <div className={s.total}>
                <p className={s.value}>{`Quantity: ${totalCount}`}</p>
                </div> : null
            }
            <table className={s.table}>
                <thead className={s.head}>
                    <tr>{tableColumnsView}</tr>
                </thead>
                <tbody className={s.body}>
                {tableRowsView}
                </tbody>
            </table>
            { isLoading ? <Skeleton isLoading={isLoading} /> : null }
        </div>
    )
}