import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const shopApi = createApi({
    reducerPath: 'shopApi',
    tagTypes: ['Category', 'Delivery', 'Products'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

        getCategories: builder.query({
            query: (params) => ({
                url: `/api/shop/category/get`,
                method: "GET",
                params
            }),
            providesTags: ["Category"]
        }),

        createCategory: builder.mutation({
            query: (body) => ({
                url: `/api/shop/category/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Category"]
        }),

        editCategory: builder.mutation({
            query: (body) => ({
                url: `/api/shop/category/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Category"]
        }),

        deleteCategory: builder.mutation({
            query: (body) => ({
                url: `/api/shop/category/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Category"]
        }),

        getDelivery: builder.query({
            query: (params) => ({
                url: `/api/shop/delivery/get`,
                method: "GET",
                params
            }),
            providesTags: ["Delivery"]
        }),

        createDelivery: builder.mutation({
            query: (body) => ({
                url: `/api/shop/delivery/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Delivery"]
        }),

        editDelivery: builder.mutation({
            query: (body) => ({
                url: `/api/shop/delivery/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Delivery"]
        }),

        deleteDelivery: builder.mutation({
            query: (body) => ({
                url: `/api/shop/delivery/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Delivery"]
        }),
        getProducts: builder.query({
            query: (params) => ({
                url: `/api/shop/product/get`,
                method: "GET",
                params
            }),
            providesTags: ["Products"]
        }),
        createProduct: builder.mutation({
            query: (body) => ({
                url: `/api/shop/product/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProduct: builder.mutation({
            query: (body) => ({
                url: `/api/shop/product/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Products"]
        }),
        updateProduct: builder.mutation({
            query: (body) => ({
                url: `/api/shop/product/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Products"]
        }),
    })
})

export const {
    useGetCategoriesQuery,
    useCreateCategoryMutation,
    useEditCategoryMutation,
    useDeleteCategoryMutation,

    useGetDeliveryQuery,
    useCreateDeliveryMutation,
    useEditDeliveryMutation,
    useDeleteDeliveryMutation,

    useGetProductsQuery,
    useCreateProductMutation,
    useDeleteProductMutation,
    useUpdateProductMutation
} = shopApi;