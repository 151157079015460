import React, { FC, useEffect, useRef } from 'react';
import s from './tabs.module.scss';
import { useNavigate } from 'react-router-dom';

type TabsType = {
    items: {
        id: number
        title: string | React.ReactElement,
        path?: string
    }[]
    setTab?: (id: number) => void
    tab: number,
    fontSize?: string;
}

export const Tabs: FC<TabsType> = ({items, setTab, tab, fontSize = '18px'}) => {

    // ### STATE

    const navigate = useNavigate()
    const tabs = useRef<HTMLSpanElement[]>([])
    const line = useRef<HTMLSpanElement>(null)

    // ### FUNCTIONS

    useEffect(()=>{
        let left_distance = 0;
        let current_width = 0;
        if(tabs.current){
            current_width = tabs.current[(tab)].offsetWidth
            tabs.current.slice(0, tab)?.forEach(el => {
                left_distance = left_distance + el.offsetWidth
            })
        }
        if(line.current){
            line.current.style.cssText = `width: ${current_width}px; left: ${left_distance}px`
        }
        
    },[tab, tabs]);

    // ### VIEWS

    const items_view = items?.map((item, index) => {
        return (
            <span 
                style={{fontSize}}
                key={index}
                className={`${s.item} ${tab === index? s.item_active : ''}`}
                onClick={() => {
                    setTab && setTab(index)
                    item.path && navigate(item.path)
                }}
                ref={(e) => {
                    if(e) tabs.current[index] = e
                }}
            >
                {item.title}
            </span>
        )
    })

    return(
        <div className={s.tabs_wrapper}>
            {items_view}
            <span className={s.line} ref={line}></span>
        </div>
    )
}