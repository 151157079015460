import s from './TextFields.module.scss';
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import translateIcon from "assets/icons/translate.webp";
import { useGetLangsQuery } from '../../redux/apis/langApi';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { translateText } from 'utils/translateText';

type TextFiledsBlockType = {
    title: string,
    values: {[key: string]: string},
    changeValue: (value: {[key: string]: string}) => void
    setLang?: (str:string) => void
    styles?: CSSProperties
    currentLang?: string
}

export const TextFields: FC<TextFiledsBlockType> = ({title, values, changeValue, setLang, styles, currentLang}) => {

    // ### State
    const { data: langs } = useGetLangsQuery({});
    const [activeLang, setActiveLang] = useState(langs?.data?.[0].key)

    const input = useRef<HTMLInputElement>(null)

    // ### Effects
    useEffect(()=>{
        if(input.current) input.current.focus()
    },[activeLang]);

    useEffect(()=>{
        if(langs?.data) setActiveLang(langs?.data?.[0]?.key)
    },[langs]);

    // ### Functions

    function getCurrentValue(){
        let value
        for(let i=0; i<langs?.data?.length; i++){
            if(values[langs?.data?.[i].key]?.length){
                value = values[langs?.data?.[i].key]
                break
            }
        }
        return value
    }

    async function handleTranslate(){
        const currentValue = getCurrentValue()
        const result = await translateText(currentValue as string, langs?.data?.map((l: any) => l.key));
        changeValue(result)
    }

    // ### Views

    const langsButtonsView = langs?.data?.map((ln: any, index: number) => {
        const completed = values?.[ln.key]?.length
        return (
            <div
                onClick={() => setActiveLang(ln.key)}
                className={`${s.languageButton} ${activeLang === ln.key ? s.selected : ''}`}
            >
                {ln.key}
                {completed ? <CheckIcon className={s.check_icon}/> : null}
            </div> 
        )
})

    return (
        <div className={s.dropdown}>
            <div className={s.languageButtons}>
                {langsButtonsView}
                <img
                    src={translateIcon}
                    alt={"translateIcon"}
                    className={s.translateIcon}
                    onClick={handleTranslate}
                />
            </div>

            <div className={`${s.content} ${false ? s.hide : ''} ${false ? s.none : ''}`}>
                <div className={s.content_head}>
                    <p><span>{title}</span></p>
                </div>
                
                <input 
                    ref={input}
                    type="text"
                    value={values?.[activeLang]?.length? values[activeLang] : ''}
                    onChange={(e) => {
                        changeValue({[activeLang]: e.target.value})
                    }}
                />

            </div>
        </div>
    );
}