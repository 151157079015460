import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const blogApi = createApi({
    reducerPath: 'blogApi',
    tagTypes: ['Blog'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getBlogs: builder.query({
            query: (params) => ({
                url: `/api/blog/get`,
                method: "GET",
                params
            }),
            providesTags: ["Blog"]
        }),
        createBlog: builder.mutation({
            query: (body) => ({
                url: `/api/blog/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Blog"]
        }),
        updateBlog: builder.mutation({
            query: (body) => ({
                url: `/api/blog/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Blog"]
        }),
        deleteBlog: builder.mutation({
            query: (body) => ({
                url: `/api/blog/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Blog"]
        }),
    })
})

export const {
    useGetBlogsQuery,
    useCreateBlogMutation,
    useUpdateBlogMutation,
    useDeleteBlogMutation
} = blogApi;