import imageCompression from "browser-image-compression";

type Props = {
    compressFiles: any;
    image?: boolean;
    video?: boolean;
}

export const compressorFiles = async ({ compressFiles, image = false, video = false }: Props) => {
    let files: any = compressFiles;
    let flagSize: any = false;
    let flagType: any = false;

    if (Array.isArray(files)) {
        for (let i = 0; i < files.length; i++) {
            const {
                file,
                flagSize: flagS,
                flagType: flagT
            }: any = await typesFile({ file: files[i], flagSize, flagType, image, video });

            flagSize = flagS;
            flagType = flagT;

            if (flagSize || flagType) {
                return [];
            } else {
                files[i] = file;
            }
        }

        if (!flagSize && !flagType) {
            return files;
        }
    } else {
        const {
            file,
            flagSize: flagS,
            flagType: flagT
        }: any = await typesFile({ file: files, flagSize, flagType, image, video });

        flagSize = flagS;
        flagType = flagT;

        if (flagSize || flagType) {
            return undefined;
        } else {
            files = file;
        }

        if (!flagSize && !flagType) {
            return files;
        }
    }
};

export const maxSize = 15000000;
export const typesImage = ["jpeg", "jpg", "png", "webp", "svg"];
export const typesDocs = ["xls", "pdf", "xlsx", "docx", "doc", "docs"];
export const typesVideo = ["mp4", "mov", "avi", "webm"];

interface TypesFileParams {
    file: File;
    flagSize: boolean;
    flagType: boolean;
    image: boolean;
    video: boolean;
}

const typesFile = async ({ file, flagSize, flagType, image, video }: TypesFileParams) => {
    const types: any = [...typesImage, ...typesDocs, ...typesVideo];
    const options = {
        maxSizeMB: 0.5,
        useWebWorker: true,
        fileType: 'image/webp'
    };

    try {
        if (file instanceof Blob) {
            let fileCompress = file;

            const type: any = file?.name?.slice(-5).split(".")?.at(-1)?.toLowerCase();

            if (image && !typesImage.includes(type)) {
                return { file: null, flagSize: true, flagType: true };
            }

            if (video && !typesVideo.includes(type)) {
                return { file: null, flagSize: true, flagType: true };
            }

            if (!types.some((typ: any) => typ === type)) {
                return { flagSize, flagType: true };
            }

            if (fileCompress.size >= maxSize) {
                return { flagSize: true, flagType };
            }

            if (typesImage.some((typ) => typ === type)) {
                fileCompress = await imageCompression(file, options);
            }

            if (typesVideo.some((typ) => typ === type)) {
                return { file: fileCompress, flagSize, flagType };
            }

            return { file: fileCompress, flagSize, flagType };
        } else {
            return { file: null, flagSize: true, flagType: true };
        }
    } catch (e) {
        console.log("error", e);
    }
};
