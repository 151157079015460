import React, { FC, useState } from 'react'
import s from "./MailingItem.module.scss"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import {ReactComponent as CompletedIcon} from "../../../../../../assets/icons/completed.svg";
import {ReactComponent as InProcessIcon} from "../../../../../../assets/icons/process.svg";
import {ReactComponent as MenuIcon} from "../../../../../../assets/icons/more_vertical.svg";
import {ReactComponent as EditIcon} from "../../../../../../assets/icons/edit_icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/icons/delete_icon.svg";
import { DateFormat } from 'utils/enums/common.enum'
import { useDeleteMailingMutation } from '../../../../../../redux/apis/mailingApi'
import { ModalGeneral } from 'components/Modals/ModalGeneral'
import { ProgressLine } from 'components/ProgressLine/ProgressLine'


type MailingItemType = {
    data: {
        date: string
        name: string
        id: string
        inProcess: boolean
        completed: boolean
        mailing_type: number
        all_send_count: number
        send_count: number
        start_date: string
        email_count: string | number
        users_length: string | number
        changeAll: boolean
        statistics: {
            users: number
            sended: number
            errors: number
        }
    }
}

export const MailingItem: FC<MailingItemType> = ({data}) => {
    console.log("data", data);
    // ### State
    const [menuState, setMenuState] = useState<boolean>(false)
    const [confirmModal, setConfirmModal] = useState(false)

    const [DeleteMailing] = useDeleteMailingMutation()

    const navigate = useNavigate()
    const {t} = useTranslation()
    const all = data?.changeAll ? Number(data.users_length) : Number(data.email_count)
    const errors = Number(data?.statistics.errors)
    const sended = Number(data?.statistics.sended)
    
    // ### Effects

    // ### Functions
    async function handleDelete(){
        await DeleteMailing({id: data?.id})
    }

    // ### Views

    return(
        <div className={s.mailing}>
            <div className={s.header}>
                <div className={s.date_wrapper}>
                    {
                        data.inProcess ? 
                        <div className={`${s.icon_wrapper} ${s.rotate}`}><InProcessIcon/></div> :
                        data.completed ? 
                        <div className={s.icon_wrapper}><CompletedIcon/></div> : null
                    }
                    { data.mailing_type ? 
                        <div className={s.header_date}>{t('mailings.sended')}
                            <div className={s.date_wrapper}>
                                {data.send_count === 0 ? `${data.all_send_count}/${data.all_send_count}` : 
                                `${(Number(data.all_send_count) - Number(data.send_count))}/${data.all_send_count}`}
                            </div>
                        </div> : null
                    }
                    {
                        data.mailing_type && data.send_count ? 
                        <div className={s.header_date}>{t('mailings.next_send')} <div className={s.date_wrapper}>{format(new Date(data.start_date), DateFormat.LONG)}</div></div> : null
                    }
                    <p className={s.header_date}>{t('mailings.send_date')} <span className={s.date_wrapper}>{format(new Date(data.date), DateFormat.LONG)}</span></p>
                    <p className={s.header_date}>{t('general.date_created')} <span className={s.date_wrapper}>{format(new Date(data.date), DateFormat.LONG)}</span></p>
                </div>
                <div className={s.menu_wrapper}>
                    <div className={s.icon_wrapper} onClick={()=>setMenuState(true)}><MenuIcon/></div>
                    {
                        menuState ?
                        <div className={s.header_menu}>
                            <div className={s.close_wrapper} onClick={()=>setMenuState(false)}/>
                            <div className={s.menu_item} onClick={()=>navigate(`/mailings/list/create?id=${data.id}`)}>
                                <EditIcon/>{t('general.edit')}
                            </div>
                            <div className={s.menu_item} onClick={()=>{
                                setConfirmModal(true)
                            }}>
                                <DeleteIcon/>
                                {t('general.delete')}
                            </div>
                        </div> : null
                    }
                </div>
            </div>
            <div className={s.content}>
                <div className={s.name}>
                    <span onClick={()=>navigate(`/mailings/list/about?id=${data.id}`)}>{data.name}</span>
                </div>
                <div className={s.statistics_wrapper}>
                    <div className={s.statistics_item}>
                        <span className={s.value}>{data.changeAll? data.users_length : data.email_count}</span>
                        <span className={s.title}>{t('mailings.total')}</span>
                    </div>
                    <div className={s.statistics_item}>
                        <span className={s.value}>{data.statistics.sended}</span>
                        <span className={s.title}>{t('mailings.sended')}</span>
                    </div>
                    <div className={s.statistics_item}>
                        <span className={s.value}>{data.statistics.errors}</span>
                        <span className={s.title}>{t('mailings.errors')}</span>
                    </div>
                </div>
            </div>
            <div className={s.progress_wrapper}>
                <ProgressLine count={all} progress={sended}/>
                <span className={s.procent}>{(sended / all * 100).toFixed(0)}%</span>
            </div>
            <ModalGeneral
                showModal={confirmModal}
                setShowModal={setConfirmModal}
                title={`${t('mailings.delete_confirm')}`}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={handleDelete}
            />
        </div>
    )
}