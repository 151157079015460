import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/close.svg"
import s from './CreateGroup.module.scss';
import { useState } from 'react';
import { useCreateGroupMutation, useUpdateGroupMutation } from '../../../../../../redux/apis/mailingApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CreateGroup = ({state, close}: any) => {

    // ### State
    const [form, setForm] = useState<any>({
        name: state?.name || undefined
    })
    const [errors, setErrors] = useState({name: false})
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [createGroup, {isLoading: isCreateLoading}] = useCreateGroupMutation()
    const [updateGroup, {isLoading: isUpdateLoading}] = useUpdateGroupMutation()

    // ### Effects

    // ### Functions
    async function handleSubmit(){
        let error = false
        if(!form.name || form.name?.length < 2){
            setErrors(prev => ({...prev, name: true}))
            error = true
        } else setErrors(prev => ({...prev, name: false}))

        console.log(error);
        if(error) return

        const action = (typeof state === "object" && state?.id) ? updateGroup : createGroup
        const response = await action({name: form.name, id: (typeof state === "object" && state?.name) ? state.id : undefined})
        if(response.data.message === "group_created" || response.data.message === "group_update") {
            close()
            navigate(`/mailings/groups/group/${response.data.id}`)
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.back}></div>
            <div className={s.modal}>
                <div className={s.header}>
                   <div className={s.title}>{state?.name ? t('mailings.edit_group') : t('mailings.create_group')}</div>
                    <div className={s.close_wrapper} onClick={()=>close()}><CloseIcon/></div>
                </div>
                <div className={s.content}>
                    <div className={s.item}>
                        <span className={errors?.name ? s.error : ""}>{t('general.name')}</span>
                        <input 
                            type="text"
                            value={form.name}
                            onChange={(ev) => setForm((prev: any) => ({...prev, name: ev.target.value}))}
                        />
                    </div>
                </div>
                <div className={s.footer}>
                    <PrimaryButton 
                        type={"button"} 
                        text={t('general.save')}
                        disabled={(isCreateLoading || isUpdateLoading)}
                        onClick={handleSubmit}
                        style={{width: "fit-content", margin: "0 auto"}}
                    />
                </div>
            </div>
        </div>
    )
}