import s from './UserPage.module.scss';
import { ReactComponent as Arrow } from "../../assets/icons/arrow_tools.svg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserPageType } from 'utils/types/user.type';
import { useGetUserInfoQuery } from '../../redux/apis/usersApi';
import { ReactComponent as Google } from "../../assets/icons/google_icon.svg"
import { ReactComponent as Gmail } from "../../assets/icons/email_icon.svg"
import { useEffect, useState } from 'react';
import { Skeleton } from 'components/Skeleton/Skeleton';
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { DropdownBlock } from 'components/DropdownBlock/DropdownBlock';
import { Table } from 'components/Table/Table';
import { Courses } from './components/courses/Courses';
import { Consultations } from './components/consultations/Consultations';
import { Payments } from './components/payments/Payments';
import { useTranslation } from 'react-i18next';

export const UserPage = ({navigation}: UserPageType) => {

    // ### State
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state
    const { t } = useTranslation()

    const [nav, setNav] = useState({prev: undefined, next: undefined})
    const [dropdown, setDropdown] = useState({courses: false, guides: false, consultations: false, payments: false})
    
    const {data: user, isFetching: isGetUserLoading} = useGetUserInfoQuery({id: params["*"]})
   

    // ### Effects
    useEffect(()=>{
        if(state && state.users?.length){
            const index = state.users.indexOf(Number(params["*"]));
            const prev = index > 0 ? state.users[index - 1] : null;
            const next = index < state.users.length - 1 ? state.users[index + 1] : null; 
            setNav({prev, next})
        }
    },[state, params["*"]]);

    // ### Functions

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.header}>
                <div className={s.left}>
                    <div className={s.back_arrow} onClick={() => navigate(state?.link ? state.link : "/dashboard/users", {state})}><Arrow/>{t('general.back')}</div>
                    {
                        nav.prev ? 
                        <div className={s.left_arrow} onClick={() => navigate(`/dashboard/user/${nav.prev}`, {state})}><Arrow/>{t('general.prev')}</div>
                        : <span></span>
                    }
                </div>
                <span className={s.email}>{user?.email}</span>
                {
                    nav.next ? 
                    <div className={s.right_arrow} onClick={() => navigate(`/dashboard/user/${nav.next}`, {state})}>{t('general.next')}<Arrow/></div>
                    : <span></span>
                }
            </div>

            <div className={`${s.content}`}>
                <div className={s.left}>
                    <div className={s.titles}>
                        <span className={s.title}>{t('general.email')}:</span>
                        <span className={s.title}>{t('dashboard.users.name')}:</span>
                        <span className={s.title}>{t('dashboard.users.registration_type')}:</span>
                        {user?.refer ? <span className={s.title}>{t('dashboard.users.user_from')}:</span> : null}
                    </div>
                    <div className={s.values}>
                        <span className={s.value}>{user?.email}</span>
                        <span className={s.value}>{user?.name || "- - -"}</span>
                        <span className={s.value} style={{textTransform: "capitalize"}}>
                            {user?.registration_type}
                            {user?.registration_type === "google" ? <Google/> : <Gmail/>}
                        </span>
                        {user?.refer ?
                        <div className={s.value}>
                            <img src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${user?.refer}`} alt="favicon" />
                            {user?.refer}
                        </div> : null}
                    </div>
                    <Skeleton isLoading={isGetUserLoading} />
                </div>
                <div className={s.left}>
                    <div className={s.titles}>
                        <span className={s.title}>{t('dashboard.users.courses')}:</span>
                        <span className={s.title}>{t('dashboard.users.guides')}:</span>
                        <span className={s.title}>{t('dashboard.users.consultations')}:</span>
                        <span className={s.title}>{t('dashboard.users.payments')}:</span>
                    </div>
                    <div className={s.values}>
                        <span className={s.value}>{t('general.active')}: <ColorNumber>{user?.courses_length?.active}</ColorNumber> {t('general.all')}: <ColorNumber>{user?.courses_length?.count}</ColorNumber></span>
                        <span className={s.value}>{t('general.active')}: <ColorNumber>{user?.guides_length?.active}</ColorNumber> {t('general.all')}: <ColorNumber>{user?.guides_length?.count}</ColorNumber></span>
                        <span className={s.value}>{t('general.active')}: <ColorNumber>{user?.calendar_length?.active}</ColorNumber> {t('general.all')}: <ColorNumber>{user?.calendar_length?.count}</ColorNumber></span>
                        <span className={s.value}><ColorNumber>{user?.payments?.length}</ColorNumber></span>
                    </div>
                    <Skeleton isLoading={isGetUserLoading} />
                </div>
            </div>

            <div className={s.dropdown_block}>
                <div className={`${s.header} ${dropdown?.payments ? s.active : ""}`} onClick={() => setDropdown(prev => ({...prev, payments: !prev.payments}))}>
                    <div className={`${s.arrow}`}><Arrow/></div>
                    <span className={s.title}>{t('dashboard.users.payments')}</span>
                </div>
                <DropdownBlock open={dropdown.payments}>
                    <Payments payments={user?.payments}/>
                </DropdownBlock>
            </div>

            <div className={s.dropdown_block}>
                <div className={`${s.header} ${dropdown?.courses ? s.active : ""}`} onClick={() => setDropdown(prev => ({...prev, courses: !prev.courses}))}>
                    <div className={`${s.arrow}`}><Arrow/></div>
                    <span className={s.title}>{t('dashboard.users.courses')}</span>
                </div>
                <DropdownBlock open={dropdown.courses}>
                    <Courses courses={user?.courses} payments={user?.payments?.filter?.((payment: any) => payment.type === "course")}/>
                </DropdownBlock>
            </div>

            <div className={s.dropdown_block}>
                <div className={`${s.header} ${dropdown?.guides ? s.active : ""}`} onClick={() => setDropdown(prev => ({...prev, guides: !prev.guides}))}>
                    <div className={`${s.arrow}`}><Arrow/></div>
                    <span className={s.title}>{t('dashboard.users.guides')}</span>
                </div>
                <DropdownBlock open={dropdown.guides}>
                    <Courses courses={user?.courses} payments={user?.payments?.filter?.((payment: any) => payment.type === "guide")}/>
                </DropdownBlock>
            </div>

            <div className={s.dropdown_block}>
                <div className={`${s.header} ${dropdown?.consultations ? s.active : ""}`} onClick={() => setDropdown(prev => ({...prev, consultations: !prev.consultations}))}>
                    <div className={`${s.arrow}`}><Arrow/></div>
                    <span className={s.title}>{t('dashboard.users.consultations')}</span>
                </div>
                <DropdownBlock open={dropdown.consultations}>
                    <Consultations consultations={user?.calendar}/>
                </DropdownBlock>
            </div>

        </div>
    )
}