import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const documentsApi = createApi({
    reducerPath: 'documentsApi',
    tagTypes: ['Documents'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getDocuments: builder.query({
            query: (params) => ({
                url: `/api/documents/get`,
                method: "GET",
                params
            }),
            providesTags: ["Documents"]
        }),
        createDocument: builder.mutation({
            query: (body) => ({
                url: `/api/documents/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Documents"]
        }),
        updateDocument: builder.mutation({
            query: (body) => ({
                url: `/api/documents/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Documents"]
        }),
        deleteDocument: builder.mutation({
            query: (body) => ({
                url: `/api/documents/delete`,
                method: "DELETE",
                body
            }),
            invalidatesTags: ["Documents"]
        }),
    })
})

export const {
    useGetDocumentsQuery,
    useCreateDocumentMutation,
    useUpdateDocumentMutation,
    useDeleteDocumentMutation
} = documentsApi;