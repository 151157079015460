import { HeaderSection } from 'components/HeaderSection/HeaderSection';
import s from './Category.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from '../../redux/apis/shopApi';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { useState } from 'react';
import i18n from 'i18n';
import { CreateCategoryModal } from './createModal/CreateCategoryModal';
import { Preloader } from 'components/preloader/Preloader';
import { ReactComponent as MoreVertical } from "assets/icons/more_vertical.svg";
import { MenuMini } from 'components/menuMini/MenuMini';
import edit_icon from 'assets/icons/edit_icon.svg';
import deleteIcon2 from 'assets/icons/delete_icon2.svg';

export const Category = () => {

    // ### State

    const [createModal, setCreateModal] = useState<any>(false)
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [idMenu, setIdMenu] = useState<number>();
    const {t} = useTranslation()

    const {data: categories, isFetching} = useGetCategoriesQuery({})
    const [deleteCategory, {isLoading: isDeleteLoading}] = useDeleteCategoryMutation()
    // ### Effects

    // ### Functions

    function handleEditCategory(id: any){
        const category = categories?.find((el: any) => el.id === id)
        setCreateModal(category)
    }

    // ### Views
    const section_items = [
        { icon: edit_icon, text: t("general.edit"), callback: () => {handleEditCategory(idMenu)} },
        { icon: deleteIcon2, text: t("general.delete"), callback: () => {deleteCategory({id: idMenu})}}
    ];

    const categoriesView = categories?.map((category: any, index: number) => {
        return(
            <div className={s.category} key={index}>
                <div className={s.tools}>
                    <div className={s.bg}>
                        <MoreVertical className={s.more_content} onClick={() => {
                            setIdMenu(category.id);
                            setOpenMenu(!openMenu);
                        }} />
                    </div>
                    <MenuMini
                        items={section_items}
                        open={openMenu && idMenu === category.id}
                        close={setOpenMenu}
                        section
                    />
                </div>
                <span className={s.title}>{category.name?.[i18n.language]}</span>
                <img className={s.background} src={`${category.image}`} alt="category" />
            </div>
        )
    })

    return(
        <div className={s.wrapper}>
            <HeaderSection
                title={t("shop.category_page_title")}
                buttonText={t("dashboard.customize")}
                icon={<Brush />}
                disabled
            />

            <div className={s.content}>
                {categoriesView}
            </div>

            <div className={s.footer}>
                <PrimaryButton 
                    text={t('shop.add_category')} 
                    type={undefined}
                    onClick={()=>{setCreateModal(true)}}
                />
            </div>

            { createModal ? <CreateCategoryModal data={createModal} close={()=>setCreateModal(false)}/> : null }
            { (isFetching || isDeleteLoading) ? <Preloader/> : null }
        </div>
    )
}