import React, { useEffect, useRef } from "react";
import s from "./Storage.module.scss" 
import { FileManager } from "components/FileManager/FileManager";

export const Storage = () => {

    const storageRef = useRef<HTMLDivElement>(null)

    return (
        <div className={s.storage} ref={storageRef}>
            <FileManager/>
        </div>
    )
};
