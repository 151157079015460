import s from "./Card.module.scss";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import React, {Dispatch, SetStateAction, useState} from "react";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_image.svg";
import {Term} from "utils/term";
import {useDeleteProductMutation} from "../../../../redux/apis/shopApi";
import {useStatusContext} from "components/StatusProvider";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useTranslation} from "react-i18next";

type CardProps = {
    setEditPage: Dispatch<SetStateAction<any>>
    product: any;
}

export const Card = ({setEditPage, product}: CardProps) => {
    const [deleteProduct] = useDeleteProductMutation();
    const { openStatus } = useStatusContext();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { t, i18n } = useTranslation();

    const handleDelete = async (id: number) => {
        const result = await deleteProduct({id: id});

        if (result?.data?.message === "Product delete successful") {
            openStatus('success', "status.operationSuccessful");
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.card}>
            <div className={s.card_image}>
                <DeleteIcon className={s.delete} onClick={() => setShowDeleteModal(true)} />
                <img src={product?.images?.[0]} alt={"product"} />
            </div>

            <div className={s.card_wrap}>
                <h3 className={s.card_title}>
                    <Term>{product?.title}</Term>
                </h3>

                <div className={s.card_bottom}>
                    <div className={s.price}>
                        <p>{`${t("shop.price")}:`}</p>
                        <span>${product?.price}</span>
                        <p>ID:</p>
                        <span>{product?.id}</span>
                    </div>

                    <EditIcon onClick={() => setEditPage({
                        open: true,
                        categoryId: Number(product?.category),
                        product: product}
                    )}
                    />
                </div>
            </div>
            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={`${t("general.areYouSure")}? ${t("general.itsContents")}`}
                deletedId={product?.id}
                buttonRight={handleDelete}
            />
        </div>
    )
}