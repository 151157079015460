import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import s from './One.module.scss';
import { useUpdateGroupMutation } from '../../../../../../../../redux/apis/mailingApi';
import { useState } from 'react';
import { validationEmail } from 'utils/validation';
import { useTranslation } from 'react-i18next';

export const One = ({id, contacts, close, setTab}: any) => {

    // ### State
    const [email, setEmail] = useState<string>("")
    const [error, setError] = useState<boolean>(false)
    const {t} = useTranslation()

    const [Update, {isLoading}] = useUpdateGroupMutation()

    // ### Effects

    // ### Functions
    async function handleUpdate(){
        const test = await validationEmail(email)
        if(!test){
            setError(true)
            return
        }
        const response = await Update({id, contacts: [...contacts, {email}]})
        if(response.data.message === "group_update"){
            setTab(1)
            close()
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.item}>
                <span className={s.title} style={{color: error ? "red" : ""}}>Email</span>
                <input 
                    type="text" 
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                />
            </div>
            <PrimaryButton  
                text={t('general.add')}
                type={"button"}
                onClick={handleUpdate}
                style={{width: "fit-content"}}
                disabled={isLoading}
            />
        </div>
    )
}