import React, {useEffect, useRef} from 'react';
import s from "./menuMini.module.scss";

type MenuMini = {
    items: any;
    open: boolean;
    close:  React.Dispatch<React.SetStateAction<boolean>>;
    page?: boolean;
    section?: boolean;
}

export const MenuMini = ({ items, open, close, page, section } : MenuMini) => {
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                close(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!open) {
        return null;
    }

    const handleCallback = (el: any) => {
        el.callback();
        close(false);
    }

    const items_view = items?.map((el: any, index: number) => {
        return (
            <div key={index} className={s.item} onClick={()=> handleCallback(el)}>
                <span>{el.text}</span>
                <img src={el.icon} alt="icon" />
            </div>
        )
    })

    return (
        <div className={`${s.menu} ${page && s.page} ${section && s.section}`} ref={menuRef}>
            {items_view}
        </div>
    )
}