import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import s from './textFieldsBlock.module.scss';
import { useSelector } from 'react-redux';

type TextFiledsBlockType = {
    title: string,
    values: {[key: string]: string},
    changeValue: (value: {[key: string]: string}) => void
    setLang?: (str:string) => void
    styles?: CSSProperties
    currentLang?: string
    activeLang?: any
    setActiveLang?: any
}

export const TextFieldsBlock: React.FC<TextFiledsBlockType> = ({title, values, changeValue, setLang, styles, activeLang, setActiveLang}) => {

    const langs = useSelector((store: any) => store.main.langs);
   
    // const [activeLang, setActiveLang] = useState(currentLang || langs?.[0]?.key)

    const input = useRef<HTMLInputElement>(null)

    // ### EFFECTS
    useEffect(()=>{
        if(input.current){
            const inputElement: HTMLInputElement = input.current.children[0] as HTMLInputElement
            inputElement?.focus()
        }
    },[activeLang]);
    if(!langs) return <></>
    const langs_view = langs?.map((el: any, index: number) => {
        const completed = values?.[el.key]?.length
        return(
            <div 
                key={index}
                className={`${s.lang_item} ${activeLang === el.key? s.active_lang_item : ''} ${completed? s.completed_lang_item : ''}`}
                onClick={()=>{
                    setActiveLang(el.key)
                    if(setLang) setLang(el.key)
                }}
            >
                {el.key}
                <span>{completed? <span>&#10004;</span> : <span>&#10008;</span>}</span>
            </div>
        )
    })

    // function setTranslateText(values){
    //     Object.entries(values).forEach(value => {
    //         changeValue({[value[0]]: value[1]})
    //     })
    // }

    return(
        <div className={s.wrapper}>
            <header>
                <p>{title}</p>
                <div className={s.langs_wrapper}>
                    {langs_view}
                </div>
                <div className={s.translator_wrapper}>
                    {/* <TextTranslator values={values} setForm={setTranslateText} /> */}
                </div>
            </header>
            <div className={s.input_wrapper}>
                <input
                    type="text"
                    ref={input}
                    style={{ width: "50%", maxWidth: 800, ...styles}}
                    value={values?.[activeLang]?.length? values[activeLang] : ''}
                    onChange={(e) => {
                        changeValue({[activeLang]: e.target.value})
                    }}
                />
            </div>
        </div>
    )
}