import s from './Statistics.module.scss';
import { useGetDashboardStatisticsQuery } from '../../../../redux/apis/statisticsApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticsButtons } from './components/StatisticsButtons/StatisticsButtons';
import { StaisticsGraph } from './components/StatisticsGraph/StaisticsGraph';
import { Attendance } from './components/Attendance/Attendance';


export const Statistics = () => {

    // ### State
    const {t} = useTranslation()

    const DASHBOARD_STATISTICS = {
        BUTTONS_STATISTICS:[
            {name: "registration_all"},
            {name: "payments_all"},
            {name: "courses_all"},
            {name: "guides_all"},
            {name: "consultations_all"},
        ],
        GRAPHIC_STATISTICS:[
            {name: "registration", title: t('dashboard.statistics.registrations'), color: "green"},
            {name: "payments", title: t('dashboard.statistics.payments'), color: "#FF6600"},
            {name: "courses", title: t('dashboard.statistics.courses'), color: "#0e349c"},
            {name: "guides", title: t('dashboard.statistics.guides'), color: "#8a1218"},
            {name: "consultations", title: t('dashboard.statistics.consultations'), color: "#17ad8f"}
        ]
    }

    const [activeLines, setActiveLines] = useState([DASHBOARD_STATISTICS.GRAPHIC_STATISTICS[0]]);


    const [query, setQuery] = useState({
        types: [
            ...DASHBOARD_STATISTICS.GRAPHIC_STATISTICS.map(el => el.name),
            ...DASHBOARD_STATISTICS.BUTTONS_STATISTICS.map(el => el.name),
        ],
        startDate: 0,
        endDate: new Date().getTime()
    })
    
    const {data: statistics,  isLoading: isStatisticsLoading } = useGetDashboardStatisticsQuery(query)
       
    // ### Effects

    // ### Functions
   
    // ### Views
   
    return(
        <div className={s.wrapper}>
            <StatisticsButtons
                buttons={DASHBOARD_STATISTICS.GRAPHIC_STATISTICS}
                activeLines={activeLines}
                setActiveLines={setActiveLines}
                data={statistics}
                isLoading={isStatisticsLoading}
                setDateRange={(value: any) => {
                    setQuery(prev => ({...prev, startDate: value.startDate, endDate: value.endDate}))
                }}
            />
            <StaisticsGraph 
                data={statistics?.data}
                query={query}
                activeLines={activeLines}
                isLoading={isStatisticsLoading}
            />
            <Attendance />
        </div>
    )
}