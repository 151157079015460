import React, { useMemo, useState } from 'react';
import s from './StyledHTMLFieldsBlock.module.scss';



import { useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';


type StyledHTMLFieldsBlockType = {
    title: string,
    values: {[key: string]: string},
    changeValue: (value: {[key: string]: string}) => void
    setLang?: (str:string) => void
    activeLang: string
    setActiveLang?: any
}

export const StyledHTMLFieldsBlock: React.FC<StyledHTMLFieldsBlockType> = ({title, values, changeValue, setLang, activeLang, setActiveLang}) => {

    const langs = useSelector((store: any) => store.main.langs);
    const store = useSelector((store: any) => store.main);
    
    if(!langs) return <></>
    const langs_view = langs?.map((el: any, index: number) => {
        const completed = values?.[el.key]?.length
        return(
            <div 
                key={index}
                className={`${s.lang_item} ${activeLang === el.key? s.active_lang_item : ''} ${completed? s.completed_lang_item : ''}`}
                onClick={()=>{
                    setActiveLang(el.key)
                    if(setLang) setLang(el.key)
                }}
            >
                {el.key}
                <span>{completed? <span>&#10004;</span> : <span>&#10008;</span>}</span>
            </div>
        )
    })

    return(
        <div className={s.wrapper}>
            <header>
                <p>{title}</p>
                <div className={s.langs_wrapper}>
                    {langs_view}
                </div>
            </header>
            <div className={s.input_wrapper}>
                <Editor
                    tinymceScriptSrc='/tinymce/tinymce.min.js'
                    init={{
                        branding: false,
                        width: 'inherit',
                        skin: "oxide-dark",
                        content_css: "dark",
                        height: 500,
                        menubar: false,
                        plugins: "image link media",
                        toolbar:
                            "styleselect undo redo | h1 h2 h3 | bold italic underline strikethrough | image link media alignleft aligncenter alignright alignjustify | bullist outdent indent | removeformat",
                        image_advtab: false,
                        image_class_list: [
                            {title: 'Center', value: 'image_center'},
                            {title: 'Left', value: 'image_left'},
                            {title: 'Right', value: 'image_right'},
                            {title: 'Left Float', value: 'image_left_float'},
                            {title: 'Right Float', value: 'image_right_float'}
                        ],
                        style_formats: [
                            {
                                title: 'image_left_float', selector: 'image_left_float', styles: {
                                    'float': 'left',
                                    'margin': '0 10px 0 10px'
                                }
                            }
                        ],
                        content_style: `
                    body {
                        background-color: #232428;
                    }
                `,
                    }}
                    value={values?.[activeLang]?.length? values[activeLang] : ''}
                    onEditorChange={(value)=>changeValue({[activeLang]: value})}
                />
            </div>
        </div>
    )
}