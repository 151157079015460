import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const statisticsApi = createApi({
    reducerPath: 'statisticsApi',
    tagTypes: ['Statistics'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),

    endpoints: (builder) => ({
        getDashboardStatistics: builder.query({
            query: (params) => ({
                url: `/api/statistics/get`,
                method: "GET",
                params
            }),
            providesTags: ["Statistics"]
        }),

        getAttendance: builder.query({
            query: (params) => ({
                url: `/api/statistics/attendance/get`,
                method: "GET",
                params
            })
        }),

        getPayments: builder.query({
            query: (params) => ({
                url: `/api/statistics/payments/get`,
                method: "GET",
                params
            })
        }),

        setAttendance: builder.mutation({
            query: (params) => ({
                url: `/api/statistics/attendance`,
                method: "POST",
                params
            })
        })

    }),
  })

export const {
    useGetDashboardStatisticsQuery,
    useGetAttendanceQuery,
    useGetPaymentsQuery,
    useSetAttendanceMutation
} = statisticsApi;