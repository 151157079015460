import parse from 'html-react-parser';

export function clearHTML(str: string) {
    try {
        let string = str?.replace(/<[^>]*>/g, '')
        string = string?.replaceAll("\\n", '\n')
        return parse(`${string}`) as string
    } catch (error) {
        return "undefined"
    }
} 