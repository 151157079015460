import { Table } from 'components/Table/Table';
import { useGetUsersStatisticsQuery } from '../../../../redux/apis/usersApi';
import s from './Users.module.scss';
import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Google } from "../../../../assets/icons/google_icon.svg"
import { ReactComponent as Gmail } from "../../../../assets/icons/email_icon.svg"
import { ReactComponent as Sort } from "../../../../assets/icons/sorting_icon.svg"
import { ReactComponent as Clear } from "../../../../assets/icons/clear-circle-svgrepo-com.svg"
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { DateFormat, SortingTypes } from 'utils/enums/common.enum';
import { format } from 'date-fns';
import { Checkbox, MenuItem, Pagination, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';

type QueryType = {
    sort: SortingTypes
    order: string
    search: string | undefined
    page: number
    limit: number
    ids: number[] | undefined
}

export const Users = ({isModal, isCheckAll, isButton, changedUsers, setChangedUsers, changeAll, setChangeAll, ids}: any) => {
    
    // ### State
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state
    
    const [search, setSearch] = useState<string | undefined>(state?.search);
    const [query, setQuery] = useState<QueryType>({
        sort: state?.sort || SortingTypes.Date,
        search: state?.search,
        order: state?.order || "desc",
        page: state?.page || 1,
        limit: state?.limit || 20,
        ids
    })
    
    const { t } = useTranslation()

    const {data: usersData, isFetching: isLoadingUsers} = useGetUsersStatisticsQuery(query);
    
    // ### Effects

    useEffect(()=>{
        setQuery(prev => ({...prev, ids}))
    },[ids]);

    useEffect(() => {
        const handler = setTimeout(() => {
          setQuery(prev => ({...prev, search}))
          clearNavigationState()
        }, 700);
    
        return () => clearTimeout(handler);
    }, [search]);

    // ### Functions
    function clearNavigationState(){
        navigate(location.pathname, { replace: true, state: null });
    }

    // ### Views
    const columns: TableColumnType[] = [
        {
            title: isModal ? "" : "ID"
        },
        {
            title: "Email"
        },
        {
            title: t("dashboard.users.name")
        },
        {
            title: t("dashboard.users.courses")
        },
        {
            title: t("dashboard.users.guides")
        },
        {
            title: t("dashboard.users.consultations")
        },
        {
            title: t("dashboard.users.date_registration")
        },
        {
            title: ""
        }
    ];

    const rows: TableRowType[] = usersData?.users?.map((user: any) => {
        const courses = (<><ColorNumber>{user.courses.active}</ColorNumber> / <ColorNumber>{user.courses.count}</ColorNumber></>)
        const guides = (<><ColorNumber>{user.guides.active}</ColorNumber> / <ColorNumber>{user.guides.count}</ColorNumber></>)
        const consultations = (<><ColorNumber>{user.calendar.active}</ColorNumber> / <ColorNumber>{user.calendar.count}</ColorNumber></>)
        const isChecked = changedUsers?.includes(user.id)
        return(
            {
                name: user.id,
                link: `/dashboard/user/${user.id}`,
                values: [
                    {
                        title: isModal ? 
                        (<Checkbox 
                            checked={(isChecked || changeAll) ? true : false}
                            onClick={e => e.stopPropagation()}
                            onChange={(e)=>{
                                e.stopPropagation()
                                setChangedUsers({isChecked, userId: user.id})
                            }}
                        />) 
                        : user.id
                    },
                    {
                        title: user.email
                    },
                    {
                        title: user.name || "- - -"
                    },
                    {
                        title: courses
                    },
                    {
                        title: guides
                    },
                    {
                        title: consultations
                    },
                    {
                        title: format(new Date(Number(user.date)), DateFormat.LONG)
                    },
                    {
                        title: user.registration_type === "google" ? <Google/> : <Gmail/>
                    }
                ]
            }
        )
    })

    const table = useMemo(()=>{
        return <Table 
            rows={rows} 
            columns={columns} 
            isLoading={isLoadingUsers}
            isModal={isModal}
            routeState={{
                page: query.page, 
                limit: query.limit, 
                sort: query.sort, 
                order: query.order, 
                search: query.search, 
                users: usersData?.users?.map((el: any) => el.id),
                link: window.location.pathname
            }}
        />
    }, [usersData, isLoadingUsers, query.page, changeAll, changedUsers])

    return(
        <div className={s.wrapper}>
            <div className={s.header}>
                <div className={s.item}>
                    <span className={s.title}>{t('general.search')}</span>
                    <input type="text" value={search} onChange={(ev)=> setSearch(ev.target.value)}/>
                </div>
                <div className={s.item}>
                    <span className={s.title}>{t('general.sorting')}</span>
                    <Select
                        value={query.sort}
                        onChange={(ev)=>{ 
                            setQuery(prev => ({...prev, sort: ev.target.value as SortingTypes}))
                            clearNavigationState()
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {
                            Object.entries(SortingTypes)?.map((el: string[], index: number) => (
                                <MenuItem key={index} value={el[1]}>{t(`dashboard.users.sort.${el[1]}`)}</MenuItem>
                            ))
                        }
                    </Select>
                </div>
                <div 
                    className={`${s.sort_wrapper} ${query.order === "asc" ? s.active : ""}`} 
                    onClick={() => {
                        setQuery(prev => {
                            return {...prev, order: prev.order === "desc" ? "asc" : 'desc'}
                        })
                        clearNavigationState()
                    }}
                >
                    <Sort/>
                </div>
                <div className={`${s.clear_wrapper}`} onClick={()=>{
                        setSearch("")
                        setQuery({
                            sort: SortingTypes.Date,
                            search: undefined,
                            order: "desc",
                            page: 1,
                            limit: 20,
                            ids
                        })
                        clearNavigationState()
                    }}
                >
                    <Clear/>
                </div>
                <div className={s.pagination_wrapper}>
                    {
                        isCheckAll ? 
                        <div className={s.select_all}>
                            <Checkbox checked={changeAll} onChange={(ev)=>setChangeAll(ev.target.checked)}/> {t('mailings.change_all')}({usersData?.quantity})
                        </div> : null
                    }
                    {
                        isButton ?
                        <PrimaryButton 
                            text={t('general.save')}
                            type={"button"}
                            style={{width: "fit-content"}}
                            onClick={isButton.action}
                            disabled={isButton.isLoading}
                        /> : null
                    }
                    { usersData?.totalPages > 1 ?
                        <Pagination 
                            count={usersData?.totalPages} 
                            variant="outlined" 
                            shape="rounded"
                            page={query.page}
                            onChange={(_, page) => {
                                setQuery(prev => ({...prev, page}))
                                clearNavigationState()
                            }}
                        /> : null
                    }
                </div>
            </div>
            <div className={s.content}>
                {table}
                { usersData?.totalPages > 1 ?
                    <div className={s.pagination_wrapper}>
                        <Pagination 
                            count={usersData?.totalPages} 
                            variant="outlined" 
                            shape="rounded"
                            page={query.page}
                            onChange={((_, page) => setQuery(prev => ({...prev, page})))}
                        />
                    </div> : null
                }
            </div>
        </div>
    )
}