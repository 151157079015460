import { FileManager } from "components/FileManager/FileManager";
import s from "./ModalStorage.module.scss";

export type ModalStorageType = {
    setIsOpenStorage: (value: boolean) => void;
    setFile: (value: string) => void;
    type: string;
}

export const ModalStorage = ({ setIsOpenStorage, setFile, type }: ModalStorageType) => {
    return (

        <div className={s.back}>
            <div className={s.storage_wrapper}>
                <FileManager
                    isModal={true}
                    setIsOpenStorage={setIsOpenStorage}
                    setFile={setFile}
                    type={type}
                />
            </div>
        </div>
    );
}
