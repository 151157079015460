import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

type Props = {
    children: any;
}

export const CheckToken = ({ children }: Props) => {

    const currentUrl = window.location.href;
    const navigate = useNavigate();

    useEffect(()=>{
        const current_token = localStorage.getItem('token');
        if(!current_token && !currentUrl.includes('registration')) {
            navigate("/auth");
        }
    },[]);

    return children;
};
