import {Back} from "components/Back/Back";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import s from "./EditProduct.module.scss";
import {DropDown} from "components/DropDown/DropDown";
import {Form, Formik} from "formik";
import {Input} from "components/Input/Input";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon2.svg';
import { ReactComponent as SizeIcon } from 'assets/icons/size.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import {Preloader} from "components/preloader/Preloader";
import {ImagesUploader} from "../ImageUploader/ImagesUploader";
import {useGetLangsQuery} from "../../../../redux/apis/langApi";
import { SketchPicker } from 'react-color';
import {useCreateProductMutation, useUpdateProductMutation} from "../../../../redux/apis/shopApi";
import {useStatusContext} from "components/StatusProvider";
import {isValidJSONFormat} from "utils/validation";

type EditProductProps = {
    setEditPage: Dispatch<SetStateAction<any>>;
    categoryId: number | null;
    product: any;
}

type ValueItem = {
    id: number;
    title: string;
    color?: string;
};

type ColorItem = {
    id: number;
    color: string;
    title: string;
};

export const EditProduct = ({ setEditPage, categoryId, product }: EditProductProps) => {
    const { data: langs, isFetching } = useGetLangsQuery({});
    const { t } = useTranslation();

    const parseOptions = product && (isValidJSONFormat(product?.options) ? JSON.parse(product?.options) : null);
    const [state, setState] = useState({images: product ? product?.images : []});
    const [selectedColor, setSelectedColor] = useState<string[]>([]);
    const [showPicker, setShowPicker] = useState<any>({show: false, id: 0});
    const [valuesArray, setValuesArray] = useState<any>({
        color: product ? parseOptions?.color ?? [] : [],
        size: product ? parseOptions?.size ?? [] : []
    });
    const [createProduct] = useCreateProductMutation();
    const [updateProduct] = useUpdateProductMutation();
    const { openStatus } = useStatusContext();

    useEffect(() => {
        if (product) {
            if (parseOptions?.color?.length !== 0 && parseOptions?.color) {
                console.log("color")
                setSelectedColor(prevState => [...prevState, 'color']);
            }
            if (parseOptions?.size?.length !== 0 && parseOptions?.size) {
                setSelectedColor(prevState => [...prevState, 'size']);
            }
        }
    }, []);

    if (isFetching || !langs) return <Preloader />

    const handleColorChange = (color: { hex: string }, id: number) => {
        setValuesArray((prev: { color: ColorItem[] }) => ({
            ...prev,
            color: prev.color.map((item) =>
                item.id === id ? { ...item, color: color.hex } : item
            ),
        }));
    };

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setSelectedColor((prev: any) => {
            if (prev.includes(value)) {
                return prev.filter((item: string) => item !== value);
            } else {
                return [...prev, value];
            }
        });

        if (!selectedColor.includes('color')) {
            setValuesArray((prevState: any) => {
                return {
                    ...prevState,
                    color: []
                }
            })
        }

        if (!selectedColor.includes('size')) {
            setValuesArray((prevState: any) => {
                return {
                    ...prevState,
                    size: []
                }
            })
        }
    };

    const addNewValues = (parameter: string) => {
        setValuesArray((prevState: any) => {
            if (parameter === "color") {
                return {
                    ...prevState,
                    color: [
                        ...prevState.color,
                        { title: "", color: "#fff", id: prevState.color.length + 1 }
                    ]
                };
            } else {
                return {
                    ...prevState,
                    size: [
                        ...prevState.size,
                        { title: "", id: prevState.size.length + 1 }
                    ]
                };
            }
        });
    };

    const handleChangeValue = (parameter: string, e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const newValue = e.target.value;

        setValuesArray((prev: any) => {
            if (parameter === "color") {
                return {
                    ...prev,
                    color: prev.color.map((item: any) =>
                        item.id === id ? { ...item, title: newValue } : item
                    ),
                };
            }

            return {
                ...prev,
                size: prev.size.map((item: any) =>
                    item.id === id ? { ...item, title: newValue } : item
                ),
            };
        });
    };

    const handleDeleteValues = (parameter: "color" | "size", id: number) => {
        setValuesArray((prev: { color: ValueItem[]; size: ValueItem[] }) => {
            if (parameter === "color") {
                return {
                    ...prev,
                    color: prev.color.filter((item) => item.id !== id),
                };
            } else {
                return {
                    ...prev,
                    size: prev.size.filter((item) => item.id !== id),
                };
            }
        });
    };

    const initialValues: any = {
        price: product ? product?.price : "",
        images: state
    };

    langs?.data?.forEach((lang: any) => {
        initialValues[`name-${lang.key}`] = product ? product?.title[lang.key] : ''
        initialValues[`description-${lang.key}`] = product ? product?.description[lang.key] : ''
    })

    const validation_data: any = {
        price: yup.string().required(t("validation.required")),
    }
    const validationSchema = yup.object().shape(validation_data);

    const handleSubmit = async (values: any, { resetForm }: any) => {
        console.log(values);

        let titleValues: any[] = [];
        let descriptionValues: any[] = [];
        const images = state.images?.map((image: any) => image.file ? image.file: image);
        const options = JSON.stringify({
            size: valuesArray?.size,
            color: valuesArray?.color
        })

        langs?.data?.forEach((lang: any) => {
            Object.keys(values).forEach((key) => {
                if (key.includes(lang.key) && key.includes("name")) {
                    titleValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                } else if (key.includes(lang.key) && key.includes("description")) {
                    descriptionValues.push({
                        value: values[key],
                        lang_key: lang.key
                    });
                }
            });
        })

        const result: any = await (product ? updateProduct({
            category: categoryId,
            title: titleValues,
            description: descriptionValues,
            options: options,
            price: values.price,
            images: images,
            id: product?.id
        }) : createProduct({
            category: categoryId,
            title: titleValues,
            description: descriptionValues,
            options: options,
            price: values.price,
            images: images
        }));

        if (result?.data?.message === "Product created successful" || result?.data?.message === "Product updated successful" ) {
            openStatus('success', "status.operationSuccessful");
            setEditPage({ open: false });
            resetForm();
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.wrap_edit}>
            <Back close={setEditPage} text={t("shop.backToProducts")} />

            <div className={s.content}>
                <h1 className={s.content_title}>{!product ? t("general.add") : t("general.edit") }</h1>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.form}>
                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"name"}
                                label={"Name"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <DropDown
                                langs={langs?.data}
                                errors={errors}
                                touched={touched}
                                kind={"description"}
                                label={"Description"}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <Input
                                value={values.price}
                                label={t("shop.price")}
                                errors={errors.price}
                                touched={touched.price}
                                name={"price"}
                                onClick={() => setFieldValue('price', '')}
                            />

                            <ImagesUploader state={state} setState={setState}/>

                            <h3 className={s.additional_title}>{t("shop.additionalInformation")}</h3>

                            <label className={s.label}>
                                <input
                                    type="checkbox"
                                    name="color"
                                    value="color"
                                    checked={selectedColor.includes('color')}
                                    onChange={handleChangeCheckbox}
                                />
                                {t("shop.color")}
                            </label>

                            {selectedColor.includes('color') && (
                                <>
                                    <div className={s.additional_button_add} onClick={() => addNewValues("color")}>
                                        {t("shop.addValue")}
                                    </div>

                                    {valuesArray?.color?.map((value: any) => (
                                        <div className={s.field} key={value.id}>
                                            <div
                                                className={s.field_color}
                                                onClick={() => setShowPicker({show: !showPicker.show, id: value.id})}
                                                style={{backgroundColor: value?.color}}
                                            ></div>

                                            {(showPicker.show && value.id === showPicker.id) && (
                                                <div className={s.field_picker}>
                                                    <CloseIcon className={s.field_picker_close}
                                                               onClick={() => setShowPicker({show: false})}/>
                                                    <SketchPicker color={value?.color}
                                                                  onChange={(color) => handleColorChange(color, value.id)}/>
                                                </div>
                                            )}

                                            <input
                                                value={value?.title}
                                                className={s.field_input}
                                                onChange={(e) => handleChangeValue("color", e, value.id)}
                                            />
                                            <DeleteIcon className={s.field_delete}
                                                        onClick={() => handleDeleteValues("color", value.id)}/>
                                        </div>
                                    ))}
                                </>
                            )}

                            <label className={s.label}>
                                <input
                                    type="checkbox"
                                    name="size"
                                    value="size"
                                    checked={selectedColor.includes('size')}
                                    onChange={handleChangeCheckbox}
                                />
                                {t("shop.size")}
                            </label>

                            {selectedColor.includes('size') && (
                                <>
                                    <div className={s.additional_button_add} onClick={() => addNewValues("size")}>
                                        {t("shop.addValue")}
                                    </div>

                                    {valuesArray?.size?.map((value: any) => (
                                        <div className={s.field} key={value.id}>
                                            <SizeIcon className={s.field_size}/>
                                            <input
                                                value={value?.title}
                                                className={s.field_input}
                                                onChange={(e) => handleChangeValue("size", e, value.id)}
                                            />
                                            <DeleteIcon className={s.field_delete}
                                                        onClick={() => handleDeleteValues("size", value.id)}/>
                                        </div>
                                    ))}
                                </>
                            )}


                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => setEditPage({show: false})}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}