import React, { useEffect, useState } from 'react';
import s from './DropDown.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import imageCompression from "browser-image-compression";

import { Field, FormikErrors, FormikValues } from "formik";
import 'react-quill/dist/quill.snow.css';
import translateIcon from "assets/icons/translate.webp";
import {translateText} from "utils/translateText";
import {useStatusContext} from "../StatusProvider";
import TinyEditor from 'components/TinyEditor/TinyEditor';
import {ModalStorage} from "../ModalStorage/ModalStorage";

type DropDownProps = {
    langs: { key: string }[];
    errors: any;
    touched: any;
    kind: string;
    label: string;
    module?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
    values?: FormikValues;
    defaultImage?: any;
    setDefaultImage?: (defaultImage: any) => void;
    isManual?: number[];
    setIsManual?: (isManual: number[]) => void;
    id?: number
};

export const DropDown = ({
                             langs,
                             errors,
                             touched,
                             kind,
                             label,
                             module,
                             setFieldValue,
                             values,
                             setDefaultImage,
                             defaultImage,
                             isManual,
                             setIsManual,
                             id
}: DropDownProps) => {
    const [hideStates, setHideStates] = useState<Record<string, boolean>>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs[0]?.key || '');
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const [translateValues, setTranslateValues] = useState<any>();
    const { openStatus } = useStatusContext();
    const [isLoadingTranslate, setIsLoadingTranslate] = useState<boolean>(false);
    const MAX_TRANSLATE_LENGTH = 5000;
    const [isOpenStorage, setIsOpenStorage] = useState<boolean>(false);
    const [keyStorage, setKeyStorage] = useState<string>();

    useEffect(() => {
        setHideStates(langs?.reduce((acc, lang) => ({ ...acc, [lang.key]: true }), {}));
    }, [langs]);

    if (isOpenStorage) {
        return <ModalStorage
            setIsOpenStorage={setIsOpenStorage}
            type={label === "image" ? "image" : "video"}
            setFile={(file) => {
                if (setFieldValue) {
                    setFieldValue(`${kind}-${keyStorage}`, new URL(file).pathname);
                    if (setDefaultImage) {
                        setDefaultImage((prev: any[]) => {
                            if (prev.some(item => item.id === id)) {
                                return prev;
                            }
                            return [...prev, { id: id, key: langs[0].key }];
                        });
                    }
                }
            }}
        />
    }

    function getCurrentValue(){
        let value
        for(let i=0; i<langs?.length; i++){
            if(translateValues[langs?.[i].key]?.length){
                value = translateValues[langs?.[i].key]
                break
            }
        }
        return value
    }

    const handleTranslate = async () => {
        try {
            setIsLoadingTranslate(true);
            const text = getCurrentValue();

            if (!text) {
                setIsLoadingTranslate(false);
                openStatus('error', "status.notPicture");
                return;
            }

            const chunks = [];
            let chunkStart = 0;

            while (chunkStart < text.length) {
                let chunkEnd = chunkStart + MAX_TRANSLATE_LENGTH;
                if (chunkEnd < text.length) {
                    const lastSpaceIndex = text.lastIndexOf(' ', chunkEnd);
                    if (lastSpaceIndex > chunkStart) {
                        chunkEnd = lastSpaceIndex;
                    }
                }
                chunks.push(text.slice(chunkStart, chunkEnd));
                chunkStart = chunkEnd + 1;
            }

            const languages = langs?.map((l: any) => l.key) || [];
            const results = await Promise.all(chunks.map(chunk => translateText(chunk, languages)));

            const mergedResult = results.reduce((acc, part) => {
                for (const key in part) {
                    acc[key] = (acc[key] || '') + part[key];
                }
                return acc;
            }, {} as Record<string, string>);

            setTranslateValues(mergedResult);
            setIsLoadingTranslate(false);
        } catch (error) {
            setIsLoadingTranslate(false);
            openStatus('error', "status.translationError");
            console.error("Error during translation:", error);
        }
    };

    const toggleHide = (key: string) => {
        setHideStates(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleLanguageChange = (langKey: string) => {
        setSelectedLang(langKey);
    };

    const renderMediaContent = (fieldName: string, value: any, type: string) => {

        return (
            <div className={s.image_wrapper} onClick={() => {
                setIsOpenStorage(true)
                setKeyStorage(fieldName.split('-')[1]);
            }}>
                {!value && <div className={s.choose_file}>Choose file</div>}
                {value && ((type === "image") ?
                    <img
                        src={API_ADDRESS + value}
                        alt="Upload"
                        className={s.image}
                    /> :
                    <video
                        playsInline
                        src={API_ADDRESS + value}
                        className={s.image}
                        controls
                    />)
                }
            </div>
        );
    };

    const renderContent = (v: { key: string }) => {
        const fieldName = `${kind}-${v.key}`;
        const value = values?.[fieldName];

        if (label === "image" || label === "video") {
            return renderMediaContent(fieldName, value, label);
        }

        return <Field
            name={fieldName}
            component={TinyEditor}
            translateValues={translateValues}
            setTranslateValues={setTranslateValues}
        />;
    };

    const renderErrors = (key: string) => {
        const errorKey = `${kind}-${key}`;
        return touched?.[errorKey] && errors?.[errorKey] && (
            <span className={s.error_text}>{errors[errorKey]}</span>
        );
    };

    return (
        <div className={s.dropdown}>
            <div className={s.languageButtons}>
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) && langs?.map(v => (
                    <div
                        key={v.key}
                        onClick={() => handleLanguageChange(v.key)}
                        className={`${s.languageButton} ${selectedLang === v.key ? s.selected : ''}`}
                    >
                        {v.key}
                        {(values?.[`${kind}-${v.key}`] && values?.[`${kind}-${v.key}`] !== "<p><br></p>") &&
                            <CheckIcon className={s.check_icon}/>}
                    </div>
                ))}
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) &&
                    (isLoadingTranslate ? <LoadingIcon className={s.loadingIcon} /> :
                        <img
                            src={translateIcon}
                            alt={"translateIcon"}
                            className={s.translateIcon}
                            onClick={handleTranslate}
                        />
                    )
                }
            </div>

            {typeof kind === "string" && (kind.includes("image") || kind.includes("video")) ? (
                <>
                    <div className={s.switch}>
                        Add pictures for different languages
                        <input
                            type="checkbox"
                            checked={isManual?.includes(id as number)}
                            onClick={() => {
                                if (id === undefined) return;
                                if (setIsManual && isManual) {
                                    if (isManual.includes(id)) {
                                        setIsManual(isManual.filter((el: number) => el !== id));
                                    } else {
                                        setIsManual([...isManual, id]);
                                    }
                                }
                            }}
                        />

                    </div>
                    <div className={s.content_row}>
                        {isManual?.includes(id as number) ?
                            langs?.map((v: any, index: number) => (
                                <div key={v?.key ?? index} className={s.flex}>
                                    <div className={s.content_head_second}>
                                        <p>{label} <span>{v?.key}</span></p> |
                                        <input
                                            type="checkbox"
                                            checked={defaultImage?.some((el: any) => el.key === v.key && el.id === id)}
                                            onClick={() => {
                                                if (!setDefaultImage || !values?.[`${kind}-${v.key}`]) return;

                                                const isDefault = defaultImage?.some((el: any) => el.key === v.key && el.id === id);

                                                if (isDefault) {
                                                    setDefaultImage(defaultImage.filter((el: any) => !(el.key === v.key && el.id === id)));
                                                } else {
                                                    const newDefault = [
                                                        ...defaultImage.filter((el: any) => el.id !== id),
                                                        {id, key: v.key}
                                                    ];
                                                    setDefaultImage(newDefault);
                                                }
                                            }}
                                        />

                                    </div>

                                    {renderContent(v)}
                                    {renderErrors(v.key ?? index)}
                                </div>
                            )) :

                            renderMediaContent(
                                `${kind}-${defaultImage?.find((el: any) => el.id === id)?.key ?? langs[0].key}`,
                                values?.[`${kind}-${defaultImage?.find((el: any) => el.id === id)?.key ?? langs[0].key}`],
                                label
                            )

                        }
                    </div>
                </>
            ) : (
                langs?.map(v => (
                    <div
                        key={v.key}
                        className={`${s.content} ${!hideStates[v.key] ? s.hide : ''} ${v.key !== selectedLang ? s.none : ''}`}
                    >
                        {!module && (
                            <div className={s.content_head} onClick={() => toggleHide(v.key)}>
                                <p>{label} <span>{v.key}</span></p>
                                <ArrowTools className={s.arrow}/>
                            </div>
                        )}
                        {renderContent(v)}
                        {renderErrors(v.key)}
                    </div>
                ))
            )}
        </div>
    );
};
