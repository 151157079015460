export function goToModule(id: any){
    const anchor: any = document.getElementsByClassName(`anchor-${id}`)

    console.log(anchor);

    window.scrollTo({
        top: anchor[0]?.offsetTop,
        left: 100,
        behavior: "smooth",
    });
}