import { FC, useEffect, useRef } from 'react';
import s from './InputImage.module.scss';

type InputImageType = {
    title: string
    setImage: (val: string) => void
    image?: string
}

export const InputImage: FC<InputImageType> = ({title, setImage, image}) => {

    // ### State
    const img_wrapper = useRef<HTMLDivElement>(null)

    // ### Effects
    useEffect(()=>{
        if(img_wrapper.current && image){
            img_wrapper.current.style.cssText= `background: url(${image}) no-repeat center; background-size: contain;`;
        }
    },[image]);

    // ### Functions
    async function changeImage(file: any){
        const reader = new FileReader();
        reader.onload = async function(e) {
            const currenFile = {
                file: `data:${file.type};base64,${await blobToBase64(file)}`,
                mimetype: file.type
            }
            const blob = base64ToBlob(currenFile.file, currenFile.mimetype)
            if(img_wrapper.current && blob){
                img_wrapper.current.style.cssText= `background: url(${URL.createObjectURL(blob)}) no-repeat center; background-size: contain;`;
                setImage(currenFile.file)
            }
            
        };
        reader.readAsDataURL(file);
    }

    function blobToBase64(blob: any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if(reader.result && typeof reader.result === 'string'){
                resolve(reader.result.split(',')[1]);
            }
        };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
    }

    function base64ToBlob(base64: string, contentType = '', sliceSize = 512){
        try {
            if(!base64) return false
            const byteCharacters = atob(base64.split(',')[1]);
            const byteArrays = [];
      
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
      
            return new Blob(byteArrays, { type: contentType });
        } catch (error) {
            console.log("base64ToBlob", error)
            return new Blob()
        }
      }

    // ### Views

    return(
        <div className={s.wrapper}>
            <span className={s.title}>{title}</span>
            <div className={s.image_wrapper} ref={img_wrapper}>
                <input type='file' accept="image/*" onChange={(ev) => changeImage(ev.target?.files?.[0])}/>
            </div>
        </div>
    )
}