import s from './FileItem.module.scss';
import { ReactComponent as Delete } from "../../.././../assets/icons/delete_icon3.svg"
import { ReactComponent as Copy } from "../../.././../assets/icons/copy.svg"
import { ReactComponent as Success } from "../../.././../assets/icons/success_copy.svg"
import { useState } from 'react';
import { formatSize } from 'utils/formatSize';
const URL = process.env.REACT_APP_API_ADDRESS

export const FileItem = ({folder, setDeleteFileModal}: any) => {

    // ### State
    const [isCopy, setIsCopy] = useState<boolean>(false)

    // ### Effects

    // ### Functions
    function handleCopy(){
        setIsCopy(true)
        setTimeout(()=>{
            setIsCopy(false)
        },2000)
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <span className={s.size}>{formatSize(folder.size)}</span>
            <span className={s.type}>{folder.mimetype}</span>
            <span className={s.name}>{folder.name}</span>
            <div className={s.delete_button} onClick={(e)=>{
                e.stopPropagation();
                setDeleteFileModal(`${folder.path}/${folder.name}`)
            }
            }><Delete /></div>
            <div className={s.copy_button} onClick={(e)=>{
                e.stopPropagation();
                navigator.clipboard.writeText(`${URL}${folder.path}/${folder.name}`.replace("/public", ""))
                .then(() => handleCopy())
                .catch(err => alert("Ошибка копирования:"));
            }
            }>{isCopy ? <Success/> : <Copy />}</div>
        </div>
    )
}