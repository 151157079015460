import { format } from 'date-fns';
import { useDeleteGroupMutation, useGetGroupsQuery } from '../../../../redux/apis/mailingApi';
import {ReactComponent as MenuIcon} from "../../../../assets/icons/more_vertical.svg"
import s from './MailingGroups.module.scss';
import { DateFormat } from 'utils/enums/common.enum';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { CreateGroup } from './components/CreateGroup/CreateGroup';
import { ModalGeneral } from 'components/Modals/ModalGeneral';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'components/Skeleton/Skeleton';

export const MailingGroups = () => {

    // ### State
    const [createModal, setCreateModal] = useState<any>(false)
    const [deleteModal, setDeleteModal] = useState<any>(false)
    
    const navigate = useNavigate()
    const {t} = useTranslation()
    const menuItemsRef = useRef<any[]>([])

    const {data: groups, isLoading: isGetLoading} = useGetGroupsQuery({})
    const [Delete, {isLoading}] = useDeleteGroupMutation()

    // ### Effects

    // ### Functions
    function toggleMenu(id: number){
        menuItemsRef.current[id].classList.toggle(s.active)
    }

    async function handleDelete(id: number){
        await Delete({id})
    }

    // ### Views
    const GoroupsView = groups?.map((group: any, index: number) => {
        return(
            <div className={s.group} key={index}>
                <div className={s.header}>
                    <span className={s.created}>Created: {format(new Date(group.date), DateFormat.LONG)}</span>
                    <div className={s.menu_wrapper} onClick={() => toggleMenu(group.id)}>
                        <MenuIcon/>

                        <div className={s.menu} ref={(el) => {menuItemsRef.current[group.id] = el}}>
                            <div className={s.back} ></div>
                            <span className={s.menu_item} onClick={()=>{navigate(`/mailings/groups/group/${group.id}`)}}>{t('general.edit')}</span>
                            <span className={s.menu_item} onClick={()=>{setCreateModal({state: {id: group.id, name: group.name}})}}>{t('general.rename')}</span>
                            <span className={s.menu_item} onClick={()=>{setDeleteModal(group.id)}}>{t('general.delete')}</span>
                        </div>
                    </div>
                </div>
                <div className={s.content}>
                    <div className={s.left}>
                        <p className={s.name} onClick={()=>navigate(`/mailings/groups/group/${group.id}`)}>{group.name}</p>
                    </div>
                    <div className={s.right}>
                        <span className={s.value}>{group.changeAll ? group.usersLength : group.users + group.contacts}</span>
                        <span className={s.title}>Emails</span>
                    </div>
                </div>
            </div>
        )
    })

    return(
        <div className={s.wrapper}>
            <div className={s.button_wrapper}>
                <PrimaryButton 
                    text={t('general.add')}
                    type={"button"}
                    onClick={() => setCreateModal(true)}
                    style={{width: "fit-content"}}
                />
            </div>
            {GoroupsView}
            
            { createModal ? <CreateGroup close={() => setCreateModal(false)} state={createModal.state}/> : null }

            <ModalGeneral
                showModal={deleteModal}
                setShowModal={setDeleteModal}
                title={`${t('general.delete')}?`}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={()=> {
                    handleDelete(Number(deleteModal)) 
                } }
            />
            <Skeleton isLoading={(isLoading || isGetLoading)} />
        </div>
    )
}