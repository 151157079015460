import React, {useState} from 'react';
import s from "./ModalGeneral.module.scss";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import {Input} from "../Input/Input";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {Select} from "../Select/Select";
import {AddModule} from "./components/AddModule";
import {AddProduct} from "./components/AddProduct";
type Modal = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    image?: any;
    buttonRight: any;
    title?: string;
    textLeftButton: string;
    textRightButton: string;
    description?: any;
    field?: boolean;
    fieldModule?: boolean;
    editElement?: any;
    type?: string;
    fieldBarModule?: boolean;
    deletedId?: number;
    currentMarkers?: string[];
    addModule?: boolean;
    addProduct?: boolean;
    addFolder?: boolean;
}

export const ModalGeneral = ({
     showModal,
     setShowModal,
     image, buttonRight,
     title,
     textLeftButton,
     textRightButton,
     description,
     field,
     fieldModule,
     editElement,
     type,
     fieldBarModule,
     deletedId,
     currentMarkers,
     addModule,
     addProduct,
     addFolder
}: Modal) => {
    const { t } = useTranslation();
    const validationSchema = yup.object().shape({
        key: yup.string()
            .required(t("validation.required"))
            .min(2, ("validation.atLeast2Characters"))
    });
    const selectValues = [
        { id: 1, name: "text" },
        { id: 2, name: "link" },
        { id: 3, name: "image" },
        { id: 4, name: "video" },
        { id: 5, name: "array" },
        { id: 6, name: "object" },
        { id: 7, name: "number" },
        { id: 8, name: "date" },
        { id: 9, name: "slug" },
    ];
    const [module, setModule] = useState<any>();
    const [product, setProduct] = useState<any>();

    const filteredSelectValues =
        (type && type === "array")
            ? selectValues.filter(item => item.name !== "array")
            : selectValues;

    const validationSchemaModule = yup.object().shape({
        marker: yup
            .string()
            .required(t("validation.required"))
            .test(
                "no-spaces",
                t("validation.noSpacesAllowed"),
                (value) => !/\s/.test(value)
            )
            .matches(
                /^[a-z0-9_]+$/,
                t("validation.invalidCharacters")
            )
            .notOneOf(
                currentMarkers ?? [],
                t("validation.theMarkersCannotSame")
            ),

        name: yup.string().required(t("validation.required")),
        type: yup.string().required(t("validation.required"))
    });

    const validationSchemaModuleBar = yup.object().shape({
        marker: yup
            .string()
            .required(t("validation.required"))
            .test(
                "no-spaces",
                t("validation.noSpacesAllowed"),
                (value) => !/\s/.test(value)
            )
            .matches(
                /^[a-z0-9_]+$/,
                t("validation.invalidCharacters")
            )
        ,
        name: yup.string().required(t("validation.required"))
    });

    if (!showModal) {
        return null;
    }

    const handleClose = () => {
        buttonRight(deletedId || module || product || null);
        setShowModal(false);
    }

    const handleFormSubmit = (values: { marker: string, name: string, type: string }) => {
        if (values.type === "object" && !editElement) {
            buttonRight({
                marker: values.marker,
                name: values.name,
                type: values.type,
                values: {},
            });
        } else if (values.type === "array" && !editElement) {
            buttonRight({
                marker: values.marker,
                name: values.name,
                type: values.type,
                values: []
            });
        }
        else {
            buttonRight({
                marker: values.marker,
                name: values.name,
                type: values.type
            });
        }

    };

    return (
        <div className={`${s.modal_wrapper}`}>
            <div className={`${s.modal} ${fieldModule && s.modal_module}`}>
                <h1>{title}</h1>
                {image && <div className={s.image_wrapper}>
                    <img src={image} alt={"icon"} />
                </div>}

                {description && <p className={s.modal_description}>{description}</p>}

                {addModule && <AddModule setModule={setModule} /> }

                {addProduct && <AddProduct setProduct={setProduct} />}

                {addFolder &&
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ key: '' }}
                        onSubmit={(values) => buttonRight(values.key)}
                    >
                        {({ setFieldValue, errors, touched, values }) => (
                            <Form className={s.form}>
                                <Input
                                    value={values.key}
                                    errors={errors.key}
                                    touched={touched.key}
                                    name={"key"}
                                    label={t('storage.folderName')}
                                    placeholder={t('storage.folderName')}
                                    onClick={() => setFieldValue("key", "")}
                                />
                                <div className={s.buttons_wrapper}>
                                    <SecondaryButton
                                        type="button"
                                        text={textLeftButton}
                                        onClick={() => setShowModal(false)}
                                    />
                                    <PrimaryButton
                                        disabled={!values.key}
                                        type="submit"
                                        text={textRightButton}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {field &&
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ key: '' }}
                        onSubmit={(values) => buttonRight(values.key)}
                    >
                        {({ setFieldValue, errors, touched, values }) => (
                            <Form className={s.form}>
                                <Input
                                    value={values.key}
                                    errors={errors.key}
                                    touched={touched.key}
                                    name={"key"}
                                    label={t("language.key")}
                                    placeholder={t("general.enterTheKey")}
                                    onClick={() => setFieldValue("key", "")}
                                />
                                <div className={s.buttons_wrapper}>
                                    <SecondaryButton
                                        type="button"
                                        text={textLeftButton}
                                        onClick={() => setShowModal(false)}
                                    />
                                    <PrimaryButton
                                        disabled={!values.key}
                                        type="submit"
                                        text={textRightButton}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {fieldBarModule &&
                    <Formik
                        validationSchema={validationSchemaModuleBar}
                        initialValues={{ name: '', marker: '' }}
                        onSubmit={(values) => buttonRight(values.name, values.marker)}
                    >
                        {({ setFieldValue, errors, touched, values }) => (
                            <Form className={s.form}>
                                <Input
                                    value={values.name}
                                    errors={errors.name}
                                    touched={touched.name}
                                    name={"name"}
                                    label={t("pagesModal.name")}
                                    placeholder={t("modules.enterName")}
                                    onClick={() => setFieldValue("name", "")}
                                />
                                <Input
                                    value={values.marker}
                                    errors={errors.marker}
                                    touched={touched.marker}
                                    name={"marker"}
                                    label={t("pages.marker")}
                                    placeholder={t("modules.enterMarker")}
                                    onClick={() => setFieldValue("marker", "")}
                                />
                                <div className={s.buttons_wrapper}>
                                    <SecondaryButton
                                        type="button"
                                        text={textLeftButton}
                                        onClick={() => setShowModal(false)}
                                    />
                                    <PrimaryButton
                                        disabled={!values.name || !values.marker}
                                        type="submit"
                                        text={textRightButton}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {fieldModule &&
                    <Formik
                        validationSchema={validationSchemaModule}
                        initialValues={{ marker: editElement?.marker ?? '', name: editElement?.name ?? "", type: editElement?.type ?? "" }}
                        onSubmit={handleFormSubmit}
                    >
                        {({ setFieldValue, errors, touched, values }) => (
                            <Form className={s.formModule}>
                                {!editElement && <Input
                                    value={values.marker}
                                    errors={errors.marker}
                                    touched={touched.marker}
                                    name={"marker"}
                                    label={t("pages.marker")}
                                    placeholder={t("modules.enterMarker")}
                                    onClick={() => setFieldValue("marker", "")}
                                />}
                                <Input
                                    value={values.name}
                                    errors={errors.name}
                                    touched={touched.name}
                                    name={"name"}
                                    label={t("pagesModal.name")}
                                    placeholder={t("modules.enterName")}
                                    onClick={() => setFieldValue("name", "")}
                                />
                                {!editElement && <Select
                                    values={filteredSelectValues}
                                    label={t("modules.chooseType")}
                                    defaultValue={"Type"}
                                    setFieldValue={setFieldValue}
                                />}

                                <div className={s.buttons_wrapper_module}>
                                    <SecondaryButton
                                        type="button"
                                        text={textLeftButton}
                                        onClick={() => setShowModal(false)}
                                    />
                                    <PrimaryButton
                                        disabled={!values.marker || !values.name}
                                        type="submit"
                                        text={textRightButton}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {(!field && !fieldModule && !fieldBarModule && !addFolder) && <div className={s.buttons_wrapper}>
                    <SecondaryButton
                        type="button"
                        text={textLeftButton}
                        onClick={() => setShowModal(false)}
                    />
                    <PrimaryButton
                        type="button"
                        text={textRightButton}
                        onClick={handleClose}
                    />
                </div>}
            </div>
        </div>
    )
}