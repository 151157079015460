import s from './StatisticsButtons.module.scss';
import {ReactComponent as GreenArrow} from '../../../../../../assets/icons/green-arrow-icon.svg'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from 'utils/transformDate';
import { DateRangeTypes } from 'utils/enums/common.enum';
import { Skeleton } from 'components/Skeleton/Skeleton';

export const StatisticsButtons = ({buttons, activeLines, setActiveLines, data, setDateRange, isLoading}: any) => {

    // ### State
    const [timeRangeState, setTimeRangeState] = useState(0)
    const [rangeType, setRangeType] = useState<DateRangeTypes>(DateRangeTypes.AllTime)

    const {t} = useTranslation()

    // ### Effects

    // ### Functions
    function handleActive(button: any){
        const isActive = activeLines.find((line: any) => line.name === button.name)
        if(isActive){
            setActiveLines((prev: any) => {
                const newState = JSON.parse(JSON.stringify(prev))
                return newState.filter((el: any) => el.name !== button.name)
            })
        } else {
            setActiveLines((prev: any) => {
                const newState = JSON.parse(JSON.stringify(prev))
                newState.push(button)
                return newState
            })
        }
    }

    function changeTimeRange(variant: any){
        setTimeRangeState(variant)
        if(variant === 0){
            setDateRange({startDate: 0, endDate: new Date().getTime()})
            setRangeType(DateRangeTypes.AllTime)
        }
        if(variant === 1){
            setDateRange({startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()})
            setRangeType(DateRangeTypes.Day)
        }
        if(variant === 2){
            setDateRange({startDate: getTimestampWeekAgo(), endDate: new Date().getTime()})
            setRangeType(DateRangeTypes.Week)
        }
        if(variant === 3){
            setDateRange({startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()})
            setRangeType(DateRangeTypes.Month)
        }
    }

    // ### Views
    const buttonsView = buttons?.map((button: any, index: number) => {
        const isActive = activeLines.find((line: any) => line.name === button.name)
        const value = data?.all?.[button.name]
        let count = data?.data?.[button.name]?.length
        let type = `(${t(`general.${rangeType}`)})`
        if(rangeType === DateRangeTypes.AllTime){
            const now = new Date()
            now.setHours(0)
            now.setMinutes(0)
            now.setSeconds(0)
            const yesterday = now.getTime()
            count = data?.data?.[button.name]?.filter((date: number) => date > yesterday)?.length   

            type = `(${t(`general.today`)})`
        }

        return(
            <div 
                key={index} 
                className={`${s.button} ${isActive ? s.active_button : ''}`}
                onClick={()=>handleActive(button)}
            >
                <span className={s.color} style={{background: button.color}}></span>
                <span className={s.title}>{button.title}</span>
                <div className={s.values}>
                    <div className={`${s.arrow_wrapper} ${count > 0 ? s.positive_arrow : ''}`}><GreenArrow/></div>
                    <span className={s.value}>{value}</span>
                    <span className={`${s.procent} ${count > 0 ? s.active_procent : ''}`}>+{count}</span>
                    <span className={s.per}>{type}</span>
                </div>
            </div>
        )
    })

    return(
        <div className={s.wrapper}>
            <div className={s.timerange_wrapper}>
                <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                    onClick={()=>changeTimeRange(0)}>
                        {t(`general.${DateRangeTypes.AllTime}`)}
                </div>
                <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                    onClick={()=>changeTimeRange(1)}>
                        {t(`general.${DateRangeTypes.Day}`)}
                </div>
                <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                    onClick={()=>changeTimeRange(2)}>
                        {t(`general.${DateRangeTypes.Week}`)}
                </div>
                <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                    onClick={()=>changeTimeRange(3)}>
                        {t(`general.${DateRangeTypes.Month}`)}
                </div>
            </div>
            <div className={s.buttons_wrapper}>
                {buttonsView}
            </div>
            <Skeleton isLoading={isLoading} />
        </div>
    )
}