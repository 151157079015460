import s from './GroupModal.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'components/Tabs/Tabs';
import { One } from './components/one/One';
import { Users } from 'pages/Dashboard/components/Users/Users';
import { useUpdateGroupMutation } from '../../../../../../redux/apis/mailingApi';

export const GroupModal = ({id, close, setChangedUsers, changedUsers, changeAll, setChangeAll, setMainTab}: any) => {
   
    // ### State
    const [tab, setTab] = useState<number>(0)
    const {t} = useTranslation()

    const items = [
        {
            id: 0,
            title: t('mailings.registered')
        },
        {
            id: 1,
            title: t('mailings.not_registered')
        },
    ]

    const [Update, {isLoading}] = useUpdateGroupMutation()
    // ### Effects

    // ### Functions
    async function handleUpdate(){
        const response = await Update({id, users: changedUsers.users, changeAll})
        if(response.data.message === "group_update"){
            close()
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.back}></div>
            <div className={s.modal}>
                <div className={s.header}>
                    <Tabs
                        items={items}
                        tab={tab}
                        setTab={setTab}
                    />
                    <div className={s.close_wrapper} onClick={()=>close()}><Close/></div>
                </div>
                <div className={s.content}>
                    {
                        tab === 0 ? <Users 
                                        isModal={true} 
                                        changedUsers={changedUsers.users} 
                                        setChangedUsers={({isChecked, userId}: any) => {
                                            setChangedUsers((prev: any) => {
                                                if(isChecked) return {...prev, users: [...prev.users?.filter((id: any) => id !== userId)]}
                                                else return {...prev, users: [...prev.users, userId]}
                                            })
                                        }} 
                                        changeAll={changeAll} 
                                        setChangeAll={setChangeAll} 
                                        isCheckAll={true} 
                                        isButton={{text: "Сохранить", action: handleUpdate, isLoading}}
                                    /> : null
                    }
                    {
                        tab === 1 ? <One id={id} contacts={changedUsers.contacts} close={close} setTab={setMainTab}/> : null
                    }
                </div>
            </div>
        </div>
    )
}