import { useGetReferralsLinkQuery } from '../../../../redux/apis/referralApi';
import s from './ReferralLinkModal.module.scss';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg'
import { useEffect, useState } from 'react';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from 'utils/transformDate';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { configureDashboardDate } from 'utils/configureDashboardDate';
import { Skeleton } from 'components/Skeleton/Skeleton';

export const ReferralLinkModal = ({id, close}:{id: number, close: any}) => {

    // ### State
    const [timeRangeState, setTimeRangeState] = useState(0)
    const [dateRange, setDateRange] = useState({startDate: 0, endDate: new Date().getTime()})
    const [graphicData, setGraphicData] = useState<undefined | any[]>(undefined)
    const {t} = useTranslation()

    const {data: link, isLoading} = useGetReferralsLinkQuery({id})
    console.log("link", link);
    // ### Effects
    useEffect(()=>{
        if(link) setGraphicData(configureDashboardDate({referal_click: link?.referal_click, referal_registration: link?.referal_registration}, dateRange, setDateRange))
    },[link, dateRange]);


    // ### Functions
    function changeTimeRange(variant: any){
        setTimeRangeState(variant)
        if(variant === 0){
            setDateRange({startDate: 0, endDate: new Date().getTime()})
        }
        if(variant === 1){
            setDateRange({startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()})
        }
        if(variant === 2){
            setDateRange({startDate: getTimestampWeekAgo(), endDate: new Date().getTime()})
        }
        if(variant === 3){
            setDateRange({startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()})
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.back}></div>
            <div className={s.modal}>
                <div className={s.header}>
                    <span className={s.title}>{link?.name}</span>
                    <div className={s.close_wrapper} onClick={()=>close()}><Close/></div>
                </div>
                <div className={s.content}>
                    <div className={s.timerange_wrapper}>
                        <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(0)}>
                                {t('general.AllTime')}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(1)}>
                                {t('general.24hours')}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(2)}>
                                {t('general.week')}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(3)}>
                                {t('general.month')}
                        </div>
                    </div>
                    
                    <div className={s.statistics}>
                        <div className={s.graph}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={graphicData}
                                margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                                        <XAxis dataKey="date"/>
                                        <CartesianGrid/>
                                        <Line type="linear" name={t('dashboard.referal.clicks')} dataKey='referal_click' stroke="green" strokeWidth={3} strokeOpacity={1} dot={false} style={{filter: `drop-shadow(0px 0px 4px #FF6600)`}}/>
                                        <Line type="linear" name={t('dashboard.referal.registrations')} dataKey='referal_registration' stroke="#FF6600" strokeWidth={3} strokeOpacity={1} dot={false} style={{filter: `drop-shadow(0px 0px 4px green)`}}/>
                                        <Tooltip/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <Skeleton isLoading={isLoading} />
                    </div >
                </div>
            </div>
        </div>
    )
}