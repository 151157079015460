import React, {useEffect, useState} from 'react';
import s from './Dashboard.module.scss';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import { Tabs } from 'components/Tabs/Tabs';
import { Users } from './components/Users/Users';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReferalLinks } from './components/ReferalLinks/ReferalLinks';
import { UserPage } from 'pages/UserPage/UserPage';
import { Statistics } from './components/Statistics/Statistics';
import { Finances } from './components/Statistics/components/Finances/Finances';

export const Dashboard = () => {

    const { t } = useTranslation();
    const location = useLocation();
    
    const items = [
        {
            id: 0,
            title: t('dashboard.statistics.statistics'),
            path: "/dashboard/statistics"
        },
        {
            id: 1,
            title: t('dashboard.users.users'),
            path: "/dashboard/users"
        },
        {
            id: 2,
            title: t('dashboard.referal.referal'),
            path: "/dashboard/referral-links"
        },
        {
            id: 3,
            title: t('dashboard.statistics.finances'),
            path: "/dashboard/finances"
        }
    ]
   
    const [tab, setTab] = useState(items[0]?.id)

    useEffect(()=>{
        if(location.pathname.includes("/dashboard/statistics")) setTab(items[0]?.id)
        else if(location.pathname.includes("/dashboard/users")) setTab(items[1]?.id)
        else if(location.pathname.includes("/dashboard/user")) setTab(items[1]?.id)
        else if(location.pathname.includes("/dashboard/referral-links")) setTab(items[2]?.id)
        else if(location.pathname.includes("/dashboard/finances")) setTab(items[3]?.id)
        else setTab(items[0]?.id)
    },[location.pathname]);

    return (
        <div className={s.dashboard}>
            <HeaderSection
                title={t("dashboard.homePage")}
                icon={<Brush />}
                disabled
            />

            <div className={s.tabs_wrapper}>
                <Tabs
                    items={items}
                    tab={tab}
                    setTab={setTab}
                />
            </div>
            <Routes>
                <Route index element={<Navigate to="/dashboard/statistics" />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user/*" element={<UserPage />} />
                <Route path="/referral-links" element={<ReferalLinks />} />
                <Route path="/finances" element={<Finances />} />
            </Routes>
        </div>
    )
}