import s from "./Card.module.scss";
import imageBlog from "assets/images/imageBlog.webp";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from "assets/icons/delete_image.svg";

import React, {Dispatch, useState} from "react";
import {useTranslation} from "react-i18next";
import {Term} from "utils/term";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useStatusContext} from "components/StatusProvider";
import {useDeleteBlogMutation} from "../../../redux/apis/blogApi";

type CardProps = {
    blog: any;
    setIsAddBlog: Dispatch<any>;
}

export const Card = ({blog, setIsAddBlog}: CardProps ) => {
    const { t, i18n } = useTranslation();
    const { openStatus } = useStatusContext();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleleBlog] = useDeleteBlogMutation();

    const handleDelete = async (id: number) => {
        const result = await deleleBlog({id: id});

        if (result?.data?.message === "Blog delete successful") {
            openStatus('success', "status.operationSuccessful");
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.card}>
            <img
                src={blog?.image?.values?.[i18n.language]}
                alt={blog?.image?.alt}
                className={s.card_image}
            />
            <h3 className={s.card_title}><Term>{blog?.title}</Term></h3>
            <p className={s.card_description}><Term>{blog?.card_body}</Term></p>

            <div className={s.card_author}>{blog?.author}</div>
            <EditIcon className={s.card_edit} onClick={() => setIsAddBlog({show: true, blog: blog})} />
            <DeleteIcon className={s.card_delete} onClick={() => setShowDeleteModal(true)}  />
            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={`${t("general.areYouSure")}? ${t("general.itsContents")}`}
                deletedId={blog?.id}
                buttonRight={handleDelete}
            />
        </div>
    )
}