import React, {ChangeEvent, ReactNode} from "react";
import s from "./AdminTable.module.scss";
import { ReactComponent as Search } from 'assets/icons/search.svg';

type TableProps = {
    title: string;
    placeholder?: string;
    query: string;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
    headers: string[];
    data?: ReactNode;
};

export const AdminTable = ({ title, placeholder, query, handleInputChange, handleKeyDown, handleSearch, headers, data }: TableProps) => {
    return (
        <section className={s.content}>
            <div className={s.header}>
                <h1 className={s.title}>{title}</h1>
                <div className={s.wrap_search}>
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        className={s.searchInput}
                        onKeyDown={handleKeyDown}
                    />
                    <Search className={s.searchIcon} onClick={handleSearch} />
                </div>
            </div>
            <table>
                <thead>
                <tr className={s.first}>
                    {headers.map((header, index) => (
                        <td key={index}><span>{header}</span></td>
                    ))}
                </tr>
                </thead>
                <tbody>
                    {data}
                </tbody>
            </table>
        </section>
    );
}