import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import s from './Mailings.module.scss';
import { useNavigate } from 'react-router-dom';
import { useGetMailingsQuery } from '../../../../redux/apis/mailingApi';
import { MailingItem } from './components/MailingItem/MailingItem';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'components/Skeleton/Skeleton';

export const Mailings = () => {

    // ### State
    const navigate = useNavigate()
    const {i18n, t} = useTranslation()

    const {data: mailings, isLoading} = useGetMailingsQuery({})
    
    // ### Effects

    // ### Functions

    // ### Views
    const mailingsView = mailings?.map((mailing: any, index: number) => {
        return (
            <MailingItem
                key={index}
                data={{
                name: mailing.title?.[i18n.language], 
                date: mailing.date as string,
                id: mailing.id as string,
                inProcess: mailing.inProcess,
                completed: mailing.completed,
                mailing_type: mailing.mailing_type,
                all_send_count: mailing.all_send_count || 0,
                send_count: mailing.send_count || 0,
                start_date: mailing.start_date,
                email_count: mailing.email_count || 0,
                users_length: mailing.users_length,
                changeAll: mailing.changeAll,
                statistics: {
                    users: mailing.send_count,
                    errors: mailing?.statistics?.error?.length || 0,
                    sended: mailing?.statistics?.success?.length || 0
                }
            }}
                />
            )
    })

    return(
        <div className={s.wrapper}>
            <div className={s.header}>
                <PrimaryButton 
                    text={t('mailings.create_mailing')}
                    type={"button"}
                    onClick={()=> navigate('/mailings/list/create')}
                    style={{width: "fit-content"}}
                />
            </div>
            <div className={s.content}>
                {mailingsView}
                <Skeleton isLoading={isLoading} />
            </div>
        </div>
    )
}