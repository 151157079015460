import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const fileExplorerApi = createApi({
    reducerPath: 'fileExplorerApi',
    tagTypes: ['Folders, Files'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getFolders: builder.mutation({
        query: (query) => ({
          url: `/api/explorer/get/folders`,
          method: "GET",
          query
        })  ,
        // providesTags: ["Folders, Files"]
      }),

      getFolderData: builder.mutation({
        query: (query) => ({
          url: `/api/explorer/get/folder`,
          method: "GET",
          params: query
        })  ,
        // providesTags: ["Folders, Files"]
      }),

      UploadFile: builder.mutation({
        query: (body) => ({
          url: '/api/explorer/upload',
          method: 'POST',
          body
        }),
        // invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

      CreateFolder: builder.mutation({
        query: (body) => ({
          url: '/api/explorer/create/folder',
          method: 'POST',
          body
        }),
        // invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

      DeleteFolder: builder.mutation({
        query: (body) => ({
          url: '/api/explorer/delete/folder',
          method: 'DELETE',
          body
        }),
        // invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

      DeleteFile: builder.mutation({
        query: (body) => ({
          url: '/api/explorer/delete/file',
          method: 'DELETE',
          body
        }),
        // invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

    }),
})

export const {
  useGetFoldersMutation,
  useGetFolderDataMutation,
  useUploadFileMutation,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useDeleteFileMutation
} = fileExplorerApi;