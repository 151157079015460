export function checkTextFields(values: {[key:string]:string}, langs:{ langIndex: string; }[]){
    let result = true
    if(!values || !langs || Object.values(values).length < Object.values(langs)?.length){
        result = false
    }
    if(typeof values !== "object") result = false
    Object.values(values).forEach(value => {
        if(!value.length) result = false
    })
    return result
}