import s from './SenderModal.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg'
import { useEffect, useState } from 'react';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { useCreateMailingSenderMutation, useGetMailingSenderMutation, useUpdateMailingSenderMutation } from '../../../../../../redux/apis/mailingApi';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'components/Skeleton/Skeleton';
import { useTranslation } from 'react-i18next';

export const SenderModal = ({close}:any) => {

    // ### State
    const [form, setForm] = useState<any>({
        name: undefined,
        email: undefined,
        host: undefined,
        port: undefined,
        password: undefined
    })
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        host: false,
        port: false,
        password: false
    })
    const {t} = useTranslation()
    const params = useParams()
    const [CreateSender, {isLoading}] = useCreateMailingSenderMutation()
    const [UpdateSender, {isLoading: isUpdateLoading}] = useUpdateMailingSenderMutation()
    const [GetSender, {isLoading: isGetLoading}] = useGetMailingSenderMutation()

    // ### Effects
    useEffect(()=>{
        if(params["*"]){
            handleGetSender(params["*"])
        }
    },[params]);

    // ### Functions
    async function handleGetSender(id: string){
        const response = await GetSender({id})
        if(response.data?.[0]){
            setForm(response.data?.[0])
        }
    }


    async function handleCreate(){
        let error = false
        if(!form.name || form.name?.length < 2){
            setErrors(prev => ({...prev, name: true}))
            error = true
        } else setErrors(prev => ({...prev, name: false}))

        if(!form.email || form.email?.length < 2){
            setErrors(prev => ({...prev, email: true}))
            error = true
        } else setErrors(prev => ({...prev, email: false}))

        if(!form.host || form.host?.length < 2){
            setErrors(prev => ({...prev, host: true}))
            error = true
        } else setErrors(prev => ({...prev, host: false}))

        if(!form.port){
            setErrors(prev => ({...prev, port: true}))
            error = true
        } else setErrors(prev => ({...prev, port: false}))

        if(!form.password || form.password?.length < 2){
            setErrors(prev => ({...prev, password: true}))
            error = true
        } else setErrors(prev => ({...prev, password: false}))
        console.log(error);
        if(error) return

        let func
        if(params["*"]) func = UpdateSender
        else func = CreateSender
        
        const response = await func(form)
        if(response.data.message === "sender_created" || response.data.message === "sender_updated"){
            close()
        }
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.back}></div>
            <div className={s.modal}>
                <div className={s.header}>
                    <span className={s.title}>{t('mailings.create_sender')}</span>
                    <div className={s.close_wrapper} onClick={()=>close()}><Close/></div>
                </div>
                <div className={s.content}>
                    <div className={s.item}>
                        <span className={`${s.title} ${errors.name ? s.error : ""}`}>{t('general.name')}</span>
                        <input 
                            type="text" 
                            value={form.name}
                            onChange={(ev) => setForm((prev: any) => ({...prev, name: ev.target.value}))}
                        />
                    </div>
                    <div className={s.item}>
                        <span className={`${s.title} ${errors.email ? s.error : ""}`}>Email</span>
                        <input 
                            type="text" 
                            value={form.email}
                            onChange={(ev) => setForm((prev: any) => ({...prev, email: ev.target.value}))}
                        />
                    </div>
                    <div className={s.item}>
                        <span className={`${s.title} ${errors.host ? s.error : ""}`}>Host</span>
                        <input 
                            type="text" 
                            value={form.host}
                            onChange={(ev) => setForm((prev: any) => ({...prev, host: ev.target.value}))}
                        />
                    </div>
                    <div className={s.item}>
                        <span className={`${s.title} ${errors.port ? s.error : ""}`}>Port</span>
                        <input 
                            type="number" 
                            value={form.port}
                            onChange={(ev) => setForm((prev: any) => ({...prev, port: Number(ev.target.value)}))}
                        />
                    </div>
                    <div className={s.item}>
                        <span className={`${s.title} ${errors.password ? s.error : ""}`}>Password</span>
                        <input 
                            type="text" 
                            value={form.password}
                            onChange={(ev) => setForm((prev: any) => ({...prev, password: ev.target.value}))}
                        />
                    </div>
                    <PrimaryButton 
                        disabled={isLoading || isUpdateLoading}
                        text={t('general.save')} 
                        type={"button"} 
                        onClick={handleCreate}
                        style={{width: 'fit-content', margin: "0 auto"}}
                    />
                    <Skeleton isLoading={isGetLoading} />
                </div>
            </div>
        </div>
    )
}