import Swal from "sweetalert2";
import {useGetPermissionsQuery} from "../redux/apis/administratorApi";
import {Preloader} from "../components/preloader/Preloader";
import {Status} from "../components/Status/Status";

// const token = localStorage.getItem('token');

type Permissions = {
    children: any;
    page?: string;
    type: string;
}

//Тільки про перегляд сторінки

export const Permissions = ({ children, page, type }: Permissions) => {

    const permissions_quey = useGetPermissionsQuery({});
    if (!permissions_quey.data) return <Preloader />

    const permissions = permissions_quey.data.permissions;
    const user_id = permissions_quey.data.id;
    const target = permissions[`${page}`];

    if(page === "posts" && type === "my"){
        if(target.all || target.my) return children;
        else {
            Swal.fire({
                title: 'На жаль, у вас немає необхідних прав для перегляду сторінки',
                confirmButtonText: 'Закрити',
                customClass: {
                    actions: 'modal',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            })
            return;
        }
    }

    if (target[`${type}`] || user_id === 1) return children;

    else{
        Swal.fire({
            title: 'На жаль, у вас немає необхідних прав для перегляду сторінки',
            confirmButtonText: 'Закрити',
            customClass: {
                actions: 'modal',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if(result.isConfirmed){

            }
        })
    }
}