import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { format } from 'date-fns';
import { DateFormat } from 'utils/enums/common.enum';
import { Table } from 'components/Table/Table';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Consultations = ({consultations}: {consultations: any}) => {

    // ### State
    const {t} = useTranslation()
    // ### Effects

    // ### Functions

    // ### Views

    const columns: TableColumnType[] = [
        {
            title: t('dashboard.users.consultation')
        },
        {
            title: t('general.start_date')
        },
        {
            title: t('general.end_date')
        },
        {
            title: t('general.link')
        }
    ];

    const rows: TableRowType[] = consultations?.map((cons: any) => {
       
        const link = <Button title='Link' onClick={() => window.open(cons.link)}>Link</Button>
        return(
            {
                values: [
                    {
                        title: cons.title
                    },
                    {
                        title: format(new Date(cons.start), DateFormat.LONG)
                    },
                    {
                        title: format(new Date(cons.end), DateFormat.LONG)
                    },
                    {
                        title: link
                    }
                ]
            }
        )
    })

    return(
        <Table 
            rows={rows}
            columns={columns}
            isLoading={false}
        />
    )
}