import s from './DropdownBlock.module.scss';

export const DropdownBlock = ({children, open}: {children: React.ReactNode, open?: boolean}) => {

    // ### State

    // ### Effects

    // ### Functions

    // ### Views

    return(
        <div className={`${s.dropdown} ${open ? s.open : ""}`}>
            <div className={s.content}>{children}</div>
        </div>
    )
}