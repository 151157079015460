import { useEffect, useRef } from 'react';
import s from './Skeleton.module.scss';

export const Skeleton = ({isLoading}: {isLoading: boolean}) => {

    // ### State
    const ref = useRef<HTMLDivElement>(null)

    // ### Effects
    useEffect(()=>{
        if(ref.current){
            const relativeParent = getClosestRelative(ref.current);
            if(relativeParent){
                const border = getComputedStyle(relativeParent).borderRadius
                if(border) ref.current.style.cssText = `border-radius: ${border}`
            }
        }
    },[ref.current]);

    // ### Functions
    function getClosestRelative(element: any) {
        while (element && element !== document.body) {
          if (getComputedStyle(element).position === 'relative') {
            return element;
          }
          element = element.parentElement;
        }
        return null;
    }

    // ### Views

    return(
        <>{ isLoading ? <div className={s.skeleton} ref={ref}></div> : null }</>
    )
}