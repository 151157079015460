import s from './FileManager.module.scss';
import { ReactComponent as NewFolder }  from "../../assets/icons/new_folder.svg"
import { ReactComponent as NewFile }  from "../../assets/icons/new_file.svg"
import { ReactComponent as Back }  from "../../assets/icons/back.svg"
import { ReactComponent as Close }  from "../../assets/icons/close.svg"
import { ReactComponent as Loading }  from "../../assets/icons/loader.svg"
import { Folder } from './components/Folder/Folder';
import { useCreateFolderMutation, useDeleteFileMutation, useDeleteFolderMutation, useGetFolderDataMutation, useGetFoldersMutation, useUploadFileMutation } from '../../redux/apis/fileExplorerApi';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FolderItem } from './components/FolderItem/FolderItem';
import { imageExtensions, videoExtensions } from 'utils/constant';
import { ImageItem } from './components/ImageItem/ImageItem';
import { VideoItem } from './components/VideoItem/VideoItem';
import { FileItem } from './components/FileItem/FileItem';
import { sendMedia } from 'utils/sendMedia';
import { useStatusContext } from 'components/StatusProvider';
import { Progress } from 'components/Progress/Progress';
import { ModalGeneral } from 'components/Modals/ModalGeneral';
import { useTranslation } from 'react-i18next';

export type FileManagerType = {
    isModal?: boolean
    setIsOpenStorage?: (value: boolean) => void
    setFile?: (value: string) => void
    type?: string
}

export const FileManager = ({isModal, setIsOpenStorage, setFile, type}: FileManagerType) => {
    
    // ### State
    const [activeFolder, setActiveFolder] = useState<string>("public/storage/")
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [totalChunks, setTotalChunks] = useState<any>();
    const [progress, setProgress] = useState<any>();
    const [fileNameProgress, setFileNameProgress] = useState<string>("");
    const [folders, setFolders] = useState<any>()
    const [folder, setFolderData] = useState<any>()
    const [nameModal, setNameModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [deleteFileModal, setDeleteFileModal] = useState<any>(false)

    const { openStatus } = useStatusContext();
    const {t} = useTranslation()

    const [filesUpload] = useUploadFileMutation()
    const [getFolders, {isLoading: isGetFolders}] = useGetFoldersMutation()
    const [GetFolderData, {isLoading: isGetFolder}] = useGetFolderDataMutation()
    const [CreateFolder, {isLoading: isCreateFolder}] = useCreateFolderMutation()
    const [DeleteFolder, {isLoading: isDeleteFolder}] = useDeleteFolderMutation()
    const [DeleteFile, {isLoading: isDeleteFile}] = useDeleteFileMutation()

    // ### Effects

    useEffect(()=>{
        handleGetFolders()
    },[]);

    // ### Functions

    async function handleDeleteFile(data: string){
        const response: any = await DeleteFile({
            file: deleteFileModal
        })
        if(response.error){
            openStatus('error', response.error.data.message);
        }
        else{
            openStatus('success', response.data.message);
            handleGetFolders()
            handleGetFolderData()
        }
    }

    async function handleDeleteFolder(){
        const response: any = await DeleteFolder({
            path: deleteModal,
        })
        if(response.error){
            openStatus('error', response.error.data.message);
        }
        else{
            openStatus('success', response.data.message);
            handleGetFolders()
            handleGetFolderData()
        }
    }

    async function handleCreateFolder(name: string){
        const response: any = await CreateFolder({
            path: activeFolder,
            name: name
        })
        if(response.error){
            openStatus('error', response.error.data.message);
        }
        else{
            openStatus('success', response.data.message);
            handleGetFolders()
            handleGetFolderData()
        }
    }

    function removeLastFolder(path: string) {
        path = path.replace(/\/$/, '');
        let parts = path.split('/');
        parts.pop();
        return parts.join('/')
    }

    async function handleGetFolders(){
        const data = await getFolders({})
        setFolders(data.data)
    }

    const handleGetFolderData = useCallback(async function(){
        const data = await GetFolderData({path: activeFolder})

        const newData = JSON.parse(JSON.stringify(data.data.data))
        
        if(type === "image" && newData?.length){
            const sortedData = newData?.filter((el: any) => {
                if(imageExtensions.includes(el.mimetype) || el.isFolder) return true
                else return false
            })
            setFolderData(sortedData)
        }
        else if(type === "video" && newData?.length){
            const sortedData = newData?.filter((el: any) => {
                // if(imageExtensions.includes(el.mimetype) || el.isFolder) return true
                if(videoExtensions.includes(el.mimetype) || el.isFolder) return true
                else return false
            })
            setFolderData(sortedData)
        }
        else setFolderData(newData)
    }, [activeFolder, GetFolderData])

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        setIsLoadingFile(true);
        const files = event.target.files;
        if (!files || files.length === 0) return;

        const result: any = await sendMedia(
            files?.[0],
            filesUpload,
            setTotalChunks,
            setProgress,
            setFileNameProgress,
            undefined,
            undefined,
            undefined,
            true,
            activeFolder
        );
        if (result?.data?.message === "file_uploaded") {
            setIsLoadingFile(false);
            handleGetFolderData()
            openStatus('success', "status.operationSuccessful");
        }
    };

    useEffect(()=>{
        if(activeFolder) handleGetFolderData()
    },[activeFolder, handleGetFolderData]);

    // ### Views
    const folderViews = folders?.data?.map((folder: any, index: number) => {
        return(
            <Folder
                key={index}
                folder={folder}
                activeFolder={activeFolder}
                setActiveFolder={setActiveFolder}
            />
        )
    })

    const boardViews = folder?.sort((a: any, b: any) => (b.isFolder ? 1 : 0) - (a.isFolder ? 1 : 0))?.map((el: any, index: number) => {
        if(el.isFolder){
            return(
                <FolderItem key={index} folder={el} setActiveFolder={setActiveFolder} setDeleteModal={setDeleteModal}/>
            )
        }
        else if(imageExtensions.includes(el.mimetype)){
            return(
                <ImageItem key={index} file={el} setDeleteFileModal={setDeleteFileModal} isModal={isModal} setFile={setFile} setIsOpenStorage={setIsOpenStorage}/>
            )
        }
        else if(videoExtensions.includes(el.mimetype)){
            return(
                <VideoItem key={index} file={el} setDeleteFileModal={setDeleteFileModal} isModal={isModal} setFile={setFile} setIsOpenStorage={setIsOpenStorage}/>
            )
        }
        else{
            return(
                <FileItem key={index} folder={el} setDeleteFileModal={setDeleteFileModal} isModal={isModal}/>
            )
        }
    })

    if (isLoadingFile) return <Progress
        count={totalChunks}
        progress={progress}
        fileNameProgress={fileNameProgress}
        storage
    />

    return(
        <div className={s.manager_wrapper}>
            <div className={s.content}>
                <div className={s.sidebar}>
                    <div className={s.header}>
                        <div className={s.left_bar}>
                            <div className={s.item}>
                                <NewFolder/>
                                <span className={s.title} onClick={()=>{setNameModal(true)}}>New folder</span>
                            </div>
                            <div className={`${s.item} ${s.upload_wrapper}`}>
                                <input type="file" onChange={handleImageUpload}/>
                                <NewFile/>
                                <span className={s.title}>Upload file</span>
                            </div>
                        </div>
                    </div>
                    {folderViews}
                    { isGetFolders ? <div className={s.loading}><Loading/></div> : null }
                </div>
                <div className={s.board}>
                    <div className={s.header}>
                        <div className={s.left_bar}>
                            <div className={s.item} onClick={() => { 
                                if(activeFolder === "public/storage/" || activeFolder === "public/storage") return
                                setActiveFolder(removeLastFolder(activeFolder)) 
                            }}>
                                <Back/>
                            </div>
                            <span className={s.location}>{activeFolder.replace('public/storage', "")}</span>
                        </div>
                        {
                            isModal? 
                            <div className={s.close} onClick={()=>setIsOpenStorage && setIsOpenStorage(false)}>
                                <Close/>
                            </div> : null
                        }
                    </div>
                    <div className={s.board_content}>
                        {boardViews?.filter((el:any) => el)}
                    </div>
                    { (isGetFolder || isCreateFolder || isDeleteFolder || isDeleteFile) ? <div className={s.loading}><Loading/></div> : null }
                </div>
            </div>
        
        <ModalGeneral
            showModal={nameModal}
            setShowModal={setNameModal}
            title={t("storage.folderName")}
            textLeftButton={t("general.cancel")}
            textRightButton={t("general.confirm")}
            buttonRight={(name: string)=> {
                handleCreateFolder(name)
                setNameModal(false)
            } }
            addFolder
        />

        <ModalGeneral
            showModal={deleteModal}
            setShowModal={setDeleteModal}
            title={"Удалить?"}
            textLeftButton={t("general.cancel")}
            textRightButton={t("general.confirm")}
            buttonRight={()=> {
                handleDeleteFolder() 
                setDeleteModal(false)
            } }
        />

        <ModalGeneral
            showModal={deleteFileModal}
            setShowModal={setDeleteFileModal}
            title={"Удалить?"}
            textLeftButton={t("general.cancel")}
            textRightButton={t("general.confirm")}
            buttonRight={(file: any)=> {
                handleDeleteFile(file) 
                setDeleteFileModal(false)
            } }
        />

        </div>
    )
}