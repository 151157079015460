import React, {useState} from 'react';
import s from "./Input.module.scss";
import {Field} from "formik";
import close from 'assets/icons/close.svg';
import show from "assets/icons/show.svg";
import eyeClosed from "assets/icons/eyeClosed.svg";

type Input = {
    label: string;
    errors?: any;
    touched?: any;
    name: string;
    placeholder?: string;
    onClick?: any;
    password?: boolean;
    isDisabled?: boolean;
    value: any;
    type?: string
}

export const Input = ({
                          label,
                          errors,
                          touched,
                          onClick,
                          name,
                          placeholder,
                          password,
                          isDisabled,
                          value,
                          type
}: Input) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={s.form}>
            <label>{label}</label>
            <div className={s.inputContainer}>
                {value && <img
                    src={password ? showPassword ? show : eyeClosed : close}
                    alt={s.deleteIcon}
                    className={password ? s.iconInputEye : s.iconInput}
                    onClick={onClick ? onClick : () => setShowPassword(!showPassword)}
                />}
                <Field
                    name={name}
                    type={ type || (password ? (showPassword ? 'text' : 'password') : 'text') }
                    placeholder={placeholder}
                    className={errors ? s.error : "" }
                    disabled={isDisabled}
                    autoComplete={password ? "new-password" : undefined}
                />
            </div>
            <span className={s.error_text}>{touched ? errors : null}</span>
        </div>
    )
}