import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Back} from "components/Back/Back";
import {useTranslation} from "react-i18next";
import s from "./ModuleModal.module.scss";
import {Form, Formik, FormikHelpers} from "formik";

import * as Yup from "yup";
import {useSelector} from "react-redux";
import {useCreateModuleMutation, useGetModuleQuery} from "../../../../redux/apis/modulesApi";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {ReactComponent as ArrowTools} from "assets/icons/arrow_tools.svg";

import {useStatusContext} from "components/StatusProvider";
import {extractIds, findById} from "utils/moduleAdd";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import cn from "classnames";
import {Preloader} from "components/preloader/Preloader";

import {DropDown} from "components/DropDown/DropDown";
import {Input} from "components/Input/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type ModuleModalProps = {
    close: Dispatch<SetStateAction<boolean>>;
    id?: number;
    setIsAddModule: React.Dispatch<React.SetStateAction<boolean>>;
    refetchGetSection: any;
    idSection: number;
}

type RenderModuleValuesProps = {
    values: any[];
    valuesForm?: any;
    errors?: any;
    touched?: any;
    setFieldValue?: any;
};

type ModuleValuesItemsProps = {
    name: string;
    type: string;
    value: any;
    valuesForm: any;
    errors: any;
    touched: any;
    setFieldValue: any;
};

interface FormValues {
    link: string;
}

export const ModuleModal = ({ close, id, setIsAddModule, refetchGetSection, idSection }: ModuleModalProps) => {
    const { data: module, isFetching } = useGetModuleQuery({ id }, { skip: !id, refetchOnMountOrArgChange: true });
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const [createModule, {isLoading}] = useCreateModuleMutation();
    const [object, setObject] = useState<any[]>([]);
    const { openStatus } = useStatusContext();
    const [hidden, setHidden] = useState<number[]>([]);
    const [defaultImage, setDefaultImage] = useState<any>([]);
    const [isManual, setIsManual] = useState<number[]>([]);

    useEffect(() => {
        if (module) {
            setObject([JSON.parse(module?.module?.type)]);
        } else {
            setObject([{
                type: "object",
                name: "Test",
                marker: "test",
                values: {}
            }]);
        }
    }, [id, module]);

    if (isFetching || isLoading) return <Preloader />

    const initialValues = {
        link: ""
    };

    const validationSchema = Yup.object({
        link: Yup.string()
    });

    const onSubmit = async (
        values: FormValues,
        {
            setSubmitting,
            resetForm
        }: FormikHelpers<FormValues>
    ) => {
        const responce: any  = findById(object?.[0]?.values, extractIds(values), values, langs, defaultImage, isManual)

        console.log("responce", responce.result)

        const result: any = await createModule({
            type: "object",
            name: object?.[0]?.name,
            module_id: id,
            section_id: idSection,
            marker: object?.[0]?.marker,
            values: responce.result,
        });

        if (result.data.message === "ok") {
            openStatus('success', "status.operationSuccessful");
            setSubmitting(false);
            resetForm();
            setIsAddModule(false);
            refetchGetSection()
        } else {
            openStatus('error', "status.error");
        }
    };

    const classType = (type: string) => cn(s.type, {
        [s.type_array]: type === "array",
        [s.type_object]: type === "object",
    });

    const handleArrowClick = (id: number) => {
        setHidden(prevState =>
            prevState.includes(id)
                ? prevState.filter(item => item !== id)
                : [...prevState, id]
        );
    };

    const RenderModuleValues = ({
                                    values,
                                    valuesForm,
                                    errors,
                                    touched,
                                    setFieldValue
                                }: RenderModuleValuesProps) => (
        <>
            {Array.isArray(values) ? (
                values?.map((value, index) => (
                    value?.values ? (
                        <div className={`${s.container_first}`} key={index}>
                            <div className={`${s.module_values_item} ${hidden?.includes(value?.id) ? s.hide_1: ""}`}>
                                <div className={s.item_name} onClick={() => handleArrowClick(value?.id)}>
                                    <div className={s.main_info}>
                                        <div className={s.data}>
                                            <p>{t("pagesModal.name")}:</p>
                                            <span>{value?.name}</span>
                                        </div>
                                        <div className={s.data}>
                                            <p>{t("pages.marker")}:</p>
                                            <span>{value?.marker}</span>
                                        </div>
                                    </div>
                                    <span className={classType(value?.type)}>{value?.type}</span>
                                    <div className={s.setting}>
                                        <ArrowTools className={s.setting_arrow} />
                                    </div>
                                </div>
                                <RenderModuleValues
                                    values={value?.values}
                                    valuesForm={valuesForm}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>
                    ) : (
                        <ModuleValuesItem
                            key={index}
                            name={value?.name}
                            type={value?.type}
                            value={value}
                            valuesForm={valuesForm}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                        />
                    )
                ))
            ) : typeof values === 'object' && values !== null ? (
                Object.values(values).map((value: any, index: number) => (
                    value?.values ? (
                        <div className={`${s.container_second}`} key={index}>
                            <div className={`${s.module_values_item} ${hidden?.includes(value?.id) ? s.hide_1: ""}`}>
                                <div className={s.item_name}>
                                    <div className={s.main_info}>
                                        <div className={s.data}>
                                            <p>{t("pagesModal.name")}:</p>
                                            <span>{value?.name}</span>
                                        </div>
                                        <div className={s.data}>
                                            <p>{t("pages.marker")}:</p>
                                            <span>{value?.marker}</span>
                                        </div>
                                    </div>
                                    <span className={classType(value?.type)}>{value?.type}</span>
                                    <div className={s.setting}>
                                        <ArrowTools className={s.setting_arrow} onClick={() => handleArrowClick(value?.id)} />
                                    </div>
                                </div>
                                <RenderModuleValues
                                    values={value?.values}
                                    valuesForm={valuesForm}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>
                    ) : (
                        <ModuleValuesItem
                            key={index}
                            name={value?.name}
                            type={value?.type}
                            value={value}
                            valuesForm={valuesForm}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                        />
                    )
                ))
            ) : null}
        </>
    );

    const ModuleValuesItem = ({
                                  name,
                                  type,
                                  value,
                                  valuesForm,
                                  errors,
                                  touched,
                                  setFieldValue
                              }: ModuleValuesItemsProps) => {
        return (
            <div className={`${s.container_third} `}>
                <div className={`${s.item} ${hidden.includes(value?.id) ? s.hide_1: ""}`}>
                    <div className={s.item_name} onClick={() => handleArrowClick(value?.id)}>
                        <div className={s.main_info}>
                            <div className={s.data}>
                                <p>{t("pagesModal.name")}:</p>
                                <span>{name}</span>
                            </div>
                            <div className={s.data}>
                                <p>{t("pages.marker")}:</p>
                                <span>{value?.marker}</span>
                            </div>
                        </div>
                        <span className={classType(value?.type)}>{value?.type}</span>
                        <div className={s.setting}>
                            <ArrowTools className={s.setting_arrow}/>
                        </div>
                    </div>

                    {(type !== "number" &&  type !== "slug" && type !== "date") && <DropDown
                        langs={langs}
                        errors={errors[`${type}${value?.id}`]}
                        touched={touched[`${type}${value?.id}`]}
                        kind={type + value?.id}
                        label={type}
                        setFieldValue={setFieldValue}
                        values={valuesForm}
                        module
                        setDefaultImage={setDefaultImage}
                        defaultImage={defaultImage}
                        isManual={isManual}
                        setIsManual={setIsManual}
                        id={value?.id}
                    />}
                    {type === "link" &&
                        <Input
                            value={valuesForm.link}
                            label={t("pages.link")}
                            errors={errors.link}
                            touched={touched.link}
                            name={"link" + value?.id}
                            onClick={() => setFieldValue("link" + value?.id, '')}
                        />
                    }
                    {type === "number" &&
                        <Input
                            type="number"
                            value={valuesForm.number}
                            label={t("pages.number")}
                            errors={errors.number}
                            touched={touched.number}
                            name={"number" + value?.id}
                            onClick={() => setFieldValue("number" + value?.id, '')}
                        />
                    }
                    {type === "slug" &&
                        <Input
                            value={valuesForm.slug}
                            label={"Slug"}
                            errors={errors.slug}
                            touched={touched.slug}
                            name={"slug" + value?.id}
                            onClick={() => setFieldValue("slug" + value?.id, '')}
                        />
                    }
                    {type === "date" &&
                        <DatePicker
                            selected={valuesForm["date" + value?.id]}
                            name={"date" + value?.id}
                            onChange={(date: Date | null) => setFieldValue("date" + value?.id, date)}
                            placeholderText={"Date"}
                            className={errors && touched ? s.error : ""}
                            dateFormat="dd.MM.yyyy, HH:mm:ss"
                            showTimeSelect
                        />
                    }
                </div>
            </div>
        );
    };
    return (
        <div className={s.container}>
            <Back close={close} text={t("blocksModal.backToBlocks")}/>
            <div className={s.form}>
                <h2>{t("general.addModule")}</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={false}
                >
                    {({
                          values: valuesForm,
                          errors,
                          touched,
                          setFieldValue
                      }) => {
                        return (
                            <Form>
                            <div className={s.modules}>
                                    {object?.map((module: any, moduleIndex: number) => (
                                        <div className={`${s.container} `} key={moduleIndex}>
                                            <div className={`${s.module} ${hidden?.includes(module?.id) ? s.hide: ""}`}>
                                                <div className={s.module_header}  onClick={() => handleArrowClick(module?.id)}>
                                                    <div className={s.main_info}>
                                                        <div className={s.data}>
                                                            <p>{t("pagesModal.name")}:</p>
                                                            <span>{module?.name}</span>
                                                        </div>
                                                        <div className={s.data}>
                                                            <p>{t("pages.marker")}:</p>
                                                            <span>{module?.marker}</span>
                                                        </div>
                                                    </div>
                                                    <span className={classType(module?.type)}>{module?.type}</span>
                                                    <div className={s.setting}>
                                                        <ArrowTools className={s.setting_arrow} />

                                                    </div>
                                                </div>

                                                <div className={s.module_values}>
                                                    {Array.isArray(module?.values) ? (
                                                        module?.values?.map((item: any, index: number) => (
                                                            <div key={index} className={s.module_values_item}>
                                                              <p>{item}</p>
                                                            </div>
                                                        ))
                                                    ) : module?.values ? (
                                                        <RenderModuleValues
                                                            values={Object.values(module?.values)}
                                                            valuesForm={valuesForm}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className={s.wrap_buttons}>
                                        <PrimaryButton
                                            text={t("general.confirm")}
                                            type={"submit"}
                                            icon={<Check/>}
                                        />
                                        <SecondaryButton
                                            text={t("general.cancel")}
                                            type={"button"}
                                            white
                                            onClick={() => close(false)}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
                </Formik>
            </div>
        </div>
    )
}