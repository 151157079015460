import s from './StyledNumberInput.module.scss';

export const StyledNumberInput = ({value, onChange}: any) => {

    // ### State

    // ### Effects

    // ### Functions
    function handleChange(value: any){
        const check = Number(value)
        if(typeof check === "number" && !Number.isNaN(value)){
            if(check > 999) onChange(999)
            else if(check < 1) onChange(1)
                else onChange(check)
        } else onChange(1)
    }

    function handleDec(){
        handleChange(value - 1)
    }

    function handleInc(){
        handleChange(value + 1)
    }

    // ### Views

    return(
        <div className={s.wrapper}>
            <div className={s.dec} onClick={handleDec}>-</div>
            <input 
                className={s.input}
                type="text" 
                value={value}
                onChange={(ev) => handleChange(ev.target.value)}
            />
            <div className={s.inc} onClick={handleInc}>+</div>
        </div>
    )
}