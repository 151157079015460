import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const mailingApi = createApi({
    reducerPath: 'mailingApi',
    tagTypes: ['Sender', 'Group', "Mailing"],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),

    endpoints: (builder) => ({

        // Sender

        getMailingSenders: builder.query({
            query: (params) => ({
                url: `/api/mailing/sender/get`,
                method: "GET",
                params
            }),
            providesTags: ["Sender"]
        }),

        getMailingSender: builder.mutation({
            query: (params) => ({
                url: `/api/mailing/sender/get`,
                method: "GET",
                params
            }),
            invalidatesTags: ["Sender"]
        }),

        createMailingSender: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/sender/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Sender"]
        }),

        updateMailingSender: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/sender/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Sender"]
        }),

        deleteMailingSender: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/sender/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Sender"]
        }),


        // Groups

        getGroups: builder.query({
            query: (params) => ({
                url: `/api/mailing/group/get`,
                method: "GET",
                params
            }),
            providesTags: ["Group"]
        }),

        getFullGroup: builder.query({
            query: (params) => ({
                url: `/api/mailing/group/get/full`,
                method: "GET",
                params
            }),
            providesTags: ["Group"]
        }),

        createGroup: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/group/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Group"]
        }),

        updateGroup: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/group/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Group"]
        }),

        deleteGroup: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/group/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Group"]
        }),


        // ### TEMPLATE

        getEmailHTMLTemplate: builder.query({
            query: (params) => ({
                url: `/api/mailing/template/get`,
                method: "GET",
                params
            })
        }),

        // ### MAILINGS

        getMailings: builder.query({
            query: (params) => ({
                url: `/api/mailing/get`,
                method: "GET",
                params
            }),
            providesTags: ["Mailing"]
        }),

        getMailingsHandler: builder.mutation({
            query: (params) => ({
                url: `/api/mailing/get`,
                method: "GET",
                params
            }),
            invalidatesTags: ["Mailing"]
        }),

        createMailing: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/create`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Mailing"]
        }),

        updateMailing: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/update`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Mailing"]
        }),

        deleteMailing: builder.mutation({
            query: (body) => ({
                url: `/api/mailing/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Mailing"]
        }),

    }),
  })

export const {
    //Sender
    useGetMailingSendersQuery,
    useGetMailingSenderMutation,
    useCreateMailingSenderMutation,
    useUpdateMailingSenderMutation,
    useDeleteMailingSenderMutation,
    // Group
    useGetGroupsQuery,
    useGetFullGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    // Template
    useGetEmailHTMLTemplateQuery,
    // Mailings
    useGetMailingsQuery,
    useGetMailingsHandlerMutation,
    useCreateMailingMutation,
    useUpdateMailingMutation,
    useDeleteMailingMutation
} = mailingApi;