import React, {useEffect, useState} from 'react';
import s from './DropDownSecond.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import {NavigateFunction} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import translateIcon from "assets/icons/translate.webp"
import {translateText} from "utils/translateText";
import {useStatusContext} from "../StatusProvider";
import {Editor} from "@tinymce/tinymce-react";
import {ModalStorage} from "../ModalStorage/ModalStorage";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import {key} from "../../utils/getKey";
import {string} from "yup";

type DropDownSecond = {
    langs?: any;
    label?: string;
    value?: any;
    refDrop?: any;
    onChange?: any;
    el?: any;
    image?: boolean;
    values?: any;
    video?: boolean;
    navigate: NavigateFunction;
    defaultImage?: any;
    setDefaultImage?: React.Dispatch<any>;
    activeImages?: any;
    setFiles?: React.Dispatch<React.SetStateAction<any[]>>;
    files?: any[];
    isOn?: boolean;
    setIsOn?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DropDownSecond = ({
                                   langs,
                                   label,
                                   value,
                                   refDrop,
                                   onChange,
                                   image,
                                   values,
                                   video,
                                   defaultImage,
                                   setDefaultImage,
                                   activeImages,
                                   setFiles,
                                   files,
                                   isOn,
                                   setIsOn
                               }: DropDownSecond) => {
    const [langMode, setLangMode] = useState<boolean>(false)
    const [hideStates, setHideStates] = useState<{ [key: string]: boolean }>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs?.[0]?.key || '');
    const { t } = useTranslation();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const [translateValues, setTranslateValues] = useState<any>();
    const { openStatus } = useStatusContext();
    const valueArray: any = Object.values(value);
    const [isOpenStorage, setIsOpenStorage] = useState<boolean>(false);
    const [isLoadingTranslate, setIsLoadingTranslate] = useState<boolean>(false);
    const MAX_TRANSLATE_LENGTH = 5000;
    const [kindLang, setKindLang] = useState<string>("");

    useEffect(() => {
        if (langs) {
            const initialState = langs.reduce(
                (acc: { [key: string]: boolean }, lang: any) => {
                    acc[lang.key] = true;
                    return acc;
                },
                {}
            );
            setHideStates(initialState);
        }
    }, [langs]);

    const handleSetFile = (file: string) => {
        if (!isOn) {
            if (!file) {
                console.log("file not found");
                return;
            }
            const key = defaultImage ?? langs?.[0]?.key;
            if (setFiles) {
                setFiles((prevFiles) =>
                    prevFiles.some((f) => f.lang_key === key)
                        ? prevFiles.map((f) =>
                            f.lang_key === key ? { file, lang_key: key } : f
                        )
                        : [...prevFiles, { file, lang_key: key }]
                );
            }
            if (setDefaultImage && !defaultImage) {
                setDefaultImage(langs?.[0]?.key);
            }
        } else if (setFiles) {
            if (files?.length === 0 && !values.file) {
                if (setDefaultImage) {
                    setDefaultImage(kindLang);
                }
            }
            setFiles((prevFiles) =>
                prevFiles.some((f) => f.lang_key === kindLang)
                    ? prevFiles.map((f) =>
                        f.lang_key === kindLang ? { file, lang_key: kindLang } : f
                    )
                    : [...prevFiles, { file, lang_key: kindLang }]
            );
        }
    };

    if (isOpenStorage) {
        return <ModalStorage
            setIsOpenStorage={setIsOpenStorage}
            type={image? "image" : "video"}
            setFile={handleSetFile}
        />
    }

    const toggleHide = (key: string) => {
        setHideStates((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleLangChange = (key: string) => {
        setSelectedLang(key);
    };

    function getCurrentValue(){
        let value
        for(let i= 0; i < langs?.length; i++){
            if(translateValues[langs?.[i].key]?.length){
                value = translateValues[langs?.[i].key]
                break
            }
        }
        return value
    }

    const handleTranslate = async () => {
        try {
            setIsLoadingTranslate(true);
            const text = getCurrentValue();

            if (!text) {
                setIsLoadingTranslate(false);
                openStatus('error', "status.notPicture");
                return;
            }

            const chunks = [];
            let chunkStart = 0;

            while (chunkStart < text.length) {
                let chunkEnd = chunkStart + MAX_TRANSLATE_LENGTH;
                if (chunkEnd < text.length) {
                    const lastSpaceIndex = text.lastIndexOf(' ', chunkEnd);
                    if (lastSpaceIndex > chunkStart) {
                        chunkEnd = lastSpaceIndex;
                    }
                }
                chunks.push(text.slice(chunkStart, chunkEnd));
                chunkStart = chunkEnd + 1;
            }

            const languages = langs?.map((l: any) => l.key) || [];
            const results = await Promise.all(chunks.map(chunk => translateText(chunk, languages)));

            const mergedResult = results.reduce((acc, part) => {
                for (const key in part) {
                    acc[key] = (acc[key] || '') + part[key];
                }
                return acc;
            }, {} as Record<string, string>);

            setTranslateValues(mergedResult);
            setIsLoadingTranslate(false);
        } catch (error) {
            setIsLoadingTranslate(false);
            openStatus('error', "status.translationError");
            console.error("Error during translation:", error);
        }
    };

    const handleQuillChange = (content: string, langKey: string) => {
        onChange({ target: { value: content } }, 'quill');

        if (translateValues?.[langKey] !== content) {
            setTranslateValues((prevValues: any) => ({
                ...prevValues,
                [langKey]: content,
            }));
        }
    };

    return (
        <div className={s.dropdown}>
            {(image || video) && (
                <>
                    <div className={s.switch}>
                        Add pictures for different languages
                        <input
                            type="checkbox"
                            checked={isOn}
                            onChange={() => {
                                if (setIsOn) {
                                    setIsOn(!isOn)
                                }
                            }}
                        />
                    </div>

                    {!isOn && (
                        <div className={s.content_row}>
                            <div className={s.flex_column}>
                            <p>{label}</p>
                                <div
                                    className={s.image_wrapper}
                                    style={{ backgroundImage: `url(${!files?.[0]?.file ?
                                            API_ADDRESS + values?.file?.find((item: any) => item.lang_key === defaultImage)?.link :
                                            files?.[0]?.file})` }}
                                    onClick={() => {
                                        setIsOpenStorage(true)
                                        setKindLang(defaultImage ?? langs?.[0].key);
                                    }}
                                >
                                    {
                                        (!files?.[0]?.file && !values?.file?.find((item: any) => item.lang_key === defaultImage)?.link) &&
                                        <div className={s.no_elements}>Change file</div>
                                    }
                                    {!image && (
                                        <div className={s.video_wrapper}>
                                            {(!files?.[0]?.file && !values?.file?.find((item: any) => item.lang_key === defaultImage)?.link) ?
                                                <div className={s.no_elements}>Change file</div>:
                                                <video
                                                    playsInline
                                                    src={(!files?.[0]?.file ?
                                                        API_ADDRESS + values?.file?.find((item: any) => item.lang_key === defaultImage)?.link:
                                                        files?.[0]?.file)}
                                                    controls
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {isOn && (
                        <div className={s.content_row}>
                            {langs?.map((ln: any, index: number) => {

                                const fileEntry = files?.find((el: any) => el.lang_key === ln.key);
                                const valueEntry = values?.file?.find((el: any) => el.lang_key === ln.key);
                                const fileUrl = fileEntry?.file ? fileEntry.file : API_ADDRESS + (valueEntry?.link || "");

                                return (
                                    <div key={index} className={s.flex_column}>
                                        <div className={s.top_checkbox}>
                                            {label} <span>{ln.key}</span> |
                                            <input
                                                type="checkbox"
                                                checked={defaultImage === ln.key}
                                                onChange={() => {
                                                    if (setDefaultImage && (fileEntry || valueEntry)) setDefaultImage(ln.key);
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={s.image_wrapper}
                                            onClick={() => {
                                                setIsOpenStorage(true);
                                                setKindLang(ln.key);
                                            }}
                                            style={{backgroundImage: `url(${fileUrl})`}}
                                        >
                                            {
                                                (!fileEntry && !valueEntry) && <div className={s.no_elements}>Change file</div>
                                            }
                                            {!image && (
                                                <div className={s.video_wrapper}>
                                                    <video
                                                        playsInline
                                                        src={fileUrl}
                                                        controls
                                                        style={{width: "100%", height: "100%"}}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}

            {(!image && !video) && <label>{t("language.langs")}</label>}
            {(!image && !video) && langs?.map((ln: any, index: number) => (
                <div
                    className={`${s.content}  html_content ${!hideStates[ln.key] && s.hide} ${selectedLang !== ln.key && s.none}`}
                    key={ln.key}>

                    <div className={s.content_head} onClick={() => toggleHide(ln.key)}>
                        <p>{label} <span>{ln.key}</span></p>

                        <ArrowTools className={s.arrow}/>
                    </div>

                    <div className={s.lang_switcher}>
                        {(!image && !video) && langs?.map((ln: any) => (
                            <div
                                key={ln.key}
                                className={`${s.lang_button} ${selectedLang === ln.key ? s.active : ''}`}
                                onClick={() => handleLangChange(ln.key)}
                            >
                                {ln.key}
                                {((refDrop.current[ln.key]?.getContent() && refDrop.current[ln.key]?.getContent() !== "<p><br></p>") || translateValues?.[ln.key]) &&
                                    <CheckIcon className={s.check_icon}/>}
                            </div>
                        ))}
                        {(!image && !video) &&
                            (isLoadingTranslate ? <LoadingIcon className={s.loadingIcon}/> :
                                    <img
                                        src={translateIcon}
                                        alt={"translateIcon"}
                                        className={s.translateIcon}
                                        onClick={handleTranslate}
                                    />
                            )
                        }
                    </div>

                    <div className={"storage"} onClick={() => setIsOpenStorage(true)}>Storage</div>

                    <Editor
                        data-refval={ln.id}
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => {
                            refDrop.current[ln.key] = editor; // Зберігаємо редактор для кожної мови
                        }}
                        init={{
                            branding: false,
                            width: 'inherit',
                            skin: "oxide-dark",
                            content_css: "dark",
                            height: 500,
                            menubar: false,
                            plugins: "image link media",
                            toolbar:
                                "styleselect undo redo | h1 h2 h3 | bold italic underline strikethrough | image link media alignleft aligncenter alignright alignjustify | bullist outdent indent | removeformat",
                            image_advtab: false,
                            image_class_list: [
                                {title: 'Center', value: 'image_center'},
                                {title: 'Left', value: 'image_left'},
                                {title: 'Right', value: 'image_right'},
                                {title: 'Left Float', value: 'image_left_float'},
                                {title: 'Right Float', value: 'image_right_float'}
                            ],
                            style_formats: [
                                {
                                    title: 'image_left_float', selector: 'image_left_float', styles: {
                                        'float': 'left',
                                        'margin': '0 10px 0 10px'
                                    }
                                }
                            ],
                            content_style: `
                        body {
                            background-color: #232428;
                        }
                    `,
                        }}
                        value={
                            translateValues?.[ln.key] ??
                            refDrop.current[ln.key]?.value ??
                            valueArray?.filter((val: any) => val?.lang_key === ln.key)?.[0]?.value
                        }
                        onEditorChange={(content) => handleQuillChange(content, ln.key)}
                        id={`editor-${ln.key}`}
                        textareaName={`textarea-${ln.key}`}
                    />


                    {defaultImage && (
                        <div className={s.default_wrapper}>
                            <input
                                type="checkbox"
                                defaultChecked={defaultImage?.lang_key === ln.key}
                                checked={ln.key === defaultImage?.lang_key}
                                onChange={() =>
                                    setDefaultImage &&
                                    setDefaultImage({
                                        lang_key: ln.key,
                                        id: values.file?.find((el: any) => el.lang_key === ln.key)?.id,
                                    })
                                }
                                disabled={!activeImages[ln.key]}
                            />
                            <span>{t('general.default')}</span>
                        </div>
                    )}

                </div>
            ))}

        </div>
    );
};
