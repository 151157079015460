import { useState } from 'react';
import s from './Attendance.module.scss';
import { DateFormat, DateRangeTypes } from 'utils/enums/common.enum';
import { useGetAttendanceQuery } from '../../../../../../redux/apis/statisticsApi';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from 'utils/transformDate';
import { useTranslation } from 'react-i18next';
import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { format } from 'date-fns';
import { Table } from 'components/Table/Table';
import { Pagination } from '@mui/material';

export const Attendance = () => {

    // ### State
    const [query, setQuery] = useState({
        limit: 20,
        page: 1,
        type: DateRangeTypes.AllTime
    })

    const [timeRangeState, setTimeRangeState] = useState(0)
    const {t} = useTranslation()

    const {data: attendance, isLoading} = useGetAttendanceQuery(query)
    
    // ### Effects

    // ### Functions
    function changeTimeRange(variant: any){
        setTimeRangeState(variant)
        if(variant === 0){
            setQuery(prev => ({...prev, startDate: 0, endDate: new Date().getTime()}))
        }
        if(variant === 1){
            setQuery(prev => ({...prev, startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()}))
        }
        if(variant === 2){
            setQuery(prev => ({...prev, startDate: getTimestampWeekAgo(), endDate: new Date().getTime()}))
        }
        if(variant === 3){
            setQuery(prev => ({...prev, startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()}))
        }
    }

    // ### Views
    const columns: TableColumnType[] = [
            {
                title: "IP"
            },
            {
                title: t("dashboard.statistics.country")
            },
            {
                title: t("dashboard.statistics.city")
            },
            {
                title: t("dashboard.statistics.region")
            },
            {
                title: t("dashboard.statistics.timezone")
            },
            {
                title: t("dashboard.statistics.refer")
            },
            {
                title: t("general.date")
            }
        ];
    
        const rows: TableRowType[] = attendance?.data?.map((el: any) => {
            return(
                {
                    values: [
                        {
                            title: el.ip || "- - -"
                        },
                        {
                            title: el.country || "- - -"
                        },
                        {
                            title: el.city || "- - -"
                        },
                        {
                            title: el.region || "- - -"
                        },
                        {
                            title: el.timezone || "- - -"
                        },
                        {
                            title: el.refer || "- - -"
                        },
                        {
                            title: format(new Date(el.date), DateFormat.LONG)
                        }
                    ]
                }
            )
        })

    return(
        <div className={s.wrapper}>
            <h2 className={s.title}>{t('dashboard.statistics.attendance_title')}</h2>
            <div className={s.header}>
                { attendance?.totalPages > 1 ?
                    <Pagination 
                        count={attendance?.totalPages} 
                        variant="outlined" 
                        shape="rounded"
                        page={query.page}
                        onChange={((_, page) => setQuery(prev => ({...prev, page})))}
                    /> : null
                }
                <div className={s.timerange_wrapper}>
                    <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(0)}>
                            {t(`general.${DateRangeTypes.AllTime}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(1)}>
                            {t(`general.${DateRangeTypes.Day}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(2)}>
                            {t(`general.${DateRangeTypes.Week}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(3)}>
                            {t(`general.${DateRangeTypes.Month}`)}
                    </div>
                </div>
            </div>

            <div className={s.table_wrapper}>
                <Table 
                    rows={rows} 
                    columns={columns} 
                    isLoading={isLoading}
                    totalCount={attendance?.total}
                />
            </div>
            { attendance?.totalPages > 1 ?
                <Pagination 
                    count={attendance?.totalPages} 
                    variant="outlined" 
                    shape="rounded"
                    page={query.page}
                    onChange={((_, page) => setQuery(prev => ({...prev, page})))}
                /> : null
            }
        </div>
    )
}