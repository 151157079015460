import {HeaderSection} from "components/HeaderSection/HeaderSection";
import React, {useEffect, useState} from "react";
import s from './Language.module.scss';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {AdminTable} from "components/AdminTable/AdminTable";
import {useDeleteLanguageMutation, useGetLangsQuery, useSetLanguageMutation} from "../../redux/apis/langApi";
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import questionMark from "assets/images/questionMark.webp";
import {Preloader} from "components/preloader/Preloader";
import {useStatusContext} from "components/StatusProvider";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface ErrorData {
    message: number;
}

export const Language = () => {
    const [query, setQuery] = useState<string>('');
    const { t } = useTranslation();
    const headers = [t("language.langs"), t("language.key"), t("language.status")];
    const { data: languages } = useGetLangsQuery({});
    const [filteredLanguages, setFilteredLanguages] = useState<any[]>();

    const location = useLocation();
    const { addLang: myAddLang, deleteLang: myDeleteLang, nameLang: myNameLang } = location.state || {};
    const [openModalAdd, setOpenModalAdd] = useState<boolean>(Boolean(myAddLang));

    const [setLanguage, { isLoading: isLoadingSetLanguage }] = useSetLanguageMutation();
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(Boolean(myDeleteLang));
    const [deleteLanguage, { isLoading: isLoadingDeleteLanguage }] = useDeleteLanguageMutation();
    const [name, setName] = useState<string>(myNameLang ? myNameLang: "");
    const [idLang, setLangId] = useState<number>(myDeleteLang ? myDeleteLang: null);
    const { openStatus } = useStatusContext();

    useEffect(() => {
        setFilteredLanguages(languages?.data);
    }, [languages]);

    if (languages?.isFetching || isLoadingSetLanguage || isLoadingDeleteLanguage) return <Preloader/>

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const statusLanguages = (status: boolean) => {
        if (status) {
            return <div className={s.active}>{t("language.active")}</div>
        } else {
            return <div className={s.notActive}>{t("language.notActive")}</div>
        }
    }

    const handleAddLanguage = () => {
        setOpenModalAdd(true);
    }

    const handleDelete = (displayname: string, id: number) => {
        setLangId(id);
        setName(displayname);
        setOpenModalDelete(true);
    }

    const languages_view = filteredLanguages?.map((language: any) => {
        return (
            <tr key={language?.id}>
                <td>{language?.displayname}</td>
                <td>{language?.key}</td>
                <td>{statusLanguages(language?.active)}</td>
                <td className={s.icons}>
                    <span
                        className={s.tools_icon}
                        onClick={() => handleDelete(language?.displayname, language?.id)}
                    >
                        <Delete />
                    </span>
                    {/*<span className={s.tools_icon}/*onClick={() => handleEdit(user)}>
                        <Edit />
                    </span>*/}
                </td>
            </tr>
        )
    })

    const deleteLang = async () => {
        const result = await deleteLanguage(idLang);
        if (result.data?.message === 24){
            openStatus('success', "status.operationSuccessful");
        }
        setOpenModalDelete(false);
    }

    const handleSearch = () => {
        const results = languages?.data?.filter((language: any) =>
            (language.key && String(language.key).toLowerCase().includes(query.toLowerCase())) ||
            (language.displayname && String(language.displayname).toLowerCase().includes(query.toLowerCase()))
        );

        setFilteredLanguages(results);
    };

    async function addLang(value: string){
        const result = await setLanguage(value);
        const error = result.error as FetchBaseQueryError;
        const errorData = error?.data as ErrorData;

        if (errorData && 'message' in errorData && errorData.message === 1000) {
            openStatus('warning', "status.unfortunately");
        }
        if (result?.data?.message === 21) {
            openStatus('error', `${t("language.lang")} ${result.data.data} ${t("language.alreadyExists")}`);
        }
        if (result?.data?.message === 22) {
            openStatus('success', "status.operationSuccessful");
        }

        setOpenModalAdd(false);
    }

    const description = (
        <>
            {t("general.wantToRemove")} <span>{name}</span> ? <br />
            {t("general.itsContents")}
        </>
    );

    return (
        <section className={s.languages}>
            <HeaderSection
                title={t("language.langs")}
                buttonText={t("language.addLanguage")}
                icon={<Plus/>}
                onClick={handleAddLanguage}
                description={t("language.selectTheDefault")}
            />
            <AdminTable
                title={t("language.yourLanguages")}
                placeholder={t("language.search")}
                query={query}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                handleSearch={handleSearch}
                headers={headers}
                data={languages_view}
            />
            <ModalGeneral
                showModal={openModalAdd}
                setShowModal={setOpenModalAdd}
                title={t("language.addLanguage")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                field
                buttonRight={addLang}
            />
            <ModalGeneral
                showModal={openModalDelete}
                setShowModal={setOpenModalDelete}
                image={questionMark}
                buttonRight={deleteLang}
                title={t("language.removeTheLanguage")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                description={description}
            />
        </section>
    )
}