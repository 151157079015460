import React, {useEffect, useState} from 'react';
import s from './MailingsPage.module.scss';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import { Tabs } from 'components/Tabs/Tabs';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Mailings } from './components/Mailings/Mailings';
import { MailingGroups } from './components/MailingGroups/MailingGroups';
import { MailingSenders } from './components/MailingSenders/MailingSenders';
import { UpdateGroup } from './components/MailingGroups/components/UpdateGroup/UpdateGroup';
import { CreateMailing } from './components/Mailings/components/create/CreateMailing';
import { AboutMailing } from './components/Mailings/components/AboutMailing/AboutMailing';


export const MailingsPage = () => {

    const { t } = useTranslation();
    const location = useLocation();
    
    const items = [
        {
            id: 0,
            title: t('mailings.mailings'),
            path: "/mailings/list"
        },
        {
            id: 1,
            title: t('mailings.groups'),
            path: "/mailings/groups"
        },
        {
            id: 2,
            title: t('mailings.senders'),
            path: "/mailings/senders"
        }
    ]
   
    const [tab, setTab] = useState(items[0]?.id)

    useEffect(()=>{
        if(location.pathname.includes("mailings/list")) setTab(items[0]?.id)
        else if(location.pathname.includes("/mailings/groups")) setTab(items[1]?.id)
        else if(location.pathname.includes("/mailings/senders")) setTab(items[2]?.id)
        else setTab(items[0]?.id)
    },[location.pathname]);

    return (
        <div className={s.dashboard}>
            <HeaderSection
                title={t("mailings.mailings")}
                icon={<Brush />}
                disabled
            />

            <div className={s.tabs_wrapper}>
                <Tabs
                    items={items}
                    tab={tab}
                    setTab={setTab}
                />
            </div>
            <Routes>
                <Route index element={<Navigate to="/mailings/list" />} />
                <Route path="/list" element={<Mailings />} />
                <Route path="/list/create" element={<CreateMailing />} />
                <Route path="/list/about" element={<AboutMailing />} />
                <Route path="/groups" element={<MailingGroups />} />
                <Route path="/groups/group/*" element={<UpdateGroup />} />
                <Route path="/senders/*" element={<MailingSenders />} />
            </Routes>
        </div>
    )
}