import s from './ImageItem.module.scss';
import { ReactComponent as Delete } from "../../.././../assets/icons/delete_icon3.svg"
import { ReactComponent as Copy } from "../../.././../assets/icons/copy.svg"
import { ReactComponent as Success } from "../../.././../assets/icons/success_copy.svg"
import { ReactComponent as Close } from "../../.././../assets/icons/close.svg"
import { useState } from 'react';
import { formatSize } from 'utils/formatSize';
import { useTranslation } from 'react-i18next';
const URL = process.env.REACT_APP_API_ADDRESS

export const ImageItem = ({file, setDeleteFileModal, isModal, setFile, setIsOpenStorage }: any) => {

    // ### State
    const [preview, setPreview] = useState<boolean>(false)
    const [isCopy, setIsCopy] = useState<boolean>(false)
    const {t} = useTranslation()

    // ### Effects

    // ### Functions
    function handleCopy(){
        setIsCopy(true)
        setTimeout(()=>{
            setIsCopy(false)
        },2000)
    }

    // ### Views
    return(
        <div className={s.wrapper} onClick={()=>setPreview(true)}>
            <span className={s.size}>{formatSize(file.size)}</span>
            <span className={s.type}>{file.mimetype}</span>
            <span className={s.name}>{file.name}</span>
            <img src={`${URL}${file.path}/${file.name}`.replace("/public", "")} alt="icon" />
            <div className={s.delete_button} onClick={(e)=>{
                e.stopPropagation();
                setDeleteFileModal(`${file.path}/${file.name}`)
            }
            }><Delete /></div>
            <div className={s.copy_button} onClick={(e)=>{
                e.stopPropagation();
                navigator.clipboard.writeText(`${URL}${file.path}/${file.name}`.replace("/public", ""))
                .then(() => handleCopy())
                .catch(err => alert("Ошибка копирования:"));
            }
            }>{isCopy ? <Success/> : <Copy />}</div>
            {
                isModal ?
                <div className={s.select} onClick={(e)=>{
                        e.stopPropagation()
                        setFile(`${URL}${file.path}/${file.name}`.replace("/public", ""))
                        setIsOpenStorage(false)
                    }}
                >{t('storage.change')}</div>
                :null
            }         
            {
                preview ? 
                <>
                <div className={s.back}></div>
                <div className={s.preview}>
                    <div className={s.header}>
                        <span className={s.title}>{file.name}</span>
                        <div className={s.close} onClick={(e)=>{
                            e.stopPropagation()
                            setPreview(false)
                        }}><Close/></div>
                    </div>
                    <div className={s.container}>
                        <img src={`${URL}${file.path}/${file.name}`.replace("/public", "")} alt="icon" />
                    </div>
                </div>
                </> : null
            }
        </div>
    )
}