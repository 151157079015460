export const handleCopyText = async (file: any, setIsCopied: any) => {
    const url = process.env.REACT_APP_FRONT_URL;

    try {
        await navigator.clipboard.writeText(file?.path ?? `${url}${file?.slug}`);
        setIsCopied({ done: true, id: file?.id });
        setTimeout(() => setIsCopied({done: false, id: null }), 1000);
    } catch (err) {
        console.error("Не вдалося скопіювати текст", err);
    }
};