import { useEffect, useState } from 'react';
import s from './Finances.module.scss';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from 'utils/transformDate';
import { useGetPaymentsQuery } from '../../../../../../redux/apis/statisticsApi';
import { DateFormat, DateRangeTypes } from 'utils/enums/common.enum';
import { useTranslation } from 'react-i18next';
import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { format } from 'date-fns';
import { Table } from 'components/Table/Table';
import { Pagination } from '@mui/material';
import { Skeleton } from 'components/Skeleton/Skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
const currency = process.env.REACT_APP_CURRENCY

export const Finances = () => {

    // ### State
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state

    const [query, setQuery] = useState({
        page: state?.page || 1,
        limit: state?.limit || 20,
        startDate: state?.startDate || 0
    })
    const [timeRangeState, setTimeRangeState] = useState(state?.rangeType || 0)
    const [totalAmount, setToalAmoount] = useState<number>(0)
    const {t} = useTranslation()

    const {data: finances, isLoading} = useGetPaymentsQuery(query)
    
    // ### Effects
    useEffect(()=>{
        if(finances?.data){
            setToalAmoount(
                finances.data?.reduce((acc: number, el: any) => {
                    return acc + Number(el.amount)
                }, 0)
            )
        }else setToalAmoount(0)
    },[finances?.data]);

    // ### Functions
    function clearNavigationState(){
        navigate(location.pathname, { replace: true, state: null });
    }

    function changeTimeRange(variant: any){
        clearNavigationState()
        setTimeRangeState(variant)
        if(variant === 0){
            setQuery(prev => ({...prev, startDate: 0, endDate: new Date().getTime()}))
        }
        if(variant === 1){
            setQuery(prev => ({...prev, startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()}))
        }
        if(variant === 2){
            setQuery(prev => ({...prev, startDate: getTimestampWeekAgo(), endDate: new Date().getTime()}))
        }
        if(variant === 3){
            setQuery(prev => ({...prev, startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()}))
        }
    }

    // ### Views
    const columns: TableColumnType[] = [
        {
            title: t('general.date_transaction')
        },
        {
            title: t('general.amount')
        },
        {
            title: t('general.type')
        },
        {
            title: t('general.value')
        },
        {
            title: t('general.user')
        },
        {
            title: t('general.name')
        }
    ];

    const rows: TableRowType[] = finances?.data?.map((payment: any) => {
        const amuount = (<><ColorNumber>{payment.amount}</ColorNumber> {currency}</>)
        return(
            {
                link: `/dashboard/user/${payment.users.id}`,
                values: [
                    {
                        title: format(new Date(Number(payment.date)), DateFormat.LONG)
                    },
                    {
                        title: amuount,
                    },
                    {
                        title: payment.type === "course" ? t('dashboard.users.course_payment') : t('dashboard.users.guide_payment')
                    },
                    {
                        title: payment.value
                    },
                    {
                        title: payment.users?.email
                    },
                    {
                        title: payment.users?.name
                    }
                ]
            }
        )
    })

    return(
        <div className={s.page} style={{rowGap: finances?.totalPages > 1 ? 20 : 40}}>
            <div className={s.header}>
                <div className={s.item}>
                    <span className={s.title}>{t('dashboard.statistics.total_amount')}: </span>
                    <span className={s.value}>{totalAmount.toFixed(1)} {currency}</span>
                </div>

                <div className={s.timerange_wrapper}>
                    <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(0)}>
                            {t(`general.${DateRangeTypes.AllTime}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(1)}>
                            {t(`general.${DateRangeTypes.Day}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(2)}>
                            {t(`general.${DateRangeTypes.Week}`)}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(3)}>
                            {t(`general.${DateRangeTypes.Month}`)}
                    </div>
                </div>
                <Skeleton isLoading={isLoading} />
            </div>

            <div className={s.content}>
                { finances?.totalPages > 1 ?
                    <Pagination 
                        style={{alignSelf: "flex-end"}}
                        count={finances?.totalPages} 
                        variant="outlined" 
                        shape="rounded"
                        page={query.page}
                        onChange={(_, page) => {
                            setQuery(prev => ({...prev, page}))
                            clearNavigationState()
                        } }
                    /> : null
                }
                <Table 
                    rows={rows}
                    columns={columns}
                    isLoading={isLoading}
                    totalCount={finances?.total}
                    routeState={{link: `/dashboard/finances`, page: query.page, startDate: query.startDate, rangeType: timeRangeState}}
                />
                { finances?.totalPages > 1 ?
                    <Pagination 
                        count={finances?.totalPages} 
                        variant="outlined" 
                        shape="rounded"
                        page={query.page}
                        onChange={(_, page) => {
                            setQuery(prev => ({...prev, page}))
                            clearNavigationState()
                        } }
                    /> : null
                }
            </div>
        </div>
    )
}