import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useGetEmailHTMLTemplateQuery, useGetGroupsQuery, useGetMailingSendersQuery, useGetMailingsQuery } from '../../../../../../redux/apis/mailingApi'
import s from "./AboutMailing.module.scss"
import { DateFormat, TimePeriod } from 'utils/enums/common.enum'
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton'
import { ProgressLine } from 'components/ProgressLine/ProgressLine'
import { Skeleton } from 'components/Skeleton/Skeleton'

const test = `
<style>
            body{
                font-family: "Noto Sans", serif;
            }
            .wrapper{
                max-width: 700px;
                width: 700px;
                margin: 0 auto;
            }
    
            .header{
                display: flex;
                justify-content: space-between;
                background: #00A6AE;
                border-radius: 16px 16px 0px 0px;
                padding: 15px;
            }
    
            .header img{
                width: 60px;
                height: 60px;
            }
    
            .header h2{
                color: #FFF;
                text-align: right;
                width: 100%;
            }

            .body{
                padding: 20px;
            }
    
            .footer{
                display: flex;
                justify-content: space-between;
                background: #00A6AE;
                border-radius: 0px 0px 16px 16px;
                padding: 15px;
                color: #FFF;
            }
    
            .footer .right .icons{
                display: flex;
                gap: 10px;
                align-items: center;
            }
            .footer .left{
                width: 100%;
            }

            .footer .right h2{
                white-space: nowrap;
            }
    
            a{
                text-transform: none;
                color: #FFF;
            }

            .order_table img{
                width: 200px;
                height: 200px;
                margin-right: 10px;
            }

            .order_table tr td{
                vertical-align: top;
            }

            .color{
                width: 20px;
                height: 20px;
                transform: translateY(-10px);
                border-radius: 50%;
                display: inline-block;
            }
        </style>
<div class="wrapper">
            <div class="header">
                <img src="https://back.successwellnesscoaching.com/storage/images/logo.png" alt="logo">
                <h2>$title</h2>
            </div>
            <div class="body">
                $body
            </div>
            <div class="footer">
                <div class="left">
                    <h2>Contacts</h2>
                    <p class="email">Email: successwellnesscoaching1@gmail.com</p>
                    <p class="date">2025</p>
                </div>
                <div class="right">
                    <h2>FOLLOW ME</h2>
                    <div class="icons">
                        <a href="https://successwellnesscoaching.com/">
                            <img src="https://back.successwellnesscoaching.com/storage/images/youtube.png" alt="youtube">
                        </a>
                        <a href="https://successwellnesscoaching.com/">
                            <img src="https://back.successwellnesscoaching.com/storage/images/facebook.png" alt="Facebook">
                        </a>
                        <a href="https://www.instagram.com/success_wellness_coaching/">
                            <img src="https://back.successwellnesscoaching.com/storage/images/instagram.png" alt="Instagram">
                        </a>
                    </div>
                </div>
            </div>
        </div>`

export const AboutMailing = () => {

    // ### State
    const [template, setTemplate] = useState<string | undefined>(undefined)
    const [params] = useSearchParams()

    const {data: templateData} = useGetEmailHTMLTemplateQuery({})
    const {data: groups, isLoading: isGetGroupsLoading} = useGetGroupsQuery({})
    const {data: senders, isLoading: isGetSendersLoading} = useGetMailingSendersQuery({})
    const {data: mailings, isLoading: isGetMailingLoading} = useGetMailingsQuery({id: params.get('id')})
    const mailing = mailings?.[0]

    const all = mailing?.changeAll ? mailing?.users_length : mailing?.email_count
    const errors = mailing?.statistics?.error?.length
    const sended = mailing?.statistics?.success?.length
    
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    // ### Effects
    useEffect(()=>{
        if(templateData && i18n.language && mailing){
            let newTemplate = templateData.template.replace('$body', mailing?.body[i18n.language])
            newTemplate = newTemplate.replace('$title', mailing?.title[i18n.language])
            setTemplate(newTemplate)
        }
    },[templateData, i18n.language, mailing]);

    // ### Functions

    // ### Views

    const interval_translate = Object.entries(TimePeriod).find(el => el[1] === mailing?.interval)?.[0]
    
    return(
        <div className={s.page}>
            <div className={s.left}>
                <div className={s.buttons_wrapper}>
                    <PrimaryButton
                        type="button"
                        style={{fontSize: "13px", padding: "5px 7px", height: "35px"}} 
                        text={t('general.edit')} 
                        onClick={()=>navigate(`/mailings/list/create?id=${params.get('id')}`)}
                    />
                </div>
                <p className={s.title}>{t('mailings.mailing')}</p>
                <div className={s.row}>
                    <span className={s.title}>{t('general.date_created')}</span>
                    <span className={s.value}>{mailing ? format(new Date(mailing?.date), DateFormat.LONG) : "- - -"}</span>
                </div>
                <div className={s.row}>
                    <span className={s.title}>{t('mailings.group')}</span>
                    <span className={s.value}>{groups?.find((group: any) => group.id === mailing?.group)?.name}</span>
                </div>
                <div className={s.row}>
                    <span className={s.title}>{t('mailings.sender')}</span>
                    <span className={s.value}>{senders?.find((sender: any) => sender.id === mailing?.sender)?.name}</span>
                </div>
                <div className={s.row}>
                    <span className={s.title}>{t('mailings.mailing_type')}</span>
                    
                    {
                        !mailing?.mailing_type ? 
                        <span className={s.value}>{t('mailings.one_time')}</span>
                        : 
                        <span className={s.value}>
                            <p>{t('mailings.cyclic')}</p>
                            <p>{t('mailings.interval')}: {t(`mailings.${interval_translate}`)}</p>
                            <p>{t('mailings.sended')}: {
                                mailing.send_count === 0 ? `${mailing.all_send_count}/${mailing.all_send_count}` : 
                                `${(Number(mailing.all_send_count) - Number(mailing.send_count))}/${mailing.all_send_count}`
                            }</p>
                            {
                                mailing?.mailing_type && mailing?.send_count ?
                                <p>{t('mailings.next_time')}: {mailing ? format(new Date(mailing?.start_date), DateFormat.LONG) : "- - -"}</p> : null
                            }
                        </span>
                    }
                    
                </div>
                <div className={s.row}>
                    <span className={s.title}>{t('mailings.date')}</span>
                    <span className={s.value}>{mailing ? format(new Date(mailing?.start_date), DateFormat.LONG) : "- - -"}</span>
                </div>
                <div className={s.statistics_wrapper}>
                    <div className={s.statistics}>
                        <div className={s.item}>
                            <div className={s.statistics_value}>{all}</div>
                            <div className={s.statistics_title}>{t('mailings.total')}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.statistics_value}>{sended}</div>
                            <div className={s.statistics_title}>{t('mailings.sended')}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.statistics_value}>{errors}</div>
                            <div className={s.statistics_title}>{t('mailings.errors')}</div>
                        </div>
                    </div>
                    <div className={s.process_wrapper}>
                        <ProgressLine 
                            count={all} 
                            progress={sended}
                        />
                        <span className={s.procent_value}>{ (sended / all * 100).toFixed(0) }%</span>
                    </div>
                </div>
            </div>
            <div className={s.right}>
                {
                    template ? 
                    <div className={s.frame} dangerouslySetInnerHTML={{__html: template}}></div>
                    : null
                }
            </div>
            <Skeleton isLoading={(isGetGroupsLoading || isGetSendersLoading || isGetMailingLoading)} />
        </div>
    )
}