import { TableColumnType, TableRowType } from 'utils/types/table.type';
import { ColorNumber } from 'utils/hooks/ColorNumber';
import { format } from 'date-fns';
import { DateFormat } from 'utils/enums/common.enum';
import { Table } from 'components/Table/Table';
import { clearHTML } from 'utils/clearHTML';
import { useTranslation } from 'react-i18next';
const currency = process.env.REACT_APP_CURRENCY

export const Courses = ({courses, payments}: {courses: any, payments: any}) => {

    // ### State
    const {i18n, t} = useTranslation()

    // ### Effects

    // ### Functions

    // ### Views

    const columns: TableColumnType[] = [
        {
            title: t('dashboard.users.course')
        },
        {
            title: t('general.validity_period')
        },
        {
            title: t('general.price')
        },
        {
            title: t('general.payment_date')
        },
        {
            title: t('general.valid_until')
        }
    ];

    const rows: TableRowType[] = payments?.map((payment: any) => {
        const course = courses?.find((course: any) => course?.course?.slug?.en === payment.value)
        const amuount = (<><ColorNumber>{payment.amount}</ColorNumber> {currency}</>)
        return(
            {
                values: [
                    {
                        title: clearHTML(course?.course?.name?.[i18n.language] || `${payment.value} (удалён)`)
                    },
                    {
                        title: course?.course?.access_length?.[i18n.language] ? `${clearHTML(course?.course?.access_length?.[i18n.language])} месяца` : "- - -"
                    },
                    {
                        title: amuount,
                    },
                    {
                        title: format(new Date(Number(payment.date)), DateFormat.LONG)
                    },
                    {
                        title: format(new Date(Number(payment.end_date)), DateFormat.LONG)
                    }
                ]
            }
        )
    })

    return(
        <Table 
            rows={rows}
            columns={columns}
            isLoading={false}
        />
    )
}